import * as React from "react";
import {
  StyledIconVideoCallMobile,
  PhotoBoxMobile,
  AvatarMobile,
  StyledAnchorDialMobile,
} from "../_styles";
import { setIsPlaying } from "../_actions";
import useOnClickWithTouchHandler from "../../../hooks/useOnClickWithTouchHandler";

const _AvatarPhotoMobile = ({
  dispatch,
  state,
  dispatchChatEvent,
  chatState,
}) => {
  const setPlaying = () => {
    if (window.trl) {
      window.trl.fixAudioContext();
    } 
    dispatch({ type: setIsPlaying });
  };

  const handleSetPlaying = useOnClickWithTouchHandler(setPlaying);

  if (state.isPlaying || state.invalidAvatar) {
    return null;
  }
  return (
    <PhotoBoxMobile isMobile={state.isMobile} fullScreen={state.fullScreen}>
      <AvatarMobile
        {...handleSetPlaying}
        isMobile={state.isMobile}
        avatarPhotoURL={state.avatarPhotoURL}
      />
      <StyledAnchorDialMobile
        isMobile={state.isMobile}
        colors={chatState.colors}
        icon={
          <StyledIconVideoCallMobile
            {...handleSetPlaying}
            className="material-icons"
            isMobile={state.isMobile}
            fullScreen={state.fullScreen}
          >
            videocam
          </StyledIconVideoCallMobile>
        }
      />
    </PhotoBoxMobile>
  );
};

export const AvatarPhotoMobile = React.memo(_AvatarPhotoMobile);
