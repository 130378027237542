import * as React from "react";
import { SDKContext } from "../contexts/SDKContext";
import { restartIce } from "../components/video/_actions";
import { ConsoleLogger } from "../common/logger";

//TODO
const RECONNECT_TIMEOUT = 60;

const logger = new ConsoleLogger();

const useNetworkStatus = () => {
  const [, dispatch] = React.useContext(SDKContext);
  const [status, setStatus] = React.useState(true);
  const [isReload, setIsReload] = React.useState(false);
  const previousData = React.useRef({});

  const handleReconnect = React.useCallback(async () => {
    const timeDifference =
      (Date.now() - previousData.current.timeOfOffline) / 1000;
    const meetingClient = window.MeetOnline.client();

    // document.hidden : is false when tab is not visible
    if (timeDifference < RECONNECT_TIMEOUT && !window.document.hidden) {
      if (!meetingClient?.isWebSocketOpen()) {
        try {
          await meetingClient?.reConnectGateway();
        } catch (error) {
          console.error("Connection failed", error);
          window.location.reload();
        }
      }
      return;
    }
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    async function changeStatus() {
      logger.log("In network ChangeStatus handler");
      setStatus(navigator.onLine);
      if (navigator.onLine === false) {
        logger.log("Not online");
        previousData.current.timeOfOffline = Date.now();
        setIsReload(true);
      }
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isReload && status) {
      /*  handleReconnect() */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload, status, handleReconnect]);

  React.useEffect(() => {
    if (status) {
      logger.log("Before restarting ice connection");
      dispatch({type: restartIce});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return { isOnline: status, isOffline: !status };
};

export default useNetworkStatus;
