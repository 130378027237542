export const OS = Object.freeze({
  IOS: "IOS",
  IOSChrome: "IOSChrome",
  Android: "Android",
  AndroidTab: "AndroidTab",
  Desktop: "Desktop",
  ChromeDesktop: "ChromeDesktop",
  SamsungBrowser: "SamsungBrowser",
  IOSIpadChrome: "IOSIpadChrome",
});

export const AgentRegMap = Object.freeze({
  IOSChrome: /CriOS/i,
  IOS: /iPad|iPhone|iPod/,
  ChromeDesktop: /Chrome/i,
  Android: /android/i,
  MobileAndroid: /Mobile/i,
  SamsungBrowser: /SamsungBrowser/i,
  IPad: /iPad/i,
});

export const breakpointValues = {
  sm: "30em",
  md: "48em",
  lg: "64em",
  xl: "80em",
  xxl: "96em",
  xxxl: "125em",
};

export const isIpad = () =>
  navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);

export const detectDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (AgentRegMap.IOSChrome.test(userAgent) && AgentRegMap.IPad.test(userAgent))
    return OS.IOSIpadChrome;
  if (AgentRegMap.IOSChrome.test(userAgent)) return OS.IOSChrome;
  if (AgentRegMap.SamsungBrowser.test(userAgent)) return OS.SamsungBrowser;
  if (
    AgentRegMap.Android.test(userAgent) &&
    AgentRegMap.MobileAndroid.test(userAgent)
  )
    return OS.Android;
  if (AgentRegMap.Android.test(userAgent)) return OS.AndroidTab;
  if (AgentRegMap.ChromeDesktop.test(userAgent)) return OS.ChromeDesktop;
  if ((AgentRegMap.IOS.test(userAgent) || isIpad()) && !window.MSStream)
    return OS.IOS;

  return OS.Desktop;
};

function parseBreakpoint(size) {
  const breakpointValue = breakpointValues?.[size];
  const breakpointToPx =
    Number.parseFloat(breakpointValue.split("em")?.[0]) * 16;
  return breakpointToPx;
}

export const isSm = (size) => {
  return size.width < parseBreakpoint("md");
};
export const isMd = (size) => {
  return (
    size.width >= parseBreakpoint("md") && size.width < parseBreakpoint("lg")
  );
};
export const isLg = (size) => {
  return (
    size.width > parseBreakpoint("lg") && size.width < parseBreakpoint("xl")
  );
};
export const isXl = (size) => {
  return (
    size.width > parseBreakpoint("xl") && size.width < parseBreakpoint("xxl")
  );
};
export const isXXl = (size) => {
  return size.width > parseBreakpoint("xxl");
};

export const isXXXL = (size) => {
  return size.width > parseBreakpoint("xxxl");
};

export const isBrowser = ![typeof window, typeof document].includes(
  "undefined"
);

export const isMobileDevice = (screenSize) => isSm(screenSize);

export const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);

export const detectIOSVersionSafari = () => {
  const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
  const major = version && version[1] ? version[1] : "";
  const minor = version && version[2] ? version[2] : "";
  const patch = version && version[3] ? version[3] : "";
  return { major, minor, patch };
};

export const detectIOSVersionBelow14Chrome = () => {
  const array = navigator.userAgent.split(" ");
  let flag = false;
  const device = detectDeviceType();
  array.forEach((item) => {
    if (
      item.startsWith("14") ||
      item.startsWith("13") ||
      item.startsWith("12") ||
      item.startsWith("11") ||
      item.startsWith("10")
    ) {
      flag = true;
    }
  });

  return (device === "IOSChrome" || "IOSIpadChrome") && flag;
};
