import React, { useEffect, useState } from "react";
import { hideToastMessage,} from "../../../menu/actions";
import {ToastContainerStyle, ToastCloseIcon, ToastIcon, ToastIconContainer, ToastMessageStyle} from "./_styles"

const CLOSE_AND_SHOW_DELAY = 100

export const ToastDuration = {
  SHORT: 2000,
  MEDIUM: 4000,
  LONG: 6000
};

const ToastMessage = ({ show, message, dispatchMenuEvent }) => {

  const [internalShowState, showInternalShowState] = useState(false)

  const [timeStamp, setTimeStamp] = React.useState({
    start: 0,
    end: 0,
  });

  const handleTouchStart = (e) => {
    setTimeStamp({ ...timeStamp, start: e.timeStamp });
  };

  const handleOnTouchEnd = React.useCallback(
    (e, callback) => {
      if (e.timeStamp - timeStamp.start < 150) {
        callback();
      }
      setTimeStamp({ start: 0, end: 0 });
    },
    [timeStamp.start]
  );

  // Set internal show state
  useEffect(() => {
    const timerId = setTimeout(() => showInternalShowState(show), CLOSE_AND_SHOW_DELAY);
    return () => clearTimeout(timerId)
  }, [show])

  return (
    <ToastContainerStyle
      id="toast-message"
      show={internalShowState}
    >
      <ToastIconContainer>
        <ToastIcon className="material-icons" >
          info
        </ToastIcon>
      </ToastIconContainer>

      <ToastMessageStyle>
        {message}
      </ToastMessageStyle>

      <ToastCloseIcon
        className="material-icons"
        onTouchStart={handleTouchStart}
        onTouchEnd={(e) =>
          handleOnTouchEnd(e, () => dispatchMenuEvent({ type: hideToastMessage })
          )
        }
        onClick={() => dispatchMenuEvent({ type: hideToastMessage })}
      >
        close
      </ToastCloseIcon>
    </ToastContainerStyle>
  );
};


const _ToastContainer = ({ menuState, dispatchMenuEvent }) => {

  const toastMessage = menuState?.toastMessage

  const [toast, setToast] = useState();

  useEffect(() => {
    let closeTimerId = null;
    let showTimerId = null;

    // Check if toast message is set to show
    if(toastMessage?.show) {

      // Clear any existing toast message
      setToast(null)

      // Set a timer to show the toast message after a delay
      showTimerId = setTimeout(() => {
        setToast(toastMessage);
      }, CLOSE_AND_SHOW_DELAY)

      // Set a timer to hide the toast message after its specified duration
      const duration = toastMessage.duration ?? ToastDuration.MEDIUM
      closeTimerId = setTimeout(() => {
        dispatchMenuEvent({ type: hideToastMessage }) 
      }, duration);

    } else {
      
      // If there's no toast message to show, clear the toast
      setToast(null);
    }

    // Cleanup function to clear timers 
    return () => {
      clearTimeout(showTimerId);
      clearTimeout(closeTimerId);
    }
  }, [toastMessage])

  return (
    <>
      {toast &&
        <ToastMessage dispatchMenuEvent={dispatchMenuEvent} message={toast?.message} show={toast?.show} />
      }    
    </>
  );
};

export const ToastContainer = React.memo(_ToastContainer);
