import {
  handleMessageReceived,
  clearChatHistoryOnReload,
  setInputFocus,
  setInputText,
  sendMessage,
  setWebSocketStatus,
  setAuthStatus,
  setOauth,
  setTrulience,
  setFSMode,
  setUIColors,
  clearInput,
} from "./_actions";
import moment from "moment";
import Util from "../../common/Util";

export function chatReducer(state, action) {
  switch (action.type) {
    case setTrulience:
      return { ...state, trulience: action.trulience };

    case setInputFocus:
      return { ...state, inputFocussed: action.inputFocussed };

    case setInputText:
      state.trulience.sendUserTyping(true);
      return { ...state, inputText: action.inputText };

    case sendMessage:
      return handleSendMessage(state, action.inputText);
    case clearInput:
      return { ...state, inputText: "" };

    case handleMessageReceived:
      return { ...state, messages: action.messages };

    case clearChatHistoryOnReload:
      return { ...state, messages: [] };

    case setWebSocketStatus:
      return {
        ...state,
        webSocketStatus: action.webSocketStatus,
        userName: action.userName,
        userPhoto: action.userPhoto,
      };

    case setAuthStatus:
      return { ...state, authStatus: action.authStatus };

    case setOauth:
      return {
        ...state,
        oauth: action.oauth,
        oauthURL: action.oauthURL,
        oauthAppId: action.oauthAppId,
        oauthPartner: action.oauthPartner,
      };

    case setFSMode:
      return { ...state, fsMode: action.fsMode };

    case setUIColors:
      const newColors = {
        chatScreenBGColor:
          action.colors.chatScreenBGColor || state.colors.chatScreenBGColor,

        userChatBubbleBGColor:
          action.colors.userChatBubbleBGColor ||
          state.colors.userChatBubbleBGColor,
        avatarChatBubbleBGColor:
          action.colors.avatarChatBubbleBGColor ||
          state.colors.avatarChatBubbleBGColor,

        userChatBubbleBorderColor:
          action.colors.userChatBubbleBorderColor ||
          state.colors.userChatBubbleBorderColor,
        avatarChatBubbleBorderColor:
          action.colors.avatarChatBubbleBorderColor ||
          state.colors.avatarChatBubbleBorderColor,

        userChatBubbleTextColor:
          action.colors.userChatBubbleTextColor ||
          state.colors.userChatBubbleTextColor,
        avatarChatBubbleTextColor:
          action.colors.avatarChatBubbleTextColor ||
          state.colors.avatarChatBubbleTextColor,

        inputBoxBGColor:
          action.colors.inputBoxBGColor || state.colors.inputBoxBGColor,
        inputBoxBorderColor:
          action.colors.inputBoxBorderColor || state.colors.inputBoxBorderColor,
        inputBoxTextColor:
          action.colors.inputBoxTextColor || state.colors.inputBoxTextColor,

        sendButtonBGColor:
          action.colors.sendButtonBGColor || state.colors.sendButtonBGColor,
        sendButtonArrowColor:
          action.colors.sendButtonArrowColor ||
          state.colors.sendButtonArrowColor,
        sendButtonBorderColor:
          action.colors.sendButtonBorderColor ||
          state.colors.sendButtonBorderColor,

        borderColorBetweenInputAndScreen:
          action.colors.borderColorBetweenInputAndScreen ||
          state.colors.borderColorBetweenInputAndScreen,
        dialButtonBackground:
          action.colors.dialButtonBackground ||
          state.colors.dialButtonBackground,
        dialButtonTextColor:
          action.colors.dialButtonTextColor || state.colors.dialButtonTextColor,
        dialPageBackground:
          action.colors.dialPageBackground || state.colors.dialPageBackground,
      };
      return {
        ...state,
        colors: newColors,
      };

    default:
      return { ...state };
  }
}

const handleSendMessage = (state, userInputText) => {
  if (userInputText === "sys-info") {
    printSystemInfo(state); // Works only when screen Id is set in Trulience Object
  }

  let newMsgArr = [];
  if (state.messages) {
    newMsgArr = [...state.messages];
  }
  let m = moment();
  let now = m.format("HH:mm");
  let id_ = Util.generateUniqueId();

  let thisMsg = {
    id: id_,
    type: 1,
    image: state.userPhoto,
    text: userInputText,
    timestamp: now,
    userName: state.userName,
  };

  if (!userInputText.includes("<trl")) {
    newMsgArr.push(thisMsg);
  }
  if (state.isMobile.any) {
    state.trulience.screenManager().scrollToBottom();
  } else {
    state.trulience.screenManager().scrollToTop();
  }

  if (state.trulience && state.trulience.isConnected()) {
   
    state.trulience.sendMessage(
      Util.replaceUnreadableCharactersUrl(userInputText)
    );
  }
  return { ...state, messages: newMsgArr, inputText: "" };
};

const printSystemInfo = (state) => {
  for (var key in state.isMobile) {
    const value = state.isMobile[key];
    if (typeof value === "object") {
      state.trulience
        .screenManager()
        .writeToScreen(`${key} = ${JSON.stringify(value)}`);
    } else {
      state.trulience.screenManager().writeToScreen(`${key} = ${value}`);
    }
  }
};
