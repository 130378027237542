import { BREAKPOINT_BIG_SCREEN, BREAKPOINT_BIG_SCREEN_HEIGHT } from "../constants";

export const calculateSizeDependingOnSize = (defualtSize) => {
  if(window.innerWidth > window.innerHeight) {
  if (window.innerWidth < BREAKPOINT_BIG_SCREEN) {
    return `${defualtSize}px`;
  }
  return `${(window.innerWidth * defualtSize) / BREAKPOINT_BIG_SCREEN}px`;
  } else {
    if (window.innerHeight < BREAKPOINT_BIG_SCREEN_HEIGHT) {
      return `${defualtSize}px`;
    }
    return `${(window.innerHeight * defualtSize) / BREAKPOINT_BIG_SCREEN_HEIGHT}px`;
  }
};


export const calculateFontSizeDependingOnSize = (defualtSize) => {
  if(window.innerWidth > window.innerHeight) {
  if (window.innerWidth < BREAKPOINT_BIG_SCREEN) {
    return `${defualtSize}px`;
  }
  return `${(window.innerWidth * defualtSize) / BREAKPOINT_BIG_SCREEN}px`;
  } else {
    if (window.innerHeight < BREAKPOINT_BIG_SCREEN_HEIGHT) {
      return `${defualtSize}px`;
    }
    return `${(window.innerHeight * defualtSize) / BREAKPOINT_BIG_SCREEN_HEIGHT}px`;
  }
};
