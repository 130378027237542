import {
  toggleFullScreen,
  exitFullScreen,
  setIsPlaying,
  stopPlaying,
  setMicMuted,
  setSpeakerMuted,
  toggleWebRtcMic,
  toggleSpeaker,
  setTrulienceObject,
  setProgress,
  setIsLoading,
  setStatusMessage,
  setDisplayControls,
  setAvatarPhotoURL,
  setWebRtcNotSupported,
  setInvalidAvatar,
  toggleFSCinemaMod,
  hideDragResizeOverlay,
  setScreenAspectRationProps,
  setMobileScreenAspectRationProps,
  setTabletScreenAspectRationProps,
  setLogoProps,
  setEnabledSubtitles,
  restartIce,
  toggleBigPlayButton
} from "./_actions";
import { ConsoleLogger } from "../../common/logger";
import useNetworkStatus from "../../hooks/useNetworkStatus";

const logger = new ConsoleLogger();

export function playerReducer(state, action) {
  switch (action.type) {
    case toggleFullScreen:
      toggleFS(state, action.target);

      // Don't toggle fullscreen if target is "video" because we don't have a way to toggle back
      const fullScreen = action.target === "video" ? state.fullScreen : !state.fullScreen 

      return { ...state, fullScreen, isFSCinemaMod: false };

    case toggleFSCinemaMod:
      return { ...state, isFSCinemaMod: !state.isFSCinemaMod };

    case exitFullScreen:
      return { ...state, fullScreen: false };

    case setIsPlaying:
      let playingChanged = false;
      let showLoading = false;
      if (state.isPlaying) {
        state.trulience.disconnectGateway(
          window["Trulience"].CallEndReason.HANGED_UP
        );
        playingChanged = true;
      } else {
        if (navigator.onLine === true) {
          state.trulience.connectGateway();
          playingChanged = true;
          showLoading = state.trulience.isDHWebglBased() ? false : true;
        }
      }

      return {
        ...state,
        isPlaying: playingChanged ? !state.isPlaying : state.isPlaying,
        isLoading: showLoading,
        loadProgress: 100,
        isModalHistoryLinks: false,
        isIframeMode: false,
        statusMessage: "",
      };

    case restartIce:
      logger.log("reducer.js -> Restarting ice");
      if (state.trulience) {
        logger.log("reducer.js -> Before Restarting ice");
        state.trulience.restartIce();
        logger.log("reducer.js -> Before Restarting stt");
        let sttObj = state.trulience.getStt();
        if (sttObj) {
          sttObj.restartSttConnection();
        }
      }
      return { ...state, restartIce: true };

    case stopPlaying:
      // Logic moved to SDK so no need to call disconnectGateway
      //state.trulience.disconnectGateway(window['Trulience'].CallEndReason.DISCONNECTED);
      return {
        ...state,
        isPlaying: false,
        isLoading: false,
        isIframeMode: false,
        statusMessage: "",
      };

    case setProgress:
      return { ...state, loadProgress: (action.loadProgress * 100).toFixed(0) };
    case setIsLoading:
      return { ...state, isLoading: action.isLoading };
    case setEnabledSubtitles:
      if (state.trulience.isConnected()) {
        state.trulience.sendMessage(
          `<trl-config subtitlesEnabled="${!state.subtitlesEnabled}" />`
        );

        return { ...state, subtitlesEnabled: !state.subtitlesEnabled };
      }
    case setMicMuted:
      if (state.trulience.rtc()) {
        state.trulience.rtc().toggleMic();
      }
      return { ...state };

    case setSpeakerMuted:
      if (state.trulience.rtc()) {
        state.trulience.rtc().toggleSpeaker();
      }
      return { ...state };

    case toggleWebRtcMic:
      return { ...state, micMuted: action.micStatus };

    case toggleSpeaker:
      return { ...state, speakerMuted: !action.speakerStatus };

    case setTrulienceObject:
      return {
        ...state,
        trulience: action.trulience,
        videoElemRef: action.videoElemRef,
      };

    case setStatusMessage:
      return {
        ...state,
        statusMessage: action.statusMessage,
        statusMessageLink: action.statusMessageLink,
      };

    case setDisplayControls:
      return { ...state, displayControls: action.displayControls };

    case setAvatarPhotoURL:
      return { ...state, avatarPhotoURL: action.avatarPhotoURL };

    case setWebRtcNotSupported:
      return {
        ...state,
        statusMessage: action.statusMessage,
        webRtcNOTSupported: true,
      };

    case setInvalidAvatar:
      return { ...state, invalidAvatar: true };
    case hideDragResizeOverlay:
      return { ...state, isDragResizeOverlay: false };
    case setScreenAspectRationProps: {
      return {
        ...state,
        screenAspectRationProps: action.screenAspectRationProps,
      };
    }
    case setMobileScreenAspectRationProps: {
      return {
        ...state,
        mobileScreenAspectRatioProps: action.mobileScreenAspectRatioProps,
      };
    }
    case setTabletScreenAspectRationProps: {
      return {
        ...state,
        tabletScreenAspectRatioProps: action.tabletScreenAspectRatioProps,
      };
    }
    case setLogoProps:
      return {
        ...state,
        logoProps: { ...state.logoProps, ...action.logoProps },
      };
    case toggleBigPlayButton: 
      return {
        ...state,
        showBigPlayButton: !state.showBigPlayButton
      }
    default:
      return { ...state };
  }
}

function toggleFS(state, target) {
  if (state.isMobile.android.device) {
    toggleFS_Android(state.fullScreen);
  } else if (state.isMobile.apple.device) {
    toggleFS_iOS(state.fullScreen, target);
  } else {
    //TODO
  }
}

function toggleFS_Android(isFullScreen) {
  if (!isFullScreen && !document.fullscreenElement) {
    document.documentElement.requestFullscreen({ navigationUI: "hide" });
    try {
      //window.screen.orientation.lock("landscape");
    } catch (e) {
      logger.log(e);
    }
  }

  if (isFullScreen && document.fullscreenElement) {
    //window.screen.orientation.lock("portrait");
    document.exitFullscreen();
  }
}

function toggleFS_iOS(isFullScreen, target) {
  logger.log("toggleFS_iOS =>  isFullScreen = " + isFullScreen);
  
  if(target === "video") {
    // Open video tag in fullscreen
    const video = document.getElementById("remoteVideo")
    if(video) {
      video.webkitEnterFullScreen()
    }
    return 
  }

  if (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement
  ) {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  } else {
    const _element = document.documentElement;
    if (_element.requestFullscreen) {
      _element.requestFullscreen();
    } else if (_element.mozRequestFullScreen) {
      _element.mozRequestFullScreen();
    } else if (_element.webkitEnterFullscreen) {
      _element.webkitEnterFullscreen();
    } else if (_element.webkitRequestFullscreen) {
      _element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  }
}
