import { useRef, useCallback } from "react"

const useOnClickWithTouchHandler = (callback) => {
  const timeStamp = useRef({
    start: 0,
    end: 0
  })

  const onTouchStart = useCallback(
    (event) => {
      timeStamp.current = { ...timeStamp.current, start: event.timeStamp }
    },
    []
  )

  const onTouchEnd = useCallback(
    (event) => {
      if (event.timeStamp - timeStamp.current.start < 150) {
        callback(event)
      }
      timeStamp.current = { start: 0, end: 0 }
    },
    [callback]
  )

  const onClick = useCallback(
    (event) => {
      callback(event)
    },
    [callback]
  )

  return {
    onTouchStart,
    onTouchEnd,
    onClick
  }
}

export default useOnClickWithTouchHandler
