import * as React from "react";
import { CloseIcon, SideNav, Link, Option, Icon } from "./menu.styles";
import { SDKContext } from "../../contexts/SDKContext";
import {
    closeMenu,
    openLinkOverlayPiP,
    openLinkOverlay,
    addCurrentLink,
} from "./actions";
import { HistoryLinks } from "./history-links";
import { sendMessage } from "../chat/_actions";
import { usePictureInPicture } from "../../hooks/usePictureInPicture";
import { calculateFontSizeDependingOnSize } from "../../common/utilForResponseUI";

const _Menu = ({
    isLandscape,
    isMobile,
    windowSize,
    widthMainScreen,
    isChatScreenMobile,
}) => {
    const [videoState, , , dispatchChatEvent, menuState, dispatchMenuEvent] =
        React.useContext(SDKContext);

    const { enterPictureInPicture, openExternalUrl } = usePictureInPicture();
    const closeNav = () => {
        dispatchMenuEvent({ type: closeMenu });
    };

    const sendPhrase = React.useCallback(
        (phrase) => {
            dispatchChatEvent({
                type: sendMessage,
                inputText: phrase,
                inputRef: "",
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatchChatEvent]
    );

    const handleOptionMenu = React.useCallback(
        async (link, word, pip, iframe, tab) => {
            if (link !== undefined && link !== "#") {
                await dispatchMenuEvent({ type: addCurrentLink, link: link });

                if (pip !== undefined) {
                    dispatchMenuEvent({ type: openLinkOverlayPiP, link: link });
                    enterPictureInPicture();
                } else if (iframe !== undefined) {
                    dispatchMenuEvent({ type: openLinkOverlay, link: link });
                } else if (tab !== undefined) {
                    openExternalUrl(link);
                } else {
                    openExternalUrl(link);
                }
            }
            if (word !== undefined) {
                sendPhrase(word);
            }
        },

        [dispatchMenuEvent, enterPictureInPicture, openExternalUrl, sendPhrase]
    );

    const maxWidthMenu = React.useMemo(() => {
        return isLandscape ? "31.25rem" : "unset";
    }, [widthMainScreen, isLandscape]);

    const closeIconColor = React.useMemo(() => {
        if (menuState.menuTab === "menu") {
            return isMobile
                ? menuState?.mobileIcons[0]?.icon?.styles?.color ?? "white"
                : menuState?.icons[0]?.icon?.styles?.color ?? "white";
        }
        return isMobile
            ? menuState?.mobHistoryDisplay?.styles?.color ?? "white"
            : menuState?.historyDisplay?.styles?.color ?? "white";
    }, [
        menuState.menuTab,
        menuState?.mobileIcons,
        menuState?.icon,
        menuState?.mobHistoryDisplay,
        menuState?.historyDisplay,
        isMobile,
    ]);

    if (!videoState.isPlaying) {
        return null;
    }

    return (
        <SideNav
            id="mySidenav"
            className="sidenav"
            maxWidthMenu={maxWidthMenu}
            widthMainScreen={widthMainScreen}
            widthMenu={windowSize.width}
            isChatScreenMobile={isChatScreenMobile}
            menuBg={
                menuState.menuTab === "history-links"
                    ? isMobile && menuState?.mobHistoryBg
                        ? menuState?.mobHistoryBg
                        : menuState.historyBg
                    : isMobile
                    ? menuState.mobMenuBg
                    : menuState.menuBg
            }
            isOpen={
                menuState.showMenu &&
                videoState.isPlaying &&
                !(
                    menuState.menuTab === "history-links" &&
                    menuState.historyLinks.length === 0
                )
            }
            isFullScreen={videoState.fullScreen}
            isMobile={isMobile}
            isLandscape={isLandscape}
        >
            <CloseIcon
                onClick={closeNav}
                className="material-icons"
                isLandscape={isLandscape}
                style={{
                    color: closeIconColor,
                }}
            >
                {"close"}
            </CloseIcon>
            {menuState.menuTab === "history-links" && (
                <HistoryLinks
                    styleLink={
                        isMobile && menuState?.mobHistoryDisplay
                            ? menuState?.mobHistoryDisplay?.styles
                            : menuState?.historyDisplay?.styles
                    }
                    historyLinks={menuState.historyLinks}
                    dispatch={dispatchMenuEvent}
                    isLandscape={isLandscape}
                    overlayData={menuState.overlayData}
                    isMobile={!isLandscape}
                />
            )}
            {menuState.menuTab === "menu" && (
                <>
                    {!isMobile ? (
                        <>
                            {menuState.icons !== undefined &&
                                menuState.icons.map((el, index) => (
                                    <Option
                                        key={`option-${index}`}
                                        isMobile={!isLandscape}
                                        onClick={() =>
                                            handleOptionMenu(
                                                el.link?.link,
                                                el.link?.word,
                                                el.link?.pip,
                                                el.link?.iframe,
                                                el.link?.tab
                                            )
                                        }
                                    >
                                        <Icon
                                            className="material-icons"
                                            style={{
                                                ...el.icon?.styles,
                                                fontFamily: "Material Icons",
                                                fontSize:
                                                    el.icon?.styles?.fontSize &&
                                                    calculateFontSizeDependingOnSize(
                                                        el.icon?.styles
                                                            ?.fontSize
                                                    ),
                                            }}
                                        >
                                            {el.icon?.name}
                                        </Icon>
                                        <Link
                                            style={{
                                                ...el.display?.styles,
                                                fontSize:
                                                    el.display?.styles
                                                        ?.fontSize &&
                                                    calculateFontSizeDependingOnSize(
                                                        el.display?.styles
                                                            ?.fontSize
                                                    ),
                                            }}
                                            isMobile={!isLandscape}
                                        >
                                            {el.display?.name}
                                        </Link>
                                    </Option>
                                ))}
                        </>
                    ) : (
                        <>
                            {menuState &&
                                menuState.mobileIcons.map((el, index) => (
                                    <Option
                                        key={`option-mob-${index}`}
                                        isMobile={!isLandscape}
                                        onClick={() =>
                                            handleOptionMenu(
                                                el.link?.link,
                                                el.link?.word,
                                                el.link?.pip,
                                                el.link?.iframe,
                                                el.link?.tab
                                            )
                                        }
                                    >
                                        <Icon
                                            className="material-icons"
                                            style={{
                                                ...el.icon?.styles,
                                                fontFamily: "Material Icons",
                                            }}
                                        >
                                            {el.icon?.name}
                                        </Icon>
                                        <Link isMobile={!isLandscape}>
                                            {el.display?.name}
                                        </Link>
                                    </Option>
                                ))}
                        </>
                    )}
                </>
            )}
        </SideNav>
    );
};

export const Menu = React.memo(_Menu);
