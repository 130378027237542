import {
  toggleMenu,
  handleMenuReceived,
  toggleHistoryLinksMenu,
  showHistoryOverlay,
  handleHistoryLinksReceived,
  openLinkOverlayPiP,
  openLinkOverlay,
  hideConfirmOverlay,
  closeIframeMode,
  hideHistoryOverlay,
  openLinkNewTab,
  closeMenu,
  addCurrentLink,
  resetMenu,
  addHistoryMenuProps,
  showToastMessage,
  hideToastMessage,
} from "./actions";

export function menuReducer(state, action) {
  switch (action.type) {
    case addHistoryMenuProps:
      return {
        ...state,
        ...action.historyMenuProps,
      };

    case toggleMenu:
      if (state.showMenu && state.menuTab === "history-links") {
        return {
          ...state,
          menuTab: "menu",
        };
      } else {
        return { ...state, showMenu: !state.showMenu, menuTab: "menu" };
      }
    case closeMenu:
      return {
        ...state,
        showMenu: false,
      };
    case addCurrentLink:
      return {
        ...state,
        currentLinkIframe: action.link,
      };

    case handleMenuReceived:
      const { type, ...props } = action;
      return { ...state, ...props, menuTab: "menu" };
    case toggleHistoryLinksMenu:
      if (state.showMenu && state.menuTab === "menu") {
        return {
          ...state,
          menuTab: "history-links",
          isRedDot: false,
        };
      } else {
        return {
          ...state,
          showMenu: !state.showMenu,
          menuTab: "history-links",
          isRedDot: false,
        };
      }
    case showHistoryOverlay:
      return {
        ...state,
        isOverlayHistoryLink: true,
        overlayData: action.overlayData,
        isRedDot: state.showMenu ? false : true,
      };
    case handleHistoryLinksReceived:
      return {
        ...state,
        historyLinks: [...state.historyLinks, ...action.links],
      };
    case openLinkOverlay:
      return {
        ...state,
        isIframeMode: true,
        currentLinkIframe: action.link,
        isOverlayHistoryLink: false,
        showMenu: false,
      };
    case hideHistoryOverlay:
      return { ...state, isOverlayHistoryLink: false };
    case openLinkOverlayPiP:
      return {
        ...state,
        currentLinkIframe: action.link,
        isConfirmOverlay: true,
        isOverlayHistoryLink: false,
        showMenu: false,
      };
    case hideConfirmOverlay:
      return {
        ...state,
        isConfirmOverlay: false,
        isOpenLinkPiP: false,
      };
    case openLinkNewTab:
      return {
        ...state,
        isOpenLinkPiP: true,
      };
    case closeIframeMode:
      return { ...state, isIframeMode: false };
    case resetMenu:
      return {
        showMenu: false,
        menuIcon: "menu",
        menuTab: "",
        icons: [],
        mobileIcons: [],
        isRedDot: false,
        isOverlayHistoryLink: false,
        historyLinks: [],
        overlayData: null,
        isConfirmOverlay: false,
        isOpenLinkPiP: false,
        isIframeMode: false,
        currentLinkIframe: "",
      };
    case showToastMessage:
      // ToastMessage accept message, duration: ToastDuration.MEDIUM
      return {...state, toastMessage: { show: true, message: action.message, duration: action.duration}}
    case hideToastMessage:
      return {...state, toastMessage: { show: false, message: "" }}
    default:
      return { ...state };
  }
}
