import * as React from "react";
import {
  Container,
  ContentContainer,
  Description,
  Title,
} from "./network-overlay.styled";
import useNetworkStatus from "../../../../hooks/useNetworkStatus"

const NetworkOverlay = ({isMobile}) => {
   const { isOffline } = useNetworkStatus()

 if (isOffline) {
  return (
    <Container>
      <ContentContainer isMobile={isMobile}>
        <Title>{"You have been disconnected"}</Title>
        <Description>
          {
            "You will be reconnected as soon as your internet connection is restored."
          }
        </Description>
      </ContentContainer>
    </Container>
  );
};

 return null
} 

export default NetworkOverlay;
