import * as React from "react";
import {
  VideoControlsMobile,
  DisplayRemoteVideoMobile,
  DisplayStatusMessage,
  DisplayDialButtonMobile,
  DisplayConfirmOverlay,
} from "../../_components";
import { StyledPlayerControlsMobile } from "../../_styles";
import { setDisplayControls } from "../../_actions";
import ReactTouchEvents from "react-touch-events";
import { HistoryLinkOverlay } from "../history-link-overlay";
import RainbowSpinner from "../spinner";
import { AvatarPhotoMobile } from "../avatar-photo-mobile";
import ProgressBar from "../progressbar";
import { ToastContainer } from "../toast-message";

export const PlayerMobile = ({
  state,
  dispatch,
  dispatchChatEvent,
  chatState,
  menuState,
  dispatchMenuEvent,
  screenRef,
  isLandscape,
  isAspectRatio,
  widthMainScreen,
}) => {
  const isMobile = true;

  return (
    <React.Fragment>
      {DisplayStatusMessage({ dispatch, state })}

      <div
        style={{
          position: "absolute",
          zIndex: 1,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RainbowSpinner isVisible={state.isLoading} />
      </div>

      <ReactTouchEvents
        onTap={() =>
          state.isPlaying
            ? dispatch({
                type: setDisplayControls,
                displayControls: !state.displayControls,
              })
            : ""
        }
      >
        {DisplayRemoteVideoMobile({
          dispatch,
          state,
          menuState,
          isLandscape,
          screenRef,
          chatState,
          isMobile,
          isAspectRatio,
          widthMainScreen,
        })}
      </ReactTouchEvents>

      <AvatarPhotoMobile
        dispatch={dispatch}
        state={state}
        dispatchChatEvent={dispatchChatEvent}
        chatState={chatState}
      />
      {DisplayDialButtonMobile({
        dispatch,
        state,
        dispatchChatEvent,
        chatState,
      })}
        
      <ProgressBar key={1} bgcolor={'#ef6c00'} completed={state.loadProgress} />

      <HistoryLinkOverlay
        menuState={menuState}
        dispatchMenuEvent={dispatchMenuEvent}
      />

      <ToastContainer 
        menuState={menuState}
        dispatchMenuEvent={dispatchMenuEvent}
      />

      {DisplayConfirmOverlay({
        dispatch,
        state,
        dispatchChatEvent,
        chatState,
        menuState,
        dispatchMenuEvent,
        isMobile,
      })}

      <StyledPlayerControlsMobile
        pad="medium"
        fill="horizontal"
        isPlaying={state.isPlaying}
        isMobile={state.isMobile}
        fullScreen={state.fullScreen}
        isLandscape={isLandscape}
        onMouseEnter={() =>
          state.isPlaying
            ? dispatch({ type: setDisplayControls, displayControls: true })
            : ""
        }
        onMouseLeave={() =>
          state.isPlaying
            ? dispatch({ type: setDisplayControls, displayControls: false })
            : ""
        }
      >
        {VideoControlsMobile({
          dispatch,
          state,
          dispatchChatEvent,
          chatState,
          menuState,
          dispatchMenuEvent,
          isMobile,
          isLandscape,
        })}
      </StyledPlayerControlsMobile>
    </React.Fragment>
  );
};
