export const toggleMenu = "TOGGLE_MENU";
export const handleMenuReceived = "HANDLE_MENU_RECEIVED";
export const toggleHistoryLinksMenu = "TOGGLE_HISTORY_LINKS_MENU";
export const handleHistoryLinksReceived = "HANDLE_HISTORY_LINKS_RECEIVED";
export const showHistoryOverlay = "SHOW_HISTORY_OVERLAY";
export const openLinkOverlayPiP = "OPEN_LINK_OVERLAY_PIP";
export const openLinkOverlay = "OPEN_LINK_OVERLAY";
export const hideHistoryOverlay = "HIDE_HISTORY_OVERLAY";
export const closeIframeMode = "CLOSE_IFRAME_MODE";
export const hideConfirmOverlay = "HIDE_CONFIRM_OVERLAY";
export const openLinkNewTab = "OPEN_LINK_NEW_TAB";
export const closeMenu = "CLOSE_MENU";
export const addCurrentLink = "ADD_CURRENT_LINK";
export const resetMenu = "RESET_MENU";
export const addHistoryMenuProps = "ADD_HISTORY_MENU_PROPS";
export const showToastMessage = "SHOW_TOAST_MESSAGE"
export const hideToastMessage = "HIDE_TOAST_MESSAGE"
