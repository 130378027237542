import React from "react";
import { Box } from "grommet";
import { forceConfig } from "../../contexts/SDKContext";
import { exitFullScreen, setIsPlaying, setDisplayControls } from "./_actions";
import {
  openLinkNewTab,
  closeIframeMode,
  hideConfirmOverlay,
} from "../menu/actions";
import {
  ReplyIcon,
  StyledPlayerMobile,
  StyledAnchorDial,
  StyledPlayerWeb,
  StyledIconMobile,
  PhotoBoxWeb,
  AvatarPhotoWeb,
  StyledIconVideoCallWeb,
  Link,
  StatusMessage,
  DialButtonMobile,
  DialButtonWeb,
  OverlayButton,
  OverlayButtonsContainer,
  ConfirmOverlayContainer,
  ConfirmOverlayText,
  ContainerConfirmOverlay,
} from "./_styles";
import Util from "../../common/Util";
import { usePictureInPicture } from "../../hooks/usePictureInPicture";
import {
  detectDeviceType,
  detectIOSVersionSafari,
  detectIOSVersionBelow14Chrome,
} from "../../common/detectDevice";
import GrowingVideoControls from "../video/components/growing-video-controls";
import {
  MainWebControlsButtons,
  MainMobileControlsButtons,
} from "../video/components/controls";

var screenorientation = -1;
export function VideoControlsMobile({
  dispatch,
  state,
  dispatchChatEvent,
  isLandscape,
  menuState,
  dispatchMenuEvent,
  isMobile,
}) {
  const exitHandlerMobile = () => {
    exitHandler(dispatch);
  };

  const [timeStamp, setTimeStamp] = React.useState({
    start: 0,
    end: 0,
  });

  const handleTouchStart = (e) => {
    setTimeStamp({ ...timeStamp, start: e.timeStamp });
  };

  const handleOnTouchEnd = React.useCallback(
    (e, callback) => {
      if (!state.fullScreen && e.timeStamp - timeStamp.start < 150) {
        callback();
      }
      setTimeStamp({ start: 0, end: 0 });
    },
    [timeStamp.start, state.fullScreen]
  );

  React.useEffect(() => {
    function handleResize() {
      var screenRatio = window.innerHeight / window.innerWidth;
      if (
        state.trulience != null &&
        screenRatio > 1 &&
        screenorientation !== 1
      ) {
        screenorientation = 1; // portrait
        if (state.trulience.isConnected()) {
          state.trulience.sendMessage(
            "<trl-content orientation='portrait' />"
          );
        }
      } else if (
        state.trulience != null &&
        screenRatio <= 1 &&
        screenorientation !== 2
      ) {
        screenorientation = 2;
        if (state.trulience.isConnected()) {
          state.trulience.sendMessage(
            "<trl-content orientation='landscape' />"
          );
        }
      }
    }

    window.addEventListener("resize", handleResize);
    Util.addFullScreenListeners(exitHandlerMobile);
    return () => {
      Util.removeFullScreenListeners(exitHandlerMobile);
    };
  });

  if (!state.isPlaying) {
    return <Box direction="row-reverse" justify="center"></Box>;
  }
  if (state.isPlaying) {
    /* if(!state.displayControls) return ''; */
  }
  return (
    <Box direction="row" style={{ zIndex: 100000 }}>
      <Box direction="row" basis="1/3" />

      <Box direction="row" basis="1/3" justify="center" />
      {menuState.isIframeMode && (
        <div
          style={{
            color: "white",
            position: "absolute",
            top: "-70px",
            right: "10px",
          }}
        >
          <StyledIconMobile
            {...state}
            className="material-icons"
            onClick={() => dispatchMenuEvent({ type: closeIframeMode })}
            onTouchStart={handleTouchStart}
            onTouchEnd={(e) =>
              handleOnTouchEnd(e, () =>
                dispatchMenuEvent({ type: closeIframeMode })
              )
            }
          >
            {"reply"}
          </StyledIconMobile>
        </div>
      )}
      <Box direction="row" basis="1/3" justify="end">
        {!state.subtitlesEnabled && (
          <MainMobileControlsButtons
            isMobile={isMobile}
            isLandscape={isLandscape}
          />
        )}
        {state.subtitlesEnabled && (
          <GrowingVideoControls isLandscape={isLandscape} isMobile={true} />
        )}
      </Box>
    </Box>
  );
}

export function VideoControlsWeb({
  dispatch,
  state,
  menuState,
  dispatchMenuEvent,
  isLandscape,
  dispatchChatEvent,
}) {
  const exitHandlerWeb = () => {
    exitHandler(dispatch);
  };

  React.useEffect(() => {
    Util.addFullScreenListeners(exitHandlerWeb);
    return () => {
      Util.removeFullScreenListeners(exitHandlerWeb);
    };
  });

  

  

  if (!state.isPlaying) {
    return (
      <Box
        direction="row-reverse"
        justify="center"
        margin={{ top: "-5vh" }}
      ></Box>
    );
  }

  if (state.isPlaying) {
    /* if(!state.displayControls) return ''; */
  }
  return (
    <Box
      direction="row"
      justify="end"
      style={{
        marginRight: "0px",
      }}
    >
      {menuState.isIframeMode && (
        <div
          style={{
            color: "white",
            position: "absolute",
            top: `-4.375rem`,
            right: '1.5rem',
          }}
        >
          <ReplyIcon
            {...state}
            className="material-icons"
            onClick={() => dispatchMenuEvent({ type: closeIframeMode })}
            
            isMobile={false}
          >
            {"reply"}
          </ReplyIcon>
        </div>
      )}
      <Box direction="row" basis="1/3" justify="end">
        {!state.subtitlesEnabled && <MainWebControlsButtons />}
        {state.subtitlesEnabled && (
          <GrowingVideoControls isLandscape={isLandscape} />
        )}
      </Box>
    </Box>
  );
}

export function DisplayStatusMessage({ dispatch, state }) {
  if (state.statusMessageLink) {
    return (
      <StatusMessage {...state}>
        {state.statusMessage}
        <Link href={state.statusMessageLink} target="_blank">
          {state.statusMessageLink}
        </Link>
      </StatusMessage>
    );
  }

  if (state.statusMessage) {
    return <StatusMessage {...state}>{state.statusMessage}</StatusMessage>;
  }
}

export function DisplayRemoteVideoWeb({
  dispatch,
  state,
  menuState,
  heightMainScreen,
  isLandscape,
  chatState,
  isIpadTable,
  isAspectRatio,
  windowSize,
  isTabletWithOpenKeyboard,
}) {
  const paddingOpenMenu = React.useMemo(() => {
    if ((windowSize.width * 20) / 100 <= 250) {
      return "250px";
    }
    return `calc(${windowSize.width}px - 80%)`;
  }, [windowSize.width]);

  return (
    <>
      {document.pictureInPictureEnabled && menuState.isConfirmOverlay && (
        <ContainerConfirmOverlay
          bgColor={chatState.colors.dialPageBackground}
        />
      )}
      <StyledPlayerWeb
        id="remoteVideo"
        isIpadTable={isIpadTable}
        
        isTabletWithOpenKeyboard={isTabletWithOpenKeyboard}
        bgColor={chatState.colors.dialPageBackground}
        hideChatHistory={forceConfig.hideChatHistory}
        className={state.isPlaying ? "showRV" : "hideRV"}
        ref={state.videoElemRef}
        heightMainScreen={heightMainScreen}
        isLandscape={isLandscape}
        isScreenAspectRation={isAspectRatio}
        paddingOpenMenu={paddingOpenMenu}
        isOpenMenu={menuState.showMenu && state.isPlaying}
        playsInline={true}
        isIframeMode={menuState.isIframeMode}
        isPlaying={state.isPlaying}
        fullScreen={state.fullScreen || forceConfig.hideChatInput}
        autoPlay={true}
        onMouseEnter={() =>
          state.isPlaying
            ? dispatch({ type: setDisplayControls, displayControls: true })
            : ""
        }
        onMouseLeave={() =>
          state.isPlaying
            ? dispatch({ type: setDisplayControls, displayControls: false })
            : ""
        }
      />
    </>
  );
}

export function DisplayRemoteVideoMobile({
  dispatch,
  state,
  menuState,
  screenRef,
  isLandscape,
  chatState,
  isMobile,
  isAspectRatio,
  widthMainScreen,
}) {
  return (
    <Box
      className={
        state.isFSCinemaMod && !isLandscape && "remote-video-mobile-container"
      }
      style={{
        display: state.isPlaying ? "flex" : "none",
        position: "relative",
      }}
      display={state.isPlaying ? "none" : "none"}
      height={!state.fullScreen && "100%"}
      ref={screenRef}
    >
      {DisplayLogo({
        state,
        menuState,
        isLandscape,
        isMobile,
      })}
      {document.pictureInPictureEnabled && menuState.isConfirmOverlay && (
        <div
          style={{
            background: Util.is_url(chatState.colors.dialPageBackground)
              ? "unset"
              : chatState.colors.dialPageBackground,
            backgroundImage: Util.is_url(chatState.colors.dialPageBackground)
              ? `url(${chatState.colors.dialPageBackground})`
              : "unset",
            width: "100%",
            height: "100%",
            zIndex: 1,
            position: "absolute",
            top: "0px",
            left: "0px",
          }}
        >
          {" "}
        </div>
      )}
      {state.isDragResizeOverlay && (
        <div
          style={{
            display: "flex",
            background: "rgba(255,255,255,0.5)",
            alignItems: "center",
            justifyContent: "center",
            padding: '1rem',
            borderRadius: '0.375rem',
            position: "absolute",
            top: "70%",
            left: "50%",
            zIndex: 100000,
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box>Drag to resize</Box>
        </div>
      )}
      <StyledPlayerMobile
        id="remoteVideo"
        isLandscape={isLandscape}
        isAspectRatio={isAspectRatio}
        widthMainScreen={widthMainScreen}
        bgColor={chatState.colors.dialPageBackground}
        ref={state.videoElemRef}
        isMobile={state.isMobile}
        fullScreen={state.fullScreen}
        isFSCinemaMod={state.isFSCinemaMod}
        isIframeMode={menuState.isIframeMode}
        playsInline={true}
        autoPlay={true}
        onMouseEnter={() =>
          state.isPlaying
            ? dispatch({ type: setDisplayControls, displayControls: true })
            : ""
        }
        onMouseLeave={() =>
          state.isPlaying
            ? dispatch({ type: setDisplayControls, displayControls: false })
            : ""
        }
      />
    </Box>
  );
}

export function DisplayAvatarPhotoWeb({
  dispatch,
  state,
  chatState,
  isLandscape,
}) {

  if (state.isPlaying || state.invalidAvatar) {
    return null;
  }
  return (
    <PhotoBoxWeb>
      <AvatarPhotoWeb
        avatarPhotoURL={state.avatarPhotoURL}
        onClick={() => {
          if (window.trl) {
            window.trl.fixAudioContext();
          }  
          dispatch({ type: setIsPlaying })
        } }
      />
      <StyledAnchorDial
        colors={chatState.colors}
        style={{ bottom: "3vh" }}
        icon={
          <StyledIconVideoCallWeb
            className="material-icons"
            onClick={() => {
              if (window.trl) {
                window.trl.fixAudioContext();
              }  
              dispatch({ type: setIsPlaying })
            }}
          >
            videocam
          </StyledIconVideoCallWeb>
        }
      />
    </PhotoBoxWeb>
  );
}

export function DisplayDialButtonMobile({
  dispatch,
  state,
  dispatchChatEvent,
  chatState,
}) {
  if (state.isPlaying || state.invalidAvatar || forceConfig.hideLetsChatBtn) {
    return null;
  }

  return (
    <DialButtonMobile
      colors={chatState.colors}
      onClick={() => {
          if (forceConfig.deviceOrientation && typeof DeviceOrientationEvent.requestPermission === 'function') {
             DeviceOrientationEvent.requestPermission().then((response)=>{
                 if (response === 'granted') {
                     console.log('DeviceOrientation Permission failed');
                 }
                 dialFS(dispatch, dispatchChatEvent)
             }).catch((response)=>{
                 console.log('DeviceOrientation Permission failed');
                 dialFS(dispatch, dispatchChatEvent)
             });
          } else {
             dialFS(dispatch, dispatchChatEvent)
          }
      } }
      onTouchStart={(event) => {
          // if we run dialFS, for some reason, onClick will be masked and we wont 
          // get to ask for device orientation permission. so call dialFS only
          // if dragging is enabled
          if (!forceConfig.disableDragging)
             dialFS(dispatch, dispatchChatEvent)
      } }
      isMobile={state.isMobile}
    >
      {forceConfig.dialButtonText}
    </DialButtonMobile>
  );
}

export const DisplayConfirmOverlay = ({
  dispatch,
  state,
  isMobile,
  menuState,
  dispatchMenuEvent,
  chatState,
}) => {
  const { exitPictureInPicture, openExternalUrl } = usePictureInPicture();

  const [timeStamp, setTimeStamp] = React.useState({
    start: 0,
    end: 0,
  });

  const handleTouchStart = (e) => {
    setTimeStamp({ ...timeStamp, start: e.timeStamp });
  };

  const handleOnTouchEnd = React.useCallback(
    (e, callback) => {
      if (e.timeStamp - timeStamp.start < 150) {
        callback();
      }
      setTimeStamp({ start: 0, end: 0 });
    },
    [timeStamp.start]
  );

  const handleOpenUrl = React.useCallback(() => {
    openExternalUrl(menuState.currentLinkIframe);
    dispatchMenuEvent({ type: openLinkNewTab });
  }, [menuState.currentLinkIframe, dispatchMenuEvent, openExternalUrl]);

  const handleDisabledPiP = React.useCallback(() => {
    exitPictureInPicture();
    dispatchMenuEvent({ type: hideConfirmOverlay });
  }, [exitPictureInPicture, dispatchMenuEvent]);


  if (!menuState.isConfirmOverlay) return;
  return (
    <ConfirmOverlayContainer
      isMobile={isMobile}
      bgColor={chatState.colors.dialButtonTextColor}
      
    >
      <ConfirmOverlayText
        isMobile={isMobile}
        colors={chatState.colors}
        
       
      >
        {!menuState.isOpenLinkPiP
          ? "Open link in new tab?"
          : "Return to full screen"}
      </ConfirmOverlayText>
      <OverlayButtonsContainer>
        <OverlayButton
          isMobile={isMobile}
          colors={chatState.colors}
          onClick={handleDisabledPiP}
          onTouchStart={handleTouchStart}
          onTouchEnd={(e) => handleOnTouchEnd(e, handleDisabledPiP)}
         
         
        >
          {menuState.isOpenLinkPiP ? "OK" : "CANCEL"}
        </OverlayButton>
        {!menuState.isOpenLinkPiP && (
          <OverlayButton
            colors={chatState.colors}
            isMobile={isMobile}
            onTouchStart={handleTouchStart}
            onTouchEnd={(e) => handleOnTouchEnd(e, handleOpenUrl)}
            onClick={handleOpenUrl}
            
            
          >
            Ok
          </OverlayButton>
        )}
      </OverlayButtonsContainer>
    </ConfirmOverlayContainer>
  );
};

export function DisplayDialButtonWeb({
  dispatch,
  state,
  dispatchChatEvent,
  chatState,
}) {
  

  if (state.isPlaying || state.invalidAvatar || forceConfig.hideLetsChatBtn) {
    return null;
  }
  return (
    <DialButtonWeb
      colors={chatState.colors}
      onClick={() => {
        if (window.trl) {
          window.trl.fixAudioContext();
        }
        dispatch({ type: setIsPlaying })
      } }
      isMobile={state.isMobile}
    >
      {forceConfig.dialButtonText}
    </DialButtonWeb>
  );
}

export function DisplayLogo({
  state,
  menuState,
  isLandscape,
  isMobile,
  isIpadTable,
}) {

  if (!state.isPlaying || state.logoProps.showLogo !== "true") return null;

  const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
  const device = detectDeviceType();
  const { major } = detectIOSVersionSafari();
  const isIOSBelow14 =
    detectIOSVersionBelow14Chrome() || (device === "IOS" && +major < 14);

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        justifyContent:
          state.logoProps.logoPosition === "right" ? "flex-end" : "flex-start",
        top:
          state.isFSCinemaMod && state.fullScreen && !isLandscape
            ? "35%"
            : '1rem',
        left: state.logoProps.logoPosition === "left" ? '1rem' : "unset",
        right: state.logoProps.logoPosition === "right" ? '1rem' : "unset",
        zIndex: 1,
        height: !isMobile && !isIpadTable && "15%",
        width: !isMobile && !isIpadTable && "15%",
        opacity:
          menuState.showMenu &&
          state.logoProps.logoPosition === "left" &&
          isLandscape
            ? 0
            : 1,
        transition:
          menuState.showMenu &&
          state.logoProps.logoPosition === "left" &&
          isLandscape
            ? "opacity 0.5s"
            : "opacity 2s",
        minWidth: !isMobile && !isIpadTable && !isSafari && '3.125rem',
        minHeight: !isMobile && !isIpadTable && !isSafari && '3.125rem',
        maxWidth: !isMobile && !isIpadTable && !isSafari && '6.25rem',
        maxHeight: isIOSBelow14
          ? "15%"
          : !isMobile && !isIpadTable && !isSafari && '6.25rem',
        transform:
          state.isFSCinemaMod &&
          state.fullScreen &&
          !isLandscape &&
          "translate(0%, -50%)",
      }}
      id="logo"
    >
      <img
        src={
          state.logoProps.logoSrc === ""
            ? require("../../common/trulience_logo.png")
            : state.logoProps.logoSrc
        }
        alt="logo"
        style={{
          width: isIOSBelow14 && "100%",
          height: isIOSBelow14 && "100%",
          maxHeight: isMobile || isIpadTable ? "15%" : isSafari && "100%",
          maxWidth: isIpadTable || isMobile ? "15%" : isSafari && "100%",
        }}
      />
    </div>
  );
}

const dialFS = (dispatch, event) => {
    if (window.trl) {
      window.trl.fixAudioContext();
    }

    dispatch({ type: setIsPlaying });
};

const exitHandler = (dispatch) => {
  if (
    document.webkitIsFullScreen === false ||
    document.mozFullScreen === false ||
    document.msFullscreenElement === false
  ) {
    dispatch({ type: exitFullScreen });
  }
};
