import React from "react";
import { ChatScreen } from "./_components";
import { SDKContext } from "../../contexts/SDKContext";
import { StyledResizableBox } from "./_styles";

import { forceConfig } from "../../contexts/SDKContext";
import ChatInput from "../chat/components/chat-input";

const Chat = ({
  chatSize,
  isLandscape,
  widthMainScreen,
  isIpadTable,
  windowSize,
  isTablet,
  isTabletWithOpenKeyboard,
  heightMainScreen,
}) => {
  let inputTextAreaRef = React.useRef();
  const [videoState, , state, dispatch, menuState] =
    React.useContext(SDKContext);

  let chatComponents = null;

  React.useEffect(() => {
    if (
      !forceConfig.hideChatInput &&
      !videoState.fullScreen &&
      !menuState.isIframeMod &&
      videoState.isPlaying &&
      isLandscape
    ) {
      if (inputTextAreaRef.current) {
        inputTextAreaRef.current.blur();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandscape]);

  if (!videoState.isPlaying || menuState.isIframeMode) {
    chatComponents = null;
  } else if (state.isMobile.any && !isTablet) {
    if (!videoState.fullScreen) {
      chatComponents = (
        /*  <>
        {!isLandscape && */
        <StyledResizableBox
          width={chatSize.width}
          height={chatSize?.height}
          handleSize={[windowSize.width, windowSize.height]}
          resizeHandles={["n"]}
        >
          <React.Fragment>
            <ChatInput
              dispatch={dispatch}
              state={state}
              inputTextAreaRef={inputTextAreaRef}
              widthMainScreen={widthMainScreen}
              isLandscape={isLandscape}
              isTablet={isTablet}
            />
            {ChatScreen({ state, windowSize })}
          </React.Fragment>
        </StyledResizableBox> /* )}
        </> */
      );
    }
  } else {
    chatComponents = (
      <>
        {ChatScreen({
          state,
          isLandscape,
          isIpadTable,
          windowSize,
          isTablet,
          isTabletWithOpenKeyboard,
          heightMainScreen,
        })}
        <ChatInput
          dispatch={dispatch}
          state={state}
          inputTextAreaRef={inputTextAreaRef}
          widthMainScreen={widthMainScreen}
          isLandscape={isLandscape}
          isTablet={isTablet}
          videoState={videoState}
        />
      </>
    );
  }
  return <React.Fragment>{chatComponents}</React.Fragment>;
};
export default Chat;
