import styled from "styled-components";
export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000000;
    top: 0rem;
    background-color: rgba(255, 255, 255, 0.5);
  `,
  ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: ${(props) =>
      props.isMobile
        ? `calc(100% - 2.5rem)`
        : '25rem'};
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: rgba(255, 255, 255);
    border: 1px solid black;
  `,
  Title = styled.p`
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 0.5rem;
    z-index: 10000001;
  `,
  Description = styled.p`
    font-size: 1.1rem;
  `;
