import React, { useContext } from "react";
import styled from "styled-components";
import { SDKContext } from "../../../../contexts/SDKContext";
import { toggleBigPlayButton } from "../../_actions";


const Container = styled.div`
    background: rgba(255,255,255,0.4);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width:100%;
    height:100%;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
`

const Icon = styled.span`
    font-size: 5rem;
    color: white;
    z-index: 1000;
`

const BigPlayButton = () => {

    const [
        videoState,
        dispatch,
    ] = useContext(SDKContext)

    const handlePlay = () => {
        const video = document.getElementById("remoteVideo");
        console.log("Aks 8 before trying video.play");
        video.play();
        dispatch({ type: toggleBigPlayButton })
    }

    if(!videoState.showBigPlayButton) return null 

    return (
        <Container 
            id="play-button" 
            onClick={handlePlay}
            onTouchStart={handlePlay}
        >
            <Icon className="material-icons">play_arrow</Icon>
        </Container>
    )
}

export default BigPlayButton;
