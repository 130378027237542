import * as React from "react";
import {
  Container,
  ContainerControls,
  WrapperControls,
  StyledMoreIcon,
} from "./_styles";
import { StyledAnchor } from "../../_styles";
import { MainWebControlsButtons, MainMobileControlsButtons } from "../controls";
import { SDKContext, forceConfig } from "../../../../contexts/SDKContext";
import useOnClickWithTouchHandler from "../../../../hooks/useOnClickWithTouchHandler";

const GrowingVideoControls = ({ isLandscape, isMobile }) => {
  const [hover, setHover] = React.useState(false);

  const [state, , , , menuState, ,] = React.useContext(SDKContext);

  const desktopNumberOfVisibleElements = [
    !forceConfig.hideHangUpButton,
    !forceConfig.hideMicButton,
    menuState.historyLinks.length > 0 && !menuState.isIframeMode,
    menuState.icons.length > 0 && !menuState.isIframeMode,
    forceConfig.showSubtitlesButton,
    !forceConfig.hideFS,
  ].filter((value) => value).length;

  const mobileNumberOfVisibleElements = [
    !forceConfig.hideHangUpButton,
    !forceConfig.hideMicButton,
    state.fullScreen && !isLandscape,
    menuState.historyLinks.length > 0 && !menuState.isIframeMode,
    menuState.icons.length > 0 && !menuState.isIframeMode,
    forceConfig.showSubtitlesButton,
    !forceConfig.hideFS,
  ].filter((value) => value).length;

  const toggleMenu = () => {
    if (isMobile) {
      setHover((prev) => !prev);
    }
  };

  const handleToggleMenu = useOnClickWithTouchHandler(toggleMenu);

  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ContainerControls
        isOpenControls={hover}
        isMobile={isMobile}
        numberOfVisibleElements={
          isMobile
            ? mobileNumberOfVisibleElements
            : desktopNumberOfVisibleElements
        }
      >
        <WrapperControls isOpenControls={hover}>
          {isMobile ? (
            <MainMobileControlsButtons />
          ) : (
            <MainWebControlsButtons />
          )}
        </WrapperControls>
      </ContainerControls>
      <div style={{ position: "relative" }}>
        <StyledAnchor
          {...handleToggleMenu}
          icon={
            <StyledMoreIcon
              className="material-icons-outlined"
              isMobile={isMobile}
            >
              {"more_horiz"}
            </StyledMoreIcon>
          }
        />
      </div>
    </Container>
  );
};

export default GrowingVideoControls;
