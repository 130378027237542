import React from "react";
import {
  openLinkOverlay,
  openLinkOverlayPiP,
  hideHistoryOverlay,
} from "../../menu/actions";
import {
  OverlayTextContainer,
  HistoryLinkOverlayContainer,
  LinkIcon,
  CloseIcon,
  ContainerLink,
} from "../_styles";
import { usePictureInPicture } from "../../../hooks/usePictureInPicture";

const _HistoryLinkOverlay = ({ menuState, dispatchMenuEvent }) => {
  const { enterPictureInPicture, openExternalUrl } = usePictureInPicture();
  const [timeStamp, setTimeStamp] = React.useState({
    start: 0,
    end: 0,
  });


  const handleTouchStart = (e) => {
    setTimeStamp({ ...timeStamp, start: e.timeStamp });
  };

  const handleOnTouchEnd = React.useCallback(
    (e, callback) => {
      if (e.timeStamp - timeStamp.start < 150) {
        callback();
      }
      setTimeStamp({ start: 0, end: 0 });
    },
    [timeStamp.start]
  );

  if (menuState.isIframeMode) return;

  return (
    <HistoryLinkOverlayContainer
      
      isOverlayHistoryLink={menuState.isOverlayHistoryLink}
      style={{ ...{ ...menuState.overlayData?.box?.styles } }}
     
    >
      <ContainerLink
        id="link-icon-container"
        style={{
          ...menuState.overlayData?.icon?.styles,
          fontFamily: "Material Icons",
        }}
        
      >
        <LinkIcon
          {...menuState}
          figure={menuState.overlayData?.icon?.figure}
          className="material-icons"
         
      
          onTouchStart={handleTouchStart}
          onTouchEnd={(e) =>
            handleOnTouchEnd(e, () => {
              if (menuState.overlayData.open === "pip") {
                dispatchMenuEvent({
                  type: openLinkOverlayPiP,
                  link: menuState.overlayData.url,
                });
                enterPictureInPicture();
              } else if (menuState.overlayData.open === "iframe") {
                dispatchMenuEvent({
                  type: openLinkOverlay,
                  link: menuState.overlayData.url,
                });
              } else {
                openExternalUrl(menuState.overlayData.url);
              }
            })
          }
          onClick={() => {
            if (menuState.overlayData.open === "pip") {
              dispatchMenuEvent({
                type: openLinkOverlayPiP,
                link: menuState.overlayData.url,
              });
              enterPictureInPicture();
            } else if (menuState.overlayData.open === "iframe") {
              dispatchMenuEvent({
                type: openLinkOverlay,
                link: menuState.overlayData.url,
              });
            } else {
              openExternalUrl(menuState.overlayData.url);
            }
          }}
        >
          {menuState.overlayData?.icon?.name ?? "link"}
        </LinkIcon>
      </ContainerLink>

      <OverlayTextContainer
        onTouchStart={handleTouchStart}
        onTouchEnd={(e) =>
          handleOnTouchEnd(e, () => {
            if (menuState.overlayData.open === "pip") {
              dispatchMenuEvent({
                type: openLinkOverlayPiP,
                link: menuState.overlayData.url,
              });
              enterPictureInPicture();
            } else if (menuState.overlayData.open === "iframe") {
              dispatchMenuEvent({
                type: openLinkOverlay,
                link: menuState.overlayData.url,
              });
            } else {
              openExternalUrl(menuState.overlayData.url);
            }
          })
        }
        onClick={() => {
          if (menuState.overlayData.open === "pip") {
            dispatchMenuEvent({
              type: openLinkOverlayPiP,
              link: menuState.overlayData.url,
            });
            enterPictureInPicture();
          } else if (menuState.overlayData.open === "iframe") {
            dispatchMenuEvent({
              type: openLinkOverlay,
              link: menuState.overlayData.url,
            });
          } else {
            openExternalUrl(menuState.overlayData.url);
          }
        }}
      >
        <p style={{ ...menuState.overlayData?.display?.styles, fontSize: '1.125rem'}}>
          {menuState.overlayData?.display?.name}
        </p>
      </OverlayTextContainer>

      <CloseIcon
        {...menuState}
        className="material-icons"
        onTouchStart={handleTouchStart}
        onTouchEnd={(e) =>
          handleOnTouchEnd(e, () =>
            dispatchMenuEvent({ type: hideHistoryOverlay })
          )
        }
        onClick={() => dispatchMenuEvent({ type: hideHistoryOverlay })}
        style={{
          ...menuState.overlayData?.display?.styles,
          fontFamily: "Material Icons",
        }}
      >
        {"close"}
      </CloseIcon>
    </HistoryLinkOverlayContainer>
  );
};

export const HistoryLinkOverlay = React.memo(_HistoryLinkOverlay);
