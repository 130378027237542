import * as React from "react";
import { Link, Option } from "./menu.styles";
import { usePictureInPicture } from "../../hooks/usePictureInPicture";
import { useTouchHandle } from "../../hooks/useTouchHandle";
import { openLinkOverlayPiP, openLinkOverlay } from "./actions";

const _HistoryLinks = ({
  historyLinks,
  dispatch,
  overlayData,
  isLandscape,
  styleLink,
  isMobile,
}) => {
  const { enterPictureInPicture, openExternalUrl } = usePictureInPicture();
  // const { handleTouchStart, handleOnTouchEnd } = useTouchHandle();


  const handleOnClick = (link) => {
    switch (link.open) {
      case "pip":
        dispatch({ type: openLinkOverlayPiP, link: link?.url });
        enterPictureInPicture();
        break;
      case "iframe":
        dispatch({ type: openLinkOverlay, link: link?.url });
        break;
      default:
        openExternalUrl(link?.url);
        break;
    }
  }

  return (
    <>
      {historyLinks.map((link) => (
        <Option
          key={link?.title}
          isMobile={!isLandscape}
          style={{ ...styleLink }}
        >
          <Link
            // onTouchStart={handleTouchStart}
            // href={"#"}
            isMobile={isMobile}
            onClick={() => handleOnClick(link)}
            // onTouchEnd={(e) =>
            //   handleOnTouchEnd(e, () => handleOnClick(link))
            // }
            isHistoryLink={true}
            style={{
              color: styleLink?.color ?? "white",
              fontSize: styleLink?.fontSize ?? undefined
            }}
          >
            {link?.title}
          </Link>
        </Option>
      ))}
    </>
  );
};

export const HistoryLinks = React.memo(_HistoryLinks);
