export const toggleFullScreen = "TOGGLE_FULL_SCREEN";
export const exitFullScreen = "EXIT_FULL_SCREEN";
export const setIsPlaying = "SET_IS_PLAYING";
export const stopPlaying = "SET_IS_PLAYING_TO_FALSE";
export const setMicMuted = "SET_MIC_MUTED";
export const setSpeakerMuted = "SET_SPEAKER_MUTED";
export const toggleFSCinemaMod = "TOGGLE_FS_CINEMA_MOD";
export const toggleWebRtcMic = "TOGGLE_WEBRTC_MIC";
export const toggleSpeaker = "TOGGLE_SPEAKER";
export const setTrulienceObject = "SET_TRULIENCE_PLAYER";
export const setProgress = "SET_PROGRESS";
export const setIsLoading = "SET_IS_LOADING";
export const setStatusMessage = "SET_STATUS_MESSAGE";
export const setDisplayControls = "SET_DISPLAY_CONTROLS";
export const setAvatarPhotoURL = "SET_AVATAR_PHOTO_URL";
export const setWebRtcNotSupported = "SET_WEBRTC_NOT_SUPPORTED";
export const setInvalidAvatar = "SET_INVALID_AVATAR";
export const hideDragResizeOverlay = "HIDE_DRAG_RESIZE_OVERLAY";
export const setScreenAspectRationProps = "SET_SCREEN_ASPECT_RATIO_PROPS";
export const setMobileScreenAspectRationProps =
  "SET_MOBIlE_SCREEN_ASPECT_RATIO_PROPS";
export const setTabletScreenAspectRationProps =
  "SET_TABLET_SCREEN_ASPECT_RATIO_PROPS";
export const setLogoProps = "SET_LOGO_PROPS";
export const setEnabledSubtitles = 'SET_ENABLED_SUBTITLES';
export const restartIce = 'RESTART_ICE';
export const toggleBigPlayButton = "TOGGLE_BIG_PLAY_BUTTON";
