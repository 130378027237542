import * as React from "react";
import {
  isIpad,
  isMobileDevice,
  detectDeviceType,
} from "../common/detectDevice";
import useWindowSize from "./useWindowSize";

export const useBrowserType = () => {
  const { windowSize } = useWindowSize();

  const device = detectDeviceType();

  const isSafari = React.useMemo(() => device === "IOS", [device]);

  const isSafariMobile = React.useMemo(
    () =>
      device !== "Desktop" &&
      device !== "ChromeDesktop" &&
      device !== "IOSChrome" &&
      device !== "Android" &&
      device !== "SamsungBrowser",
    [device]
  );

  const isMobile = React.useMemo(
    () =>
      (device !== "Desktop" &&
        device !== "ChromeDesktop" &&
        isIpad() === false) ||
      isMobileDevice(windowSize),
    [device, windowSize]
  );
  const isLandscape = React.useMemo(
    () =>
      device !== "Desktop" &&
      device !== "ChromeDesktop" &&
      isIpad() === false &&
      windowSize.width > windowSize.height &&
      device !== "AndroidTab",
    [device, windowSize.height, windowSize.width]
  );
  const isChromeIOS = React.useMemo(() => {
    return device === "IOSChrome";
  }, [device]);

  const isTabletView = React.useMemo(
    () =>
      (device === "Desktop" || device === "ChromeDesktop") &&
      windowSize.width < 768,
    [device, windowSize]
  );

  const isAndroidMobile = React.useMemo(() => {
    return device === "Android" && isMobileDevice(windowSize);
  }, [device, windowSize]);

  const isAndroid = React.useMemo(() => {
    return device === "Android";
  }, [device]);

  const isSamsungBrowser = React.useMemo(() => {
    return device === "SamsungBrowser";
  }, [device]);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

  const isWindows = navigator.platform.indexOf("Win") > -1;

  const isIOS = device === "IOS";

  return {
    isSafari,
    isMobile,
    isChromeIOS,
    isLandscape,
    isTabletView,
    isSafariMobile,
    isAndroidMobile,
    isSamsungBrowser,
    isAndroid,
    isFirefox,
    isIOS,
    isWindows,
  };
};
