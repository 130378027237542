const NO_OP = (message, ...optionalParams) => {};

export class ConsoleLogger {
  constructor() {
    this.log = null;
    this.warn = null;
    this.error = null;

    const urlParams = new URLSearchParams(window.location.search);
    const lsLogLevel = urlParams.get("lsloglevel");
    let logLevel = "error";
    if (lsLogLevel === "log" || lsLogLevel === "warn") {
      logLevel = lsLogLevel;
    }
    const level = logLevel;

    this.error = console.error.bind(console);

    if (level === "error") {
      this.warn = NO_OP;
      this.log = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console);

    if (level === "warn") {
      this.log = NO_OP;

      return;
    }

    this.log = console.log.bind(console);
  }
}
