import styled from "styled-components";

export const ToastContainerStyle = styled.div`
  display: flex;
  padding: 0.4375rem;
  align-items: center;
  min-width: 16.875rem;
  min-height: 2.5rem;
  width: min(80vw, 20rem);
  background: white;
  position: absolute;
  transition: top 2s;
  z-index: 2;
  border-radius: 0.375rem;
  cursor: pointer;
  left: 50%;
  top: 0px;
  transition: transform 0.5s;
  transform: ${(props) =>
    props.show ? "translate(-50%, 20%)" : "translate(-50%, -100%)"};
`;

export const ToastIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2rem;
  height: 1.5rem;
  margin-right: 0.2rem;
`;

export const ToastIcon = styled.i`
  max-width: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const ToastMessageStyle = styled.div`
  font-size: 1rem;
  width: 85%;
`;

export const ToastCloseIcon = styled.i`
  font-size: 1.4rem !important;
  font-family: Material Icons;
  margin-right: 0.3125rem;
  margin-left: auto;
  color: black;
  cursor: pointer;
  position: absolute;
  top: 0.0625rem;
  right: -0.3125rem;
  padding: 0px 0px 12px 12px;
`;
