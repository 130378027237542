import * as React from "react";
import { SDKContext, forceConfig } from "../../../../contexts/SDKContext";
import {
    toggleFullScreen,
    setIsPlaying,
    setMicMuted,
    setSpeakerMuted,
    setEnabledSubtitles,
} from "../../_actions";
import {
    toggleMenu,
    toggleHistoryLinksMenu,
    resetMenu,
} from "../../../menu/actions";
import {
    HistoryIcon,
    HistoryIconContainer,
    Dot,
    StyledAnchor,
    StyledIconWeb,
    StyledIconPhoneDown,
    PulseBox,
} from "../../_styles";
import { clearInput, clearChatHistoryOnReload } from "../../../chat/_actions";

export const MainWebControlsButtons = () => {
    const [state, dispatch, , dispatchChatEvent, menuState, dispatchMenuEvent] =
        React.useContext(SDKContext);

    return (
        <>
            {!forceConfig.hideHangUpButton && (
                <StyledAnchor
                    icon={
                        <StyledIconPhoneDown
                            className="material-icons"
                            onClick={() => {
                                dispatchMenuEvent({ type: resetMenu });
                                dispatch({ type: setIsPlaying });
                                dispatchChatEvent({ type: clearInput });
                                dispatchChatEvent({
                                    type: clearChatHistoryOnReload,
                                });
                            }}
                        >
                            {state.isPlaying ? "call_end" : "play_arrow"}
                        </StyledIconPhoneDown>
                    }
                />
            )}
            {!forceConfig.hideMicButton && (
                <div style={{ position: "relative" }}>
                    <StyledAnchor
                        style={{ zIndex: 100 }}
                        icon={
                            <StyledIconWeb
                                className="material-icons"
                                onClick={() => dispatch({ type: setMicMuted })}
                            >
                                {state.micMuted ? "mic" : "mic_off"}
                            </StyledIconWeb>
                        }
                    />
                    <PulseBox
                        className={
                            state.isPlaying &&
                            state.micMuted &&
                            !state.isLoading
                                ? "pulse-animation"
                                : ""
                        }
                    />
                </div>
            )}
            {!forceConfig.hideSpeakerButton && (
                <div style={{ position: "relative" }}>
                    <StyledAnchor
                        style={{ zIndex: 100 }}
                        icon={
                            <StyledIconWeb
                                className="material-icons"
                                onClick={() => {
                                    if (window.trl) {
                                      window.trl.fixAudioContext();
                                    }  
                                    dispatch({ type: setSpeakerMuted })
                                } }
                            >
                                {state.speakerMuted
                                    ? "volume_off"
                                    : "volume_up"}
                            </StyledIconWeb>
                        }
                    />
                </div>
            )}
            {menuState.historyLinks.length > 0 && !menuState.isIframeMode && (
                <HistoryIconContainer>
                    {menuState.isRedDot &&
                        menuState.historyLinks.length > 0 && <Dot />}
                    <StyledAnchor
                        icon={
                            <HistoryIcon
                                className="material-icons"
                                onClick={() =>
                                    dispatchMenuEvent({
                                        type: toggleHistoryLinksMenu,
                                    })
                                }
                            >
                                {"history"}
                            </HistoryIcon>
                        }
                    />
                </HistoryIconContainer>
            )}
            {menuState.icons.length > 0 && !menuState.isIframeMode && (
                <StyledAnchor
                    icon={
                        <StyledIconWeb
                            className="material-icons"
                            onClick={() =>
                                dispatchMenuEvent({ type: toggleMenu })
                            }
                        >
                            {menuState.menuIcon ?? "menu"}
                        </StyledIconWeb>
                    }
                />
            )}
            {forceConfig.showSubtitlesButton && (
                <div style={{ position: "relative" }}>
                    {state.subtitlesEnabled && <Dot colorDot="green" />}
                    <StyledAnchor
                        icon={
                            <StyledIconWeb
                                className="material-icons-outlined"
                                onClick={() =>
                                    dispatch({ type: setEnabledSubtitles })
                                }
                            >
                                {"closed_caption"}
                            </StyledIconWeb>
                        }
                    />
                </div>
            )}
            {!forceConfig.hideFS && (
                <StyledAnchor
                    icon={
                        <StyledIconWeb
                            className="material-icons"
                            onClick={async () => {
                                try {
                                    const ascene =
                                    document.querySelector("a-scene");
                                    const supportsVR =
                                        await navigator.xr.isSessionSupported(
                                            "immersive-vr"
                                        );
                                if (ascene && supportsVR)
                                    state.fullScreen
                                        ? ascene.exitVR() &&
                                            console.log("leaving VR")
                                        : ascene.enterVR() &&
                                            console.log("entering VR");
                                else dispatch({ type: toggleFullScreen });

                                } catch(error) {
                                    // on error [isSessionSupported() is not allowed inside iframe] - open in fullscreen
                                    dispatch({ type: toggleFullScreen });
                                }
                            }}
                        >
                            {state.fullScreen
                                ? "fullscreen_exit"
                                : "fullscreen"}
                        </StyledIconWeb>
                    }
                />
            )}
        </>
    );
};
