import React from "react";
import { ConsoleLogger } from "../../common/logger";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { sendMessage } from "./_actions";
import {
  StyledChatScreenWeb,
  StyledChatScreenMobile,
  StyledBubbleContainerUser,
  StyledBubbleUser,
  StyledBubbleContainerBot,
  StyledBubbleBot,
  DialButton,
  Link,
} from "./_styles";
import Util from "../../common/Util";
import { forceConfig } from "../../contexts/SDKContext";
import { Menu } from "../menu/menu";
import { detectDeviceType } from "../../common/detectDevice";

const logger = new ConsoleLogger();

export function ChatScreen({
  state,
  isLandscape,
  isIpadTable,
  windowSize,
  isTablet,
  isTabletWithOpenKeyboard,
  heightMainScreen,
}) {
  return state.isMobile.any && !isTablet ? (
    ChatScreenMobile({ state, isLandscape, windowSize, isChatScreenMobile: true })
  ) : (
    <div style={{ position: "relative" }}>
      {!isLandscape && (
        <Menu
          isLandscape={isLandscape}
          isMobile={true}
          windowSize={windowSize}
        />
      )}
      {ChatScreenWeb({
        state,
        isLandscape,
        isIpadTable,
        isTabletWithOpenKeyboard,
        heightMainScreen,
        windowSize,
       
      })}
    </div>
  );
}

export function ChatDial({ dispatch, state }) {
  return (
    <DialButton
      colors={state.colors}
      onClick={() => {
        dispatchMessage(state, dispatch);
      }}
      isMobile={state.isMobile}
    >
      {" "}
      Let's Talk{" "}
    </DialButton>
  );
}

export function ChatScreenWeb({
  state,
  isLandscape,
  isIpadTable,
  isTabletWithOpenKeyboard,
  heightMainScreen,
  windowSize,
}) {
  

  if (forceConfig.hideChatHistory) {
    return null;
  }
  if (!state.messages) {
    return (
      <StyledChatScreenWeb
        id="screen"
        overflow="scroll"
        isMobile={state.isMobile}
        inputFocussed={state.inputFocussed}
        colors={state.colors}
        isIpadTable={isIpadTable}
        hideChatInput={forceConfig.hideChatInput}
        isTabletWithOpenKeyboard={isTabletWithOpenKeyboard}
        heightMainScreen={heightMainScreen}
        hightClient={windowSize.height}
      />
    );
  }
  let msgArr = [...state.messages];
  return (
    <StyledChatScreenWeb
      id="screen"
      hideChatInput={forceConfig.hideChatInput}
      overflow="scroll"
      isMobile={state.isMobile}
      isLandscape={isLandscape}
      isIpadTable={isIpadTable}
      inputFocussed={state.inputFocussed}
      colors={state.colors}
      isTabletWithOpenKeyboard={isTabletWithOpenKeyboard}
      heightMainScreen={heightMainScreen}
      hightClient={windowSize.height}
      
    >
      {msgArr.reverse().map((msg, index) => {
        const isLast = index === msgArr.length - 1;
        return msg.type === 0
          ? ChatBubbleBot(
              msg,
              state.isMobile,
              state.colors,
              isLast,
             
            )
          : ChatBubbleUser(
              msg,
              state.isMobile,
              state.colors,
              isLast,
            
            );
      })}
    </StyledChatScreenWeb>
  );
}

export function ChatScreenMobile({ state, isLandscape, windowSize, isChatScreenMobile }) {
  if (forceConfig.hideChatHistory) {
    return null;
  }
  if (!state.messages) {
    return (
      <StyledChatScreenMobile
        id="screen"
        overflow="scroll"
        isMobile={state.isMobile}
        inputFocussed={state.inputFocussed}
        colors={state.colors}
      />
    );
  }
  let msgArr = [...state.messages];
  return (
    <StyledChatScreenMobile
      id="screen"
      overflow="scroll"
      isMobile={state.isMobile}
      inputFocussed={state.inputFocussed}
      colors={state.colors}
    >
      <div style={{ position: "relative" }}>
        {
          <Menu
            isLandscape={isLandscape}
            isMobile={true}
            windowSize={windowSize}
            isChatScreenMobile={isChatScreenMobile}
          />
        }
        {msgArr.reverse().map((msg) => {
          return msg.type === 0
            ? ChatBubbleBot(msg, state.isMobile, state.colors)
            : ChatBubbleUser(msg, state.isMobile, state.colors);
        })}
      </div>
    </StyledChatScreenMobile>
  );
}

const toLink = (tag, index) => {
  return (
    <Link
      key={index}
      href={tag.attributes.url}
      target={tag.attributes.target}
      title={tag.attributes.title}
      isUnderline={true}
    >
      {Util.parseFieldName(tag.attributes.display)}
    </Link>
  );
};

// `That's easy! In order to complete an employee upload, you need to enter the necessary details on the upload template.
// <trl-break duration='1.0' />\nFind more information
// <trl-link url=\"https://zws.zurich.ae/documents\" title=\"DEWS Documentation\" speech=\"at zws.zurich.ae/documents\" display=\"here\" target=\"_blank\" />.
// Feel free to visit.
// <trl-anim type='aux' id='smileMedium' duration='3.5' blendStart='0.5' blendEnd='0.5'/>
// Have a nice day.`;
const convertTokensToComponent = (text, messageTokens) => {
  if (!text) return null;

  let index = 0;
  let msgComponents = [];

  if (!messageTokens || messageTokens.length === 0) {
    if (Util.is_url(text))
      msgComponents.push(
        <Link key={index} href={Util.formatURL(text)} target="_blank">
          {text}
        </Link>
      );
    else if (!text.startsWith("<")) msgComponents.push(text);
    return msgComponents;
  }

  for (let msgToken of messageTokens) {
    try {
      if (msgToken.type === "XML" && msgToken.name !== "trl-link") continue;

      if (msgToken.type === "TEXT" && !msgToken.content) continue;

      if (msgToken.type === "XML") {
        msgComponents.push(toLink(msgToken, index));
      } else if (Util.is_url(msgToken.content)) {
        let anchor = (
          <Link
            key={index}
            href={Util.formatURL(msgToken.content)}
            target="_blank"
          >
            {msgToken.content}
          </Link>
        );
        msgComponents.push(anchor);
      } else {
        const textComponent = (
          <React.Fragment key={index}>{msgToken.content}</React.Fragment>
        );
        msgComponents.push(textComponent);
      }
    } catch (err) {
      logger.log(err);
    }

    index++;
  }
  return msgComponents;
};

export function ChatBubbleUser(
  message,
  isMobile,
  colors,
  isLast,
  
) {
  let msgComponents = convertTokensToComponent(
    message.text,
    message.messageTokens
  );
  if (!msgComponents || msgComponents.length === 0) return null;

  return (
    <StyledBubbleContainerUser
      key={message.id}
      isMobile={isMobile}
      
    >
      <StyledBubbleUser
        colors={colors}
        isLast={isLast}
        
      >
        {msgComponents}
      </StyledBubbleUser>
    </StyledBubbleContainerUser>
  );
}

export function ChatBubbleBot(
  message,
  isMobile,
  colors,
  isLast,
  
) {
  let msgComponents = convertTokensToComponent(
    message.text,
    message.messageTokens
  );
  if (!msgComponents || msgComponents.length === 0) return null;

  return (
    <StyledBubbleContainerBot
      key={message.id}
      isMobile={isMobile}
      
    >
      <StyledBubbleBot
        colors={colors}
        isLast={isLast}
       
      >
        {convertTokensToComponent(message.text, message.messageTokens)}
      </StyledBubbleBot>
    </StyledBubbleContainerBot>
  );
}

const dispatchMessage = (state, dispatch, inputTextAreaRef) => {
  if (!state.trulience.isMediaConnected()) {
    /* confirmAlert({
      title: "Please start a video call",
      message: "To send messages, a call must be in progress",
      buttons: [
        {
          label: "OK",
          onClick: () => window.scrollTo(0, 0),
        },
      ],
    }); */
  } else if (state.inputText && state.inputText.trim().length > 0) {
    dispatch({
      type: sendMessage,
      inputText: state.inputText,
      inputRef: inputTextAreaRef,
    });
    inputTextAreaRef.current.focus();
  }
};
