import * as React from "react";
import Util from "../common/Util";
import { detectDeviceType, isIpad } from "../common/detectDevice";
import useWindowSize from "../hooks/useWindowSize";
import { useResizeObserver } from "../hooks/useResizeObserver";
import { MainContainer } from "./_styles";
import Chat from "./chat/index";
import Video from "./video/index";
import { useBrowserType } from "../hooks/useBrowserType";
import NetworkOverlay from "./video/components/network-overlay";
import { createGlobalStyle } from "styled-components";
import { calculateFontSizeDependingOnSize } from "../common/utilForResponseUI";
import { forceConfig } from "../contexts/SDKContext";

const GlobalStyle = createGlobalStyle`
html {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-size: ${(props) => calculateFontSizeDependingOnSize(16)};
}
`;

export const Main = ({ videoState, chatState }) => {
    const device = detectDeviceType();
    const screenRef = React.useRef(null);
    const { isAndroidMobile } = useBrowserType();
    const { windowSize, setWindowSize } = useWindowSize();

    const [widthScreen, heightScreen] = useResizeObserver(screenRef);

    const isLandscape = React.useMemo(() => {
        return (
            windowSize.width > windowSize.height &&
            !(
                (isAndroidMobile ||
                    videoState.isMobile.tablet ||
                    device === "AndroidTab") &&
                chatState.inputFocussed
            )
        );
    }, [
        windowSize,
        isAndroidMobile,
        chatState.inputFocussed,
        videoState.isMobile.tablet,
        device,
    ]);

    const [videoSize, setVideoSize] = React.useState({
        width: 0,
        height: 0,
    });

    const [chatSize, setChatSize] = React.useState({ width: 0, height: 0 });

    const [videoConstraints, setVideoConstraints] = React.useState(
        Object.values([windowSize.width, windowSize.height - 75])
    );

    const defaultHightScreen = React.useMemo(() => {
        return forceConfig.hideChatInput
            ? windowSize.height
            : Util.findSideRatio(windowSize.width, 16, 16);
    }, [windowSize.width, windowSize.height]);

    const onResize = (event, { size }) => {
        setVideoSize({ width: size.width, height: size.height });
        setChatSize({
            width: windowSize.width,
            height: windowSize.height - videoSize.height,
        });
    };

    const videoSizeDefault = React.useMemo(() => {
        return { width: windowSize.width, height: defaultHightScreen };
    }, [defaultHightScreen, windowSize.width]);

    const chatSizeDefault = React.useMemo(() => {
        return {
            width: windowSize.width,
            height: windowSize.height - videoSizeDefault.height,
        };
    }, [windowSize, videoSizeDefault.height]);

    React.useEffect(() => {
        setVideoConstraints(Object.values(windowSize));
        setVideoSize(videoSizeDefault);
        setChatSize(chatSizeDefault);
    }, [windowSize, videoSizeDefault, chatSizeDefault]);

    const handleResetSize = React.useCallback(() => {
        setVideoConstraints(Object.values(windowSize));
        setVideoSize(videoSizeDefault);
        setChatSize(chatSizeDefault);
    }, [videoSizeDefault, chatSizeDefault, windowSize]);

    React.useEffect(() => {
        if (!videoState.isPlaying) {
            handleResetSize();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoState.isPlaying]);

    const isIpadTable = React.useMemo(() => {
        return isIpad() && detectDeviceType() === "IOS";
    }, []);

    const widthMainScreen = React.useMemo(() => {
        if (videoState.isMobile.any) {
            return Util.findSideRatio(
                heightScreen,
                videoState.mobileScreenAspectRatioProps.widthRatio,
                videoState.mobileScreenAspectRatioProps.heightRatio
            );
        } else if (isIpadTable) {
            return Util.findSideRatio(
                heightScreen,
                videoState.tabletScreenAspectRatioProps.widthRatio,
                videoState.tabletScreenAspectRatioProps.heightRatio
            );
        } else {
            return Util.findSideRatio(
                heightScreen,
                videoState.screenAspectRationProps.widthRatio,
                videoState.screenAspectRationProps.heightRatio
            );
        }
    }, [
        videoState.mobileScreenAspectRatioProps,
        videoState.tabletScreenAspectRatioProps,
        videoState.screenAspectRationProps,
        heightScreen,
        isIpadTable,
        videoState.isMobile,
    ]);

    const heightMainScreen = React.useMemo(() => {
        if (videoState.isMobile.any) {
            return Util.findSideRatio(
                widthScreen,
                videoState.mobileScreenAspectRatioProps.heightRatio,
                videoState.mobileScreenAspectRatioProps.widthRatio
            );
        } else if (isIpadTable) {
            return Util.findSideRatio(
                widthScreen,
                videoState.tabletScreenAspectRatioProps.heightRatio,
                videoState.tabletScreenAspectRatioProps.widthRatio
            );
        } else {
            return Util.findSideRatio(
                widthScreen,
                videoState.screenAspectRationProps.heightRatio,
                videoState.screenAspectRationProps.widthRatio
            );
        }
    }, [
        videoState.mobileScreenAspectRatioProps,
        videoState.tabletScreenAspectRatioProps,
        videoState.screenAspectRationProps,
        widthScreen,
        isIpadTable,
        videoState.isMobile,
    ]);

    const handleCurrentSize = () => {
        try {
            setWindowSize({
                width: document.getElementById("root").clientWidth,
                height: document.getElementById("root").clientHeight,
            });
        } catch (e) {
            console.error(e.message);
        }
    };

    const heightBody = document.getElementsByTagName("body")[0].clientHeight;

    const isChromeIOS = React.useMemo(() => {
        return device === "IOSChrome" || device === "IOSIpadChrome";
    }, [device]);

    React.useEffect(() => {
        if (isChromeIOS) {
            let timer = setTimeout(() => {
                handleCurrentSize();
            }, 200);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLandscape, isChromeIOS, heightBody]);

    const isTablet = device === "IOSIpadChrome" || device === "AndroidTab";

    const isTabletWithOpenKeyboard = React.useMemo(() => {
        return isTablet && chatState.inputFocussed;
    }, [isTablet, chatState.inputFocussed]);

    return (
        <>
            <MainContainer
                id="main-container"
                isLandscape={isLandscape}
                hideChatInput={forceConfig.hideChatInput}
                allButtonsHidden={
                    forceConfig.hideHangUpButton &&
                    forceConfig.hideMicButton &&
                    forceConfig.hideSpeakerButton &&
                    forceConfig.hideFS // full screen
                }
                style={{ fontSize: `calc(100vw / ${windowSize.width} * 10)` }}
            >
                <GlobalStyle />
                <Video
                    onResize={onResize}
                    isIpadTable={isTablet || isIpadTable}
                    videoConstraints={videoConstraints}
                    videoSize={videoSize}
                    widthMainScreen={widthMainScreen}
                    heightMainScreen={heightMainScreen}
                    heightDevice={windowSize.height}
                    screenRef={screenRef}
                    defaultHightScreen={defaultHightScreen}
                    isLandscape={isLandscape}
                    widthMenu={windowSize.width}
                    windowSize={windowSize}
                    isTablet={isTablet}
                    isTabletWithOpenKeyboard={isTabletWithOpenKeyboard}
                    handleResetSize={handleResetSize}
                    setVideoSize={setVideoSize}
                />
                <Chat
                    isTablet={isTablet || isIpadTable}
                    isTabletWithOpenKeyboard={isTabletWithOpenKeyboard}
                    widthMainScreen={widthScreen}
                    chatSize={chatSize}
                    isIpadTable={isIpadTable}
                    isLandscape={isLandscape}
                    windowSize={windowSize}
                    heightMainScreen={heightMainScreen}
                />
                <NetworkOverlay
                    isMobile={videoState.isMobile.any && !isTablet}
                />
            </MainContainer>
        </>
    );
};
