export const handleMessageReceived = "MESSAGE_RECEIVED";
export const clearChatHistoryOnReload = "CLEAR_CHAT_HISTORY_ON_RELOAD";
export const setInputFocus = "SET_INPUT_FOCUS";
export const setInputText = "SET_INPUT_TEXT";
export const sendMessage = "SEND_MESSAGE";
export const setWebSocketStatus = "SET_WEBSOCKET_STATUS";
export const setAuthStatus = "SET_AUTH_STATUS";
export const setOauth = "SET_IS_OAUTH";
export const setTrulience = "SET_TRULIENCE";
export const setFSMode = "SET_FS_MODE";
export const setUIColors = "SET_UI_COLORS";
export const clearInput = "CLEAR_INPUT";
