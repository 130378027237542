import { useLayoutEffect, useState, useCallback } from "react"
import ResizeObserver from "resize-observer-polyfill"

export const useResizeObserver = (
  ref,
  onResize
) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleResize = useCallback(
    (entries) => {
      if (!Array.isArray(entries)) {
        return
      }

      const entry = entries[0]
      setWidth(entry.contentRect.width)
      setHeight(entry.contentRect.height)

      if (onResize) {
        onResize(entry.contentRect)
      }
    },
    [onResize]
  )

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    const RO = new ResizeObserver(entries => handleResize(entries))
    RO.observe(ref.current)

    return () => {
      RO.disconnect()
    }
  }, [handleResize, ref])

  return [width, height]
}