export const usePictureInPicture = () => {

  const videoPiP = document.getElementById("remoteVideo")

  const openExternalUrl = (url) => {
    const targetWindow = window.open(url, "_blank")
    return targetWindow
  }

  const enterPictureInPicture = async () => {
    if (!document.pictureInPictureEnabled) return
    if (!document.pictureInPictureElement) {
      try {
        setTimeout(() => {
          videoPiP
            .requestPictureInPicture()
            .then(() => {})
            .catch(console.error)
        }, 500)
      } catch (err) {
        console.error(err, "no ready")
      }
    }
  }

  const exitPictureInPicture = async () => {
    if (!document.pictureInPictureElement) return
    try {
      await document.exitPictureInPicture()
    } catch (err) {
      console.error("error", err)
    }
  }

  return {
    enterPictureInPicture,
    exitPictureInPicture,
    openExternalUrl,
    videoPiP,
  }
}