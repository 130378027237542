import is_mobile from "ismobilejs";
import { detect } from "detect-browser";
import viewport from "viewport-dimensions";

const mobileQueryObject = is_mobile(navigator.userAgent);
const browser = detect();
mobileQueryObject.viewportRatio = viewport.height() / viewport.width();
mobileQueryObject.browser = browser;

export const initialState = {
  agentId: null,
  oauthAppId: null,
  trulience: null,
  oauth: false,
  oauthPartner: null,
  oauthURL: null,
  authStatus: null,
  webSocketStatus: "DISCONNECTED",
  inputText: "",
  messages: [],
  userName: "Guest",
  userPhoto: "/static/images/userIcon.png",
  inputFocussed: false,
  isMobile: mobileQueryObject,
  fsMode: false,
  avatarPhotoURL: "",
  colors: {
    chatScreenBGColor: "#eee",

    userChatBubbleBGColor: "#FF6200",
    avatarChatBubbleBGColor: "#087FFE",
    userChatBubbleBorderColor: "none",
    avatarChatBubbleBorderColor: "none",
    userChatBubbleTextColor: "white",
    avatarChatBubbleTextColor: "white",
    inputBoxBGColor: "#fff",
    inputBoxBorderColor: "none",
    inputBoxTextColor: "inherit",
    sendButtonBGColor: "white",
    sendButtonArrowColor: "#808080",
    sendButtonBorderColor: "none",

    borderColorBetweenInputAndScreen: "#f0f0f0",

    dialButtonBackground: "#FF6200",
    dialButtonTextColor: "white",
    dialPageBackground: "#000000",
  },
};
