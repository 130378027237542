import * as React from "react";
import * as rdd from "react-device-detect";
import { SDKContext, forceConfig, inIframe } from "../../../../contexts/SDKContext";
import {
    setIsPlaying,
    setMicMuted,
    setSpeakerMuted,
    toggleFSCinemaMod,
    setEnabledSubtitles,
    toggleFullScreen,
    toggleBigPlayButton,
} from "../../_actions";
import {
    toggleMenu,
    toggleHistoryLinksMenu,
    resetMenu,
} from "../../../menu/actions";
import {
    HistoryIcon,
    HistoryIconContainer,
    Dot,
    StyledAnchor,
    StyledIconMobile,
    PulseBox,
} from "../../_styles";
import {
    setFSMode,
    clearInput,
    clearChatHistoryOnReload,
} from "../../../chat/_actions";

export const MainMobileControlsButtons = ({ isLandscape, isMobile }) => {
    const [state, dispatch, , dispatchChatEvent, menuState, dispatchMenuEvent] =
        React.useContext(SDKContext);

    const isDHUnrealEngineBased = () => {
        return window.Trulience?.getInstance().isDHUnrealEngineBased?.()
    }

    const isDHWebglBased = () => {
        return window.Trulience?.getInstance().isDHWebglBased?.()
    }

    const [timeStamp, setTimeStamp] = React.useState({
        start: 0,
        end: 0,
    });

    const handleTouchStart = (e) => {
        setTimeStamp({ ...timeStamp, start: e.timeStamp });
    };

    const handleOnTouchEnd = React.useCallback(
        (e, callback) => {
            if (!state.fullScreen && e.timeStamp - timeStamp.start < 150) {
                callback();
            }
            setTimeStamp({ start: 0, end: 0 });
        },
        [timeStamp.start, state.fullScreen]
    );

    const isInIframeOnIOS = rdd.isIOS && inIframe()
    const isIframeOnIOSWithWebGLAvatar = isInIframeOnIOS && isDHWebglBased()
    const isIframeOnIOSWithUEAvatar = isInIframeOnIOS && isDHUnrealEngineBased()

    // Hide the fullscreen if app is inside an iframe on an iOS device with a WebGL-based avatar or hideFS is enable
    const hideFullscreenIcon = forceConfig.hideFS || isIframeOnIOSWithWebGLAvatar;

    const dispatchFSEvents = (dispatch, dispatchChatEvent) => {
        if(isIframeOnIOSWithUEAvatar) {
            // open video in fullscreen 
            dispatch({ type: toggleFullScreen, target: "video" }); 
            dispatch({ type: toggleBigPlayButton });
        }
        else {
            dispatch({ type: toggleFullScreen }); //video
            dispatchChatEvent({ type: setFSMode }); //chat
        }
    };

    const handleFullScreenOnClick = async () => {
        DeviceOrientationEvent.requestPermission().then((response)=>{
            if (response === 'granted') {
                // logger.log('DeviceOrientation Permission failed');
            }
        }).catch((response)=>{
            // logger.log('DeviceOrientation Permission failed');
        });
        return;
        try{
            const ascene = document.querySelector("a-scene");
            const supportsVR = await navigator.xr.isSessionSupported("immersive-vr");
        if (ascene && supportsVR)
            state.fullScreen
                ? ascene.exitVR() && console.log("leaving VR")
                : ascene.enterVR() && console.log("entering VR");
        else
            dispatchFSEvents(dispatch, dispatchChatEvent);

        } catch(error) {
            // on error [isSessionSupported() is not allowed inside iframe] - open in fullscreen
            dispatchFSEvents(dispatch, dispatchChatEvent);
        }
    }

    return (
        <>
            {!forceConfig.hideHangUpButton && (
                <StyledAnchor
                    icon={
                        <StyledIconMobile
                            {...state}
                            className="material-icons"
                            onTouchStart={handleTouchStart}
                            onTouchEnd={(e) =>
                                handleOnTouchEnd(e, () => {
                                    dispatch({ type: setIsPlaying });
                                    dispatchMenuEvent({ type: resetMenu });
                                    dispatchChatEvent({ type: clearInput });
                                    dispatchChatEvent({
                                        type: clearChatHistoryOnReload,
                                    });
                                })
                            }
                            onClick={() => {
                                dispatch({ type: setIsPlaying });
                                dispatchMenuEvent({ type: resetMenu });
                                dispatchChatEvent({ type: clearInput });
                                dispatchChatEvent({
                                    type: clearChatHistoryOnReload,
                                });
                            }}
                        >
                            {state.isPlaying ? "call_end" : "play_arrow"}
                        </StyledIconMobile>
                    }
                />
            )}
            {!forceConfig.hideMicButton && (
                <div style={{ position: "relative" }}>
                    <StyledAnchor
                        style={{ zIndex: 100 }}
                        icon={
                            <StyledIconMobile
                                {...state}
                                className="material-icons"
                                onTouchStart={handleTouchStart}
                                onTouchEnd={(e) =>
                                    handleOnTouchEnd(e, () =>
                                        dispatch({ type: setMicMuted })
                                    )
                                }
                                onClick={() => 
                                {
                                    DeviceOrientationEvent.requestPermission().then((response)=>{
                                        if (response === 'granted') {
                                            // logger.log('DeviceOrientation Permission failed');
                                        }
                                    }).catch((response)=>{
                                        // logger.log('DeviceOrientation Permission failed');
                                    });
                                    return;    
                                    dispatch({ type: setMicMuted })
                                }
                                }
                            >
                                {state.micMuted ? "mic" : "mic_off"}
                            </StyledIconMobile>
                        }
                    />
                    <PulseBox
                        className={
                            state.isPlaying &&
                            state.micMuted &&
                            !state.isLoading
                                ? "pulse-animation"
                                : ""
                        }
                        isMobile={true}
                    />
                </div>
            )}

            {!forceConfig.hideSpeakerButton && (
                <div style={{ position: "relative" }}>
                    <StyledAnchor
                        style={{ zIndex: 100 }}
                        icon={
                            <StyledIconMobile
                                {...state}
                                className="material-icons"
                                onTouchStart={handleTouchStart}
                                onTouchEnd={(e) =>
                                    handleOnTouchEnd(e, () => {
                                        if (window.trl) {
                                          window.trl.fixAudioContext();
                                        }  
                                        dispatch({ type: setSpeakerMuted })
                                    })
                                }
                                onClick={() => {
                                        if (window.trl) {
                                          window.trl.fixAudioContext();
                                        }  
                                        dispatch({ type: setSpeakerMuted })
                                    } 
                                }
                            >
                                {state.speakerMuted
                                    ? "volume_off"
                                    : "volume_up"}
                            </StyledIconMobile>
                        }
                    />
                </div>
            )}

            {state.fullScreen && !isLandscape && (
                <StyledAnchor
                    icon={
                        <StyledIconMobile
                            {...state}
                            className="material-icons"
                            onTouchStart={handleTouchStart}
                            onTouchEnd={(e) =>
                                handleOnTouchEnd(e, () =>
                                    dispatch({ type: toggleFSCinemaMod })
                                )
                            }
                            onClick={() => {
                                dispatch({ type: toggleFSCinemaMod });
                            }}
                        >
                            {"screenshot"}
                        </StyledIconMobile>
                    }
                />
            )}
            {menuState.historyLinks.length > 0 && !menuState.isIframeMode && (
                <HistoryIconContainer>
                    {menuState.isRedDot &&
                        menuState.historyLinks.length > 0 && <Dot />}
                    <StyledAnchor
                        icon={
                            <HistoryIcon
                                className="material-icons"
                                isMobile={isMobile}
                                onClick={() =>
                                    dispatchMenuEvent({
                                        type: toggleHistoryLinksMenu,
                                    })
                                }
                                onTouchStart={handleTouchStart}
                                onTouchEnd={(e) =>
                                    handleOnTouchEnd(e, () =>
                                        dispatchMenuEvent({
                                            type: toggleHistoryLinksMenu,
                                        })
                                    )
                                }
                            >
                                {"history"}
                            </HistoryIcon>
                        }
                    />
                </HistoryIconContainer>
            )}
            {menuState.icons.length > 0 && !menuState.isIframeMode && (
                <StyledAnchor
                    icon={
                        <StyledIconMobile
                            {...state}
                            className="material-icons"
                            onClick={() =>
                                dispatchMenuEvent({ type: toggleMenu })
                            }
                            onTouchStart={handleTouchStart}
                            onTouchEnd={(e) =>
                                handleOnTouchEnd(e, () =>
                                    dispatchMenuEvent({ type: toggleMenu })
                                )
                            }
                        >
                            {menuState.menuIcon ?? "menu"}
                        </StyledIconMobile>
                    }
                />
            )}
            {forceConfig.showSubtitlesButton && (
                <div style={{ position: "relative" }}>
                    {state.subtitlesEnabled && <Dot colorDot="green" />}
                    <StyledAnchor
                        icon={
                            <StyledIconMobile
                                {...state}
                                className="material-icons-outlined"
                                onTouchStart={handleTouchStart}
                                onTouchEnd={(e) =>
                                    handleOnTouchEnd(e, () =>
                                        dispatch({ type: setEnabledSubtitles })
                                    )
                                }
                                onClick={() =>
                                    dispatch({ type: setEnabledSubtitles })
                                }
                            >
                                {"closed_caption"}
                            </StyledIconMobile>
                        }
                    />
                </div>
            )}
            {!hideFullscreenIcon && (
                <StyledAnchor
                    icon={
                        <StyledIconMobile
                            {...state}
                            className="material-icons"
                            onTouchStart={handleTouchStart}
                            onTouchEnd={(e) =>
                                handleOnTouchEnd(e, () =>
                                    dispatchFSEvents(
                                        dispatch,
                                        dispatchChatEvent
                                    )
                                )
                            }
                            onClick={handleFullScreenOnClick}
                        >
                            {state.fullScreen
                                ? "fullscreen_exit"
                                : "fullscreen"}
                        </StyledIconMobile>
                    }
                />
            )}
        </>
    );
};
