import * as React from "react";
import { SDKContextProvider, SDKContext } from "./contexts/SDKContext";
import { Main } from "./components/Main";

function App() {
  return (
    <SDKContextProvider>
      <SDKContext.Consumer>
        {(props) => {
          return <Main videoState={props[0]} chatState={props[2]} />;
        }}
      </SDKContext.Consumer>
    </SDKContextProvider>
  );
}

export default App;
