import styled from "styled-components";
export const Container = styled.div`
    position: relative;
  `,
  WrapperControls = styled.div`
  display: flex;
  transition: ${(props) => (props.isOpenControls ? "opacity 0.6s" : "opacity 0.2s")};
  opacity: ${(props) => (props.isOpenControls ? "1" : "0")};
  `,
  StyledMoreIcon = styled.i`
  font-size: ${(props) => (!props.isMobile ? '2.56rem' : '2.2rem' )};
  `,
  ContainerControls = styled.div`
  display: flex;
  width: ${(props) => (props.isOpenControls ? `${(props.numberOfVisibleElements + 1) * (props.isMobile ? 2.625 : 3.1875)}rem` : "0rem")};
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  transition: 0.5s;
  border-radius: 1rem;
  padding:  ${(props) => (props.isOpenControls && `0rem 0.5rem`)};
  `;
