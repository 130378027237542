import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConsoleLogger } from './common/logger'
const logger = new ConsoleLogger()

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();

logger.log("process.env.PUBLIC_URL => "+process.env.PUBLIC_URL);
logger.log("process.env.REACT_APP_THIRDPARTY_URL => "+process.env.REACT_APP_THIRDPARTY_URL+'/aframe_master.js?r=j35kk1')
logger.log("process.env.REACT_APP_SDK_URL => "+process.env.REACT_APP_SDK_URL+'/trulience.sdk.js?r=h2s9o4');
logger.log("process.env.NODE_ENV => "+process.env.NODE_ENV);
logger.log("process.env.REACT_APP_STAGE => "+process.env.REACT_APP_STAGE);
