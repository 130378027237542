export const initialState = {
  showMenu: false,
  menuIcon: "menu",
  menuTab: "",
  icons: [],
  mobileIcons: [],
  isRedDot: false,
  isOverlayHistoryLink: false,
  historyLinks: [],
  overlayData: null,
  isConfirmOverlay: false,
  isOpenLinkPiP: false,
  isIframeMode: false,
  currentLinkIframe: "",
  toastMessage: { show: false, message: ""} 
};
