import * as React from "react";
import { SDKContext } from "../../contexts/SDKContext";

export const IframeMode = ({ isMobile }) => {
  const [state, , , , menuState] = React.useContext(SDKContext);
  if (!state.isPlaying) return null;

  return (
    <div
      style={{
        height: menuState.isIframeMode ? "100vh" : "0px",
        position: isMobile && "relative",
        zIndex: isMobile && -7,
      }}
    >
      <iframe
        id="id-iframe"
        title="page"
        src={menuState.currentLinkIframe}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  );
};
