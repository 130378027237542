import is_mobile from "ismobilejs";
import { detect } from "detect-browser";
import viewport from "viewport-dimensions";

const mobileQueryObject = is_mobile(navigator.userAgent);
const browser = detect();
mobileQueryObject.viewportRatio = viewport.height() / viewport.width();
mobileQueryObject.browser = browser;

export const initialState = {
  background: "",
  fullScreen: false,
  isPlaying: false,
  micMuted: false,
  speakerMuted: false,
  isFSCinemaMod: false,
  showSubtitlesButton: false,
  subtitlesEnabled: false, 
  closedCaptions: false,
  showSettings: "",
  trulience: null,
  isLoading: false,
  loadProgress: 0,
  showBigPlayButton: false,
  statusMessage: "",
  statusMessageLink: "",
  displayControls: true,
  isMobile: mobileQueryObject,
  avatarPhotoURL: "",
  webRtcNOTSupported: false,
  invalidAvatar: false,
  isDragResizeOverlay: true,
  screenAspectRationProps: {
    enabled: false,
    heightRatio: 9,
    widthRatio: 16,
  },
  mobileScreenAspectRatioProps: {
    enabled: false,
    heightRatio: 9,
    widthRatio: 16,
  },
  tabletScreenAspectRatioProps: {
    enabled: false,
    heightRatio: 9,
    widthRatio: 16,
  },
  logoProps: {
    showLogo: false,
    logoPosition: "left",
    logoSrc: "",
  },
};
