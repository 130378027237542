import is_mobile from "ismobilejs";
import { detect } from "detect-browser";
import viewport from "viewport-dimensions";
import { ConsoleLogger } from "../common/logger";

const mobileQueryObject = is_mobile(navigator.userAgent);
const browser = detect();
mobileQueryObject.viewportRatio = viewport.height() / viewport.width();
mobileQueryObject.browser = browser;
const logger = new ConsoleLogger();

export default class Util {
  static addFullScreenListeners(exitHandler) {
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
    document.addEventListener("webkitfullscreenchange", exitHandler, false);
  }

  static removeFullScreenListeners(exitHandler) {
    document.removeEventListener("fullscreenchange", exitHandler, false);
    document.removeEventListener("mozfullscreenchange", exitHandler, false);
    document.removeEventListener("MSFullscreenChange", exitHandler, false);
    document.removeEventListener("webkitfullscreenchange", exitHandler, false);
  }

  static parseImage(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return "";
    try {
      return messageTokens
        .filter(
          (tag) =>
            tag.type === "XML" &&
            tag.name === "trl-content" &&
            tag.attributes.screen
        )
        .map((tag) =>
          tag.attributes.screen.replace(
            "https://trulience.com/public_html/object.jsp?pic=",
            ""
          )
        )
        .reduce((acc, imageURL) => decodeURIComponent(imageURL), "");
    } catch (err) {
      logger.log(err);
    }
    return "";
  }

  static linksHistoryMenu(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return null;
    try {
      let result = {};

      let attributes = messageTokens.filter(
        (tag) =>
          tag.type === "XML" &&
          tag.name === "trl-content" &&
          (tag.attributes?.showHistory ||
            tag.attributes?.historyBg ||
            tag.attributes?.historyDisplay ||
            tag.attributes?.mobHistoryBg ||
            tag.attributes?.mobHistoryDisplay)
      );

      if (attributes === null || attributes === undefined || attributes.length === 0) {
        return null;
      } else {
        attributes = attributes[0].attributes;
      }

      if (attributes?.showHistory === "true") {
        let data = {
          showMenu: true,
          menuTab: "history-links",
          historyDisplay: attributes?.historyDisplay
            ? this.convertIntoStylesObject(
                attributes?.historyDisplay,
                "###",
                "_"
              )
            : { styles: {} },
          historyBg: attributes?.historyBg,
          mobHistoryBg: attributes?.mobHistoryBg,
          mobHistoryDisplay: attributes?.mobHistoryDisplay,
        };
        result = data;
      } else {
        let data = {
          historyDisplay: attributes?.historyDisplay
            ? this.convertIntoStylesObject(
                attributes?.historyDisplay,
                "###",
                "_"
              )
            : { styles: {} },
          historyBg: attributes?.historyBg,
          mobHistoryBg: attributes?.mobHistoryBg,
          mobHistoryDisplay: attributes?.mobHistoryDisplay
            ? this.convertIntoStylesObject(
                attributes?.mobHistoryDisplay,
                "###",
                "_"
              )
            : { styles: {} },
        };
        result = data;
      }
      return result;
    } catch (err) {
      logger.log(err);
    }
  }

  static parseHistoryLink(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return null;
    try {
      let object = messageTokens.filter(
        (tag) =>
          tag.type === "XML" && tag.name === "trl-link" && tag.attributes.url
      );
      if (object === null || object === undefined || object.length === 0) {
        return null;
      } else {
        object = object[0].attributes;
      }

      const display = this.convertIntoStylesObject(object.display, "###", "_");

      const icon = object.icon
        ? this.convertIntoStylesObject(object.icon, "###", "_", true)
        : null;

      const box = object.box
        ? this.convertIntoStylesObject(object.box, "###", "_")
        : null;

      return [
        {
          url: this.decodingUnreadableCharactersUrl(object?.url),
          display: display,
          title: display.name,
          box: box,
          icon: {
            ...icon,
            figure:
              icon?.styles?.figure !== undefined
                ? icon?.styles?.figure
                : "circle",
          },
          target: object.target,
          open: object.open !== undefined ? object.open : "",
        },
      ];
    } catch (err) {
      logger.log(err);
    }
  }

  static parsePhrase(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return [];
    try {
      const lengthArray = messageTokens.filter(
        (tag) =>
          tag.type === "XML" &&
          tag.name === "trl-link" &&
          tag.attributes.isPhrase
      ).length;
      return lengthArray > 0 ? true : false;
    } catch (err) {
      logger.log(err);
    }
  }

  static parseLogo(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return null;

    const array = messageTokens.filter(
      (tag) =>
        tag.type === "XML" &&
        tag.name === "trl-content" &&
        tag.attributes.showLogo
    );

    return array.length === 0 ? null : array[0]?.attributes;
  }

  static convertIntoStylesObject(
    string,
    mainSeparator,
    additionalSeparator,
    isConvertName
  ) {
    return string.split(mainSeparator).reduce((acc, item) => {
      const array = item.split(additionalSeparator);
      const key = `${array[0]}`;
      const value = `${array[1]}`;
      if (key === "name") {
        if (isConvertName) {
          const iconName = this.convertIconName(value);
          acc[key] = iconName;
        } else {
          const name = value;
          acc[key] = name;
        }
      } else {
        acc[`styles`] = { ...acc[`styles`], [`${key}`]: value };
      }
      return acc;
    }, {});
  }

  static async canAccessIFrame(id) {
    const element = await this.waitForElm(id);

    return element.contentWindow.length > 0 ? true : false;
  }

  static waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.getElementById(selector)) {
        return resolve(document.getElementById(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.getElementById(selector)) {
          resolve(document.getElementById(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  static parseLinkMenu(link, separator, additionalSeparator) {
    return link.split(separator).reduce((acc, item) => {
      const array = item.split(additionalSeparator);
      const key = `${array[0]}`;
      const value = `${array[1]}`;

      acc[key] = value;

      return acc;
    }, {});
  }

  static convertIconName(string) {
    const nameConversion = string
      .split("")
      .map((el, index) => {
        if (el.toUpperCase() === el && index !== 0) {
          return `_${el.toLowerCase()}`;
        } else if (index === 0) {
          return el.toLowerCase();
        } else {
          return el;
        }
      })
      .join("");
    return nameConversion.endsWith("_icon")
      ? nameConversion.slice(0, -5)
      : nameConversion;
  }

  static converAspectRatio(string) {
    const ratios = string.split(":");
    return {
      heightRatio: +ratios[1],
      widthRatio: +ratios[0],
    };
  }

  static parseScreenAspectRatio(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return null;
    const array = messageTokens.filter(
      (tag) =>
        tag.type === "XML" &&
        tag.name === "trl-content" &&
        tag.attributes.screenAspectRatio
    );

    if (array.length === 0) {
      return null;
    } else {
      const ratios = this.converAspectRatio(
        array[0].attributes.screenAspectRatio
      );

      return {
        enabled: true,
        heightRatio: ratios.heightRatio,
        widthRatio: ratios.widthRatio,
      };
    }
  }

  static parseMobileScreenAspectRatio(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return null;
    const array = messageTokens.filter(
      (tag) =>
        tag.type === "XML" &&
        tag.name === "trl-content" &&
        tag.attributes.mobileScreenAspectRatio
    );

    if (array.length === 0) {
      return null;
    } else {
      const ratios = this.converAspectRatio(
        array[0].attributes.mobileScreenAspectRatio
      );

      return {
        enabled: true,
        heightRatio: ratios.heightRatio,
        widthRatio: ratios.widthRatio,
      };
    }
  }

  static parseTabletScreenAspectRatio(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return null;
    const array = messageTokens.filter(
      (tag) =>
        tag.type === "XML" &&
        tag.name === "trl-content" &&
        tag.attributes.tabletScreenAspectRatio
    );

    if (array.length === 0) {
      return null;
    } else {
      const ratios = this.converAspectRatio(
        array[0].attributes.tabletScreenAspectRatio
      );

      return {
        enabled: true,
        heightRatio: ratios.heightRatio,
        widthRatio: ratios.widthRatio,
      };
    }
  }

  static parseMenu(messageTokens) {
    if (!messageTokens || messageTokens.length === 0) return null;

    try {
      let obj = messageTokens.filter(
        (tag) =>
          tag.type === "XML" &&
          tag.name === "trl-content" &&
          tag.attributes.showMenu
      );

      if (obj === null || obj === undefined || obj.length === 0) {
        return null;
      } else {
        obj = obj[0].attributes;
      }

      const numberOfDesktopIcons = Object.keys(obj).filter((el) =>
        el.startsWith("display")
      ).length;

      const numberOfMobileIcons = Object.keys(obj).filter((el) =>
        el.startsWith("mobDisplay")
      ).length;

      const emptyDesktopArray = new Array(numberOfDesktopIcons).fill({});
      const emtyMobileArray = new Array(numberOfMobileIcons).fill({});

      const desktopIcons = emptyDesktopArray.map((el, index) => {
        let value = {};

        if (obj[`display${index + 1}`] !== undefined) {
          value.id = index + 1;
        }

        if (obj[`display${index + 1}`] !== undefined) {
          value.display = this.convertIntoStylesObject(
            obj[`display${index + 1}`],
            "###",
            "_"
          );
        }

        if (obj[`link${index + 1}`] !== undefined) {
          const parselinkProps = this.parseLinkMenu(
            obj[`link${index + 1}`],
            "###",
            "_"
          );
          const link = {
            ...parselinkProps,
            link: this.decodingUnreadableCharactersUrl(parselinkProps?.link),
          };
          value.link =
            /* this.decodingUnreadableCharactersUrl( */ link; /* ); */
        }

        if (obj[`icon${index + 1}`] !== undefined) {
          const styles = this.convertIntoStylesObject(
            obj[`icon${index + 1}`],
            "###",
            "_",
            true
          );
          value.icon = styles;
        }

        return value;
      });

      const mobileIcons = emtyMobileArray.map((el, index) => {
        let value = {};

        if (obj[`mobDisplay${index + 1}`] !== undefined) {
          value.id = index + 1;
        }

        if (obj[`display${index + 1}`] !== undefined) {
          value.display = this.convertIntoStylesObject(
            obj[`mobDisplay${index + 1}`],
            "###",
            "_"
          );
        }

        if (obj[`mobLink${index + 1}`] !== undefined) {
          const parselinkProps = this.parseLinkMenu(
            obj[`mobLink${index + 1}`],
            "###",
            "_"
          );

          const link = {
            ...parselinkProps,
            link: this.decodingUnreadableCharactersUrl(parselinkProps?.link),
          };
          value.link = link;
        }

        if (obj[`mobIcon${index + 1}`] !== undefined) {
          const styles = this.convertIntoStylesObject(
            obj[`mobIcon${index + 1}`],
            "###",
            "_",
            true
          );
          value.icon = styles;
        }
        return value;
      });

      const allMobileIcons = desktopIcons.map((el) => {
        if (mobileIcons.some((mobileIcon) => mobileIcon.id === el.id)) {
          const desktopIcon = { ...el };
          desktopIcon.display = {
            name:
              mobileIcons[desktopIcon.id - 1].display.name ??
              desktopIcon.display.name,
            styles: mobileIcons[desktopIcon.id - 1].display.styles,
          };

          desktopIcon.link = {
            ...desktopIcon.link,
            ...mobileIcons[desktopIcon.id - 1].link,
          };

          desktopIcon.icon = {
            name:
              mobileIcons[desktopIcon.id - 1].icon?.name ??
              desktopIcon.icon?.name,
            styles: mobileIcons[desktopIcon.id - 1].icon?.styles,
          };

          return desktopIcon;
        }

        return el;
      });

      return {
        showMenu: obj.showMenu === "true" ? true : false,
        menuIcon: obj.menuIcon,
        menuBg: obj.menuBg,
        icons: desktopIcons,
        mobileIcons: allMobileIcons,
        mobMenuIcon: obj.mobMenuIcon,
        mobMenuBg: obj.mobMenuBg,
      };
    } catch (err) {
      logger.log(err);
    }
  }

  static generateUniqueId() {
    return (
      "_" +
      Math.random().toString(36).substr(2, 9) +
      Math.random().toString(36).substr(2, 9) +
      Math.random().toString(36).substr(2, 9)
    );
  }

  static isNotSafariOn_iOS() {
    if (
      mobileQueryObject &&
      mobileQueryObject.apple.device &&
      mobileQueryObject.browser.name.toLowerCase().trim() !== "ios"
    ) {
      logger.warn(mobileQueryObject);
      logger.warn(mobileQueryObject.apple.device);
      logger.warn(mobileQueryObject.browser.name);
      return true;
    }
    return false;
  }

  static copyURL() {
    var el = document.getElementById("linkinput");
    var editable = el.contentEditable;
    var readOnly = el.readOnly;
    el.contentEditable = "true";
    el.readOnly = "false";
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    el.setSelectionRange(0, 999999);
    el.contentEditable = editable;
    el.readOnly = readOnly;
    document.execCommand("copy");
    var butt = document.getElementById("linkbutton");
    butt.readOnly = "false";
    butt.contentEditable = "true";
    butt.innerHTML = "Copied";
  }

  static stripTRLTags(str) {
    if (str) {
      return str.replace(/<[^>]*>/g, "").trim();
    }
    return "";
  }

  static is_url(str) {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  static formatURL(s) {
    if (!s.startsWith("http")) {
      return "http://" + s;
    }
    return s;
  }

  static findSideRatio(side, firstRatio, secondRatio) {
    return (side * firstRatio) / secondRatio;
  }

  static parseFieldName(text) {
    return text.split("###")[0].slice(5);
  }

  static replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  static checkForDuplicateLinks(arrayLinks, link) {
    let flag = true;
    if (arrayLinks.length === 0) {
      return true;
    }
    arrayLinks.forEach((linkItem) => {
      if (linkItem.title === link.title) {
        flag = false;
      }
    });
    return flag;
  }

  static replaceUnreadableCharactersUrl(text) {
    if (!text) return;
    return text
      .split(" ")
      .map((item) => {
        if (item.startsWith("url=")) {
          const replaceUrl = item.substring(4);
          const ampersand = this.replaceAll(replaceUrl, "&", "[ampersand]");
          const questionMark = this.replaceAll(
            ampersand,
            "?",
            "[questionMark]"
          );
          const dash = this.replaceAll(questionMark, "-", "[dash]");
          const underDash = this.replaceAll(dash, "_", "[underDash]");
          return "url=" + underDash;
        }
        return item;
      })
      .join(" ");
  }

  static decodingUnreadableCharactersUrl(text) {
    if (!text) return;
    const ampersand = this.replaceAll(text, "[ampersand]", "&");
    const questionMark = this.replaceAll(ampersand, "[questionMark]", "?");
    const dash = this.replaceAll(questionMark, "[dash]", "-");
    const underDash = this.replaceAll(dash, "[underDash]", "_");
    return underDash;
  }

  static isAutoplayEnabled() {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.src = 'data:audio/mp3;base64,//NExAAAAAAAAAAAAAAA=='; // Silent audio
      audio.autoplay = true;
  
      console.log("Before audio play 1");
      const playPromise = audio.play();
  
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Autoplay is allowed
          audio.pause(); // Pause the audio
          resolve(true);
        }).catch(error => {
          // Autoplay is likely disabled
          resolve(false);
        });
      } else {
        // Unable to determine autoplay status
        resolve(false);
      }
    });
  }

}
