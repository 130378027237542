import React from "react";

import BigPlayButton from "../big-play-button";
import { PlayerMobile } from "./player-mobile";
import { StyledResizableBox } from "../../_styles";

const MobileResizablePlayer = ({
  state,
  menuState,
  isAspectRatio,
  isLandscape,
  onResize,
  disableDragging,
  widthResizeBox,
  heightResizableBox,
  defaultHightScreen,
  videoConstraints,
  widthMainScreen,
  videoSize,
  windowSize,
  chatState,
  dispatch,
  dispatchChatEvent,
  dispatchMenuEvent,
  screenRef,
  handleOnClick,
  setVideoSize,
}) => {
  const prevVideoHeightRef = React.useRef(0);
  const [isDraggable, setIsDraggable] = React.useState(true);

  React.useEffect(() => {
    let pendingUpdate = false;

    function viewportHandler(event) {
      if (pendingUpdate) return;
      pendingUpdate = true;

      requestAnimationFrame(() => {
        pendingUpdate = false;
        const mainWindowContainer = document.getElementById("main-container");

        // visual viewport's offset from the layout viewport origin.
        const viewport = event.target;
        // if offsetTop is not a 0, then keyboard is open
        const isKeyboardOpen = !!viewport.offsetTop;

        // Set main window draggable false when keyboard open and vice verse
        setIsDraggable(!isKeyboardOpen);

        // Get offset height
        const offsetTop = viewport.offsetTop;

        // setting top padding to make main video visible
        mainWindowContainer.style.transform = `translate(${0}px, ${offsetTop}px) scale(${
          1 / viewport.scale
        })`;
        const messageInputBoxHeight = 62; //
        const newVideoHeight = viewport.height - messageInputBoxHeight;

        setVideoSize((prev) => {
          // Set the video height before keyboard opens
          if (prevVideoHeightRef.current < prev.height) {
            prevVideoHeightRef.current = prev.height;
          }

          // case 1 - when keyboard is closed - apply prev video height
          if (!isKeyboardOpen) {
            const height = prevVideoHeightRef.current;
            prevVideoHeightRef.current = 0;
            return { ...prev, height: height };
          }

          // case 2 - if video height is less then viewport height - don't change video height
          if (prev.height < newVideoHeight) {
            return prev;
          }

          // case 3 - if video height is greater viewport height - set available Height
          return { ...prev, height: newVideoHeight };
        });
      });
    }

    window.visualViewport.addEventListener("resize", viewportHandler);
    window.visualViewport.addEventListener("scroll", viewportHandler);

    return () => {
      window.visualViewport.removeEventListener("resize", viewportHandler);
      window.visualViewport.removeEventListener("scroll", viewportHandler);
    };
  }, []);

  return (
    <StyledResizableBox
      isFullscreen={state.fullScreen}
      overflow="hidden"
      axis={isDraggable ? "y" : "none"}
      isiframemode={`${menuState.isIframeMode}`}
      isaspectratio={`${isAspectRatio && isLandscape && state.isPlaying}`}
      onResize={onResize}
      width={disableDragging ? null : widthResizeBox}
      height={disableDragging ? null : heightResizableBox}
      minConstraints={[388, defaultHightScreen]}
      maxConstraints={videoConstraints}
      handle={
        <div
          id="player-mobile-container"
          style={{
            width:
              isAspectRatio &&
              !menuState.isIframeMode &&
              isLandscape &&
              state.isPlaying
                ? widthMainScreen
                : menuState.isIframeMode
                ? 270
                : videoSize.width + "px",
            height: !state.isPlaying
              ? "0px"
              : state.fullScreen
              ? "100%"
              : disableDragging
              ? "100vh"
              : isLandscape
              ? windowSize.height
              : menuState.isIframeMode
              ? 140
              : videoSize.height + "px",
            backgroundColor: chatState.colors.dialPageBackground,
          }}
        >
          <BigPlayButton />

          <PlayerMobile
            state={state}
            dispatch={dispatch}
            dispatchChatEvent={dispatchChatEvent}
            chatState={chatState}
            menuState={menuState}
            dispatchMenuEvent={dispatchMenuEvent}
            screenRef={screenRef}
            isLandscape={isLandscape}
            isAspectRatio={isAspectRatio}
            widthMainScreen={widthMainScreen}
          />
          <div id="webgl_container" className="parent_size"></div>
        </div>
      }
      handleSize={[windowSize.width, windowSize.height]}
      resizeHandles={["s"]}
      preventdefaulttouchmoveevent="true"
      cursor={"ns-resize"}
      onClick={handleOnClick}
      draggableOpts={{
        allowAnyClick: true,
        enableUserSelectHack: true,
        disabled: state.fullScreen || disableDragging,
      }}
    />
  );
};

export default MobileResizablePlayer;
