import { Anchor, Box, Button } from "grommet";
import styled from "styled-components";
import { forceConfig } from "../../contexts/SDKContext";
import * as rdd from "react-device-detect";
import { ResizableBox } from "react-resizable";
import Util from "../../common/Util";
import { detectDeviceType } from "../../common/detectDevice";

const device = detectDeviceType();

export const StyledPlayerWeb = styled.video`
    position: ${(props) => (props.isIframeMode ? "fixed" : "relative")};
    bottom: ${(props) => props.isIframeMode && "1rem"};
    right: ${(props) => props.isIframeMode && "1rem"};
    overflow: hidden;
    width: ${(props) =>
      props.isScreenAspectRation && !props.isIframeMode
        ? "100%"
        : props.isIframeMode
        ? "16.875rem"
        : "100vw"};
    height: ${(props) =>
      props.isIpadTable && !props.isLandscape && props.fullScreen
        ? "100vh"
        : props.isScreenAspectRation && !props.isLandscape
        ? `${props.heightMainScreen}px`
        : props.isIframeMode && "8.75"};
    max-height: ${(props) => 
      props.fullScreen ? "100vh" : "calc(100vh - 3.875rem)"
    };
    margin-bottom: 0px;
    margin-left: ${(props) =>
      props.isOpenMenu && !props.isIframeMode ? "0px" : "0px"};
    background-color: ${(props) =>
      Util.is_url(props.bgColor) ? "unset" : props.bgColor};
    object-fit: ${(props) =>
      props.fullScreen ? "cover" : forceConfig.videoObjectFit};
    @media screen and (orientation: portrait) {
      height: ${(props) =>
        props.isIpadTable && !props.isLandscape && props.fullScreen
          ? "100vh"
          : props.hideChatHistory
          ? `calc(100vh - 3.875rem)`
          : props.isIframeMode
          ? "8.75rem"
          : device === "IOSIpadChrome"
          ? `${props.heightMainScreen}px`
          : "48vh"};
      max-height: ${(props) =>
        props.isIpadTable && !props.isLandscape && props.fullScreen
          ? "100vh"
          : !props.hideChatHistory &&
            (device === "IOSIpadChrome"
              ? `${props.heightMainScreen}px`
              : "48vh")};
    }
    @media screen and (orientation: landscape) {
      height: ${(props) =>
        props.isTabletWithOpenKeyboard
          ? "48vh"
          : !props.isPlaying
          ? "0px"
          : props.isIframeMode
          ? "8.75rem"
          : props.fullScreen
          ? `100vh`
          : "100%"};
      width: ${(props) =>
        props.isScreenAspectRation && !props.isIframeMode
          ? "100%"
          : props.isIframeMode
          ? "16.875rem"
          : "100vw"};
    }
  `,
  StyledResizableBox = styled(ResizableBox)`
    position: ${(props) =>
      props.isiframemode === "true" ? "absolute" : "relative"};
    bottom: ${(props) => props.isiframemode === "true" && "10px"};
    right: ${(props) => props.isiframemode === "true" && "10px"};
    margin: ${(props) => props.isaspectratio === "true" && "0 auto"};
    max-height: 100vh;
    background-color: #383838;
    .custom-handle {
      position: absolute;
      background-color: #383838;
      opacity: 0.95;
      z-index: 1200;
      transition: all 0.1s ease;

      &:hover {
        background-color: #dd3c66;
        opacity: 1;
        z-index: 1200;
      }
    }

    .custom-handle-sw {
      bottom: -4px;
      left: -4px;
      cursor: sw-resize;
    }
    .custom-handle-se {
      bottom: -4px;
      right: -4px;
      cursor: se-resize;
    }
    .custom-handle-nw {
      top: -4px;
      left: -4px;
      cursor: nw-resize;
    }
    .custom-handle-ne {
      top: -4px;
      right: -4px;
      cursor: ne-resize;
    }
    .custom-handle-w,
    .custom-handle-e {
      top: 0;
      width: 8px;
      height: 100vh;
      cursor: ew-resize;
    }
    .custom-handle-w {
      left: -4px;
    }
    .custom-handle-e {
      right: -4px;
    }
    .custom-handle-n,
    .custom-handle-s {
      left: 50%;
      cursor: ns-resize;
    }
    .custom-handle-n {
      top: -4px;
    }
    .custom-handle-s {
      height: 8px;
      width: 100vw;
      bottom: 0;
      width: 100vw;
      left: 0;
      right: 0;
    }
  `,
  StyledAnchor = styled(Anchor)`
    margin: 0;
    padding: ${(props) => `0px 0.3rem`};
    position: relative;
    color: #fcfafc;
  `,
  StyledAnchorDial = styled(Anchor)`
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1.5vh;
    bottom: 1.5vh;
    background-color: ${(props) => props.colors.dialButtonBackground};
    color: ${(props) => props.colors.dialButtonTextColor};
    width: 7vh;
    height: 7vh;
    right: 4.5vh;
    bottom: 4.5vh;
    @media screen and (orientation: portrait) {
      width: 6vh;
      height: 6vh;
      right: 1.5vh;
      bottom: 1.5vh;
    }
    border-radius: 50%;
    z-index: 1000;
  `,
  StyledIconVideoCallWeb = styled.i`
    @media screen and (orientation: landscape) {
      font-size: 6vh;
      margin-top: 0.6vh;
      margin-left: 0.6vh;
    }
    @media screen and (orientation: portrait) {
      font-size: 5vh;
      margin-top: 0.6vh;
      margin-left: 0.6vh;
    }
  `,
  DialButtonWeb = styled(Button)`
    cursor: pointer;
    border-radius: 0.3125rem;
    z-index: 1000;
    padding: 0.625rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem;
    background-color: ${(props) => props.colors.dialButtonBackground};
    color: ${(props) => props.colors.dialButtonTextColor};
    margin-top: 1.5rem;
    align: middle;
    text-align: center;
    @media screen and (orientation: portrait) {
      top: 50vh;
      left: calc(50vw - (132px) / 2);
    }
    @media screen and (orientation: landscape) {
    }
  `,
  StyledPlayerControlsWeb = styled(Box)`
    position: ${(props) => (props.isIframeMode ? "fixed" : "absolute")};
    width: ${(props) => props.isIframeMode && "fit-content"};
    right: ${(props) => props.isIframeMode && "0px"};
    padding: 1.5rem;
    bottom: 0;
  `,
  PhotoBoxWeb = styled(Box)`
    position: relative;
    margin: 0px;
    padding: 0px;

    @media screen and (orientation: landscape) {
      /* top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
    }
  `,
  AvatarPhotoWeb = styled.div`
    background-image: url(${(props) => props.avatarPhotoURL});
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
    @media screen and (orientation: portrait) {
      width: 30vh;
      height: 30vh;
    }
    @media screen and (orientation: landscape) {
      width: 52vh;
      height: 52vh;
    }
  `,
  StyledPlayerMobile = styled.video`
    position: relative;
    overflow: hidden;
    background-color: ${(props) =>
      Util.is_url(props.bgColor) ? "unset" : props.bgColor};

    display: ${(props) => (!props.isPlaying ? "content" : "flex")};
    z-index: ${(props) => props.isIframeMode && 10000};
    object-fit: ${(props) =>
      props.fullScreen ? "cover" : forceConfig.videoObjectFit};
    @media screen and (orientation: portrait) {
      height: ${(props) =>
        props.fullScreen && !props.isFSCinemaMod
          ? "100vh"
          : props.fullScreen && !props.isFSCinemaMod
          ? "60vw"
          : "100%"};
      width: ${(props) => (props.isIframeMode ? "100%" : "100vw")};
      z-index: ${(props) => props.isIframeMode && 10000};
      top: ${(props) => props.isFSCinemaMod && !props.isLandscape && "45%"};
      left: ${(props) => props.isFSCinemaMod && !props.isLandscape && "50%"};
      transform: ${(props) =>
        props.isFSCinemaMod && !props.isLandscape && "translate(-50%, -50%)"};
    }

    @media screen and (orientation: landscape) {
      height: ${(props) =>
        props.fullScreen ? "100vh" : props.isMobile.tablet ? "100%" : "100%"};
      width: ${(props) => (props.isAspectRatio ? "100%" : "100vw")};
    }
  `,
  PhotoBoxMobile = styled(Box)`
    position: absolute;
    margin: 0px;
    padding: 0px;

    @media screen and (orientation: portrait) {
      top: calc((60vh - (60vw * 3 / 4)) / 2);
      left: calc(50vw - (60vw * 3 / 4) / 2);
    }

    @media screen and (orientation: landscape) {
      top: ${(props) =>
        props.fullScreen
          ? "calc((100vh - 52vh)/2)"
          : props.isMobile.tablet
          ? "calc((100vh - 52vh -  165px)/2)"
          : "calc((100vh - 60vh -  65px)/2)"};
      left: calc((100vw - 52vh) / 2);
    }
  `,
  AvatarMobile = styled.div`
    background-image: url(${(props) => props.avatarPhotoURL});
    background-size: cover;
    background-position: center;
    border-radius: 190px;
    -webkit-border-radius: 190px;
    -moz-border-radius: 190px;
    cursor: pointer;

    @media screen and (orientation: portrait) {
      width: calc((60vw) * 3 / 4);
      height: calc((60vw) * 3 / 4);
    }

    @media screen and (orientation: landscape) {
      width: 52vh;
      height: 52vh;
    }
  `,
  StyledPlayerControlsMobile = styled(Box)`
    position: absolute;
    display: ${(props) => (!props.isPlaying ? "none" : "flex")};

    @media only screen and (max-width: 926px) {
      padding: 4px 4px;
    }

    @media screen and (orientation: portrait) {
      bottom: ${(props) => /* !props.fullScreen && */ "0px"};
    }
    @media screen and (orientation: landscape) {
      top: calc(
        ${(props) =>
            props.isLandscape
              ? "unset"
              : props.fullScreen
              ? rdd.isIOS
                ? rdd.isSafari
                  ? "103vh"
                  : "97vh"
                : rdd.isAndroid
                ? "105vh"
                : "100vh"
              : "90vh"} -
          ${(props) => (props.isMobile.tablet ? "unset" : "unset")}
      );
      bottom: ${(props) => /* props.isLandscape &&  */ "0px"};
    }
  `,
  StyledAnchorDialMobile = styled(Anchor)`
    margin: 0;
    padding: 0 0.3rem;
    position: absolute;

    @media screen and (orientation: portrait) {
      right: ${(props) => (props.isMobile.tablet ? "3vw" : "1vw")};
      bottom: ${(props) => (props.isMobile.tablet ? "3vw" : "1vw")};
    }
    @media screen and (orientation: landscape) {
      right: ${(props) => (props.isMobile.tablet ? "1.5vw" : "1vw")};
      bottom: ${(props) => (props.isMobile.tablet ? "1.5vw" : "1vw")};
    }
    background-color: ${(props) => props.colors.dialButtonBackground};
    color: ${(props) => props.colors.dialButtonTextColor};

    width: ${(props) =>
      props.isMobile.tablet ? "max(60px ,6vw)" : "max(40px ,6vw)"};
    height: ${(props) =>
      props.isMobile.tablet ? "max(60px ,6vw)" : "max(40px ,6vw)"};
    border-radius: 80px;
    z-index: 1000;
  `,
  StyledIconVideoCallMobile = styled.i`
    // samsung internet doesn't like max or calc here
    font-size: ${(props) => (props.isMobile.tablet ? "50px" : "34px")};
    margin-top: 1vw;
    margin-left: 0px;

    @media screen and (orientation: portrait) {
      font-size: ${(props) => (props.isMobile.tablet ? "54px" : "34px")};
      margin-top: ${(props) => (props.isMobile.tablet ? "3px" : "3px")};
      margin-left: ${(props) => (props.isMobile.tablet ? "-1px" : "-1px")};
    }
    @media screen and (orientation: landscape) {
      font-size: ${(props) => (props.isMobile.tablet ? "54px" : "34px")};
      margin-top: ${(props) => (props.isMobile.tablet ? "8px" : "4px")};
      margin-left: ${(props) => (props.isMobile.tablet ? "3px" : "-1px")};
    }
  `,
  DialButtonMobile = styled(Button)`
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    font-size: ${(props) => (props.isMobile.any ? "20px" : "15px")};
    background-color: ${(props) => props.colors.dialButtonBackground};
    color: ${(props) => props.colors.dialButtonTextColor};
    position: absolute;
    min-width: 140px;
    width: max-content
    max-width: 240px;

    text-align: center;

    left: 50vw;
    transform: translate(-50%);
    
    @media screen and (orientation: portrait) {
      top: 50vh;
    }

    @media screen and (orientation: landscape) {
      top: 70vh;
    }
  `,
  StyledIconPhoneDown = styled.i`
    font-size: 2.6rem;
  `,
  StyledIconWeb = styled.i`
    font-size: 2.6rem;
  `,
  StyledIconMobile = styled.i`
    font-size: ${(props) => (props.isMobile.tablet ? "3.0rem" : "2.2rem")};
    margin-right: ${(props) => (props.isMobile.tablet ? "6px" : "0px")};
  `,
  LoaderCSSWeb = styled.div`
    position: absolute;
    @media screen and (orientation: portrait) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (orientation: landscape) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    z-index: 99;
  `,
  Link = styled(Anchor)`
    color: white;
    text-decoration: underline;
    text-decoration-color: white;
    text-decoration-thickness: 0.125em;
    text-underline-offset: 1.5px;
  `,
  StatusMessage = styled.div`
    position: absolute;
    z-index: 99;
    white-space: pre-wrap;
    color: white;
    font-size: ${(props) => (props.isMobile.tablet ? "1.8rem" : "1.5rem")};

    @media screen and (orientation: portrait) {
      padding: ${(props) => (props.fullScreen ? "10vh" : "7vh")};
    }

    @media screen and (orientation: landscape) {
      padding: 7vw;
    }
  `,
  HistoryIconContainer = styled.div`
    position: relative;
  `,
  Dot = styled.div`
    width: 0.625rem;
    height: 0.625rem;
    background: ${(props) => props.colorDot ?? "red"};
    border-radius: 50%;
    position: absolute;
    right: 0.375rem;
    top: 0.125rem;
    z-index: 1;
  `,
  HistoryLinkOverlayContainer = styled.div`
    display: flex;
    padding: 0.4375rem;
    flex-direction: ${(props) => props.isPiPOverlay && "column"};
    justify-content: ${(props) => props.isPiPOverlay && "center"};
    align-items: center;
    min-width: 16.875rem;
    min-height: ${(props) => (props.isPiPOverlay ? "3.75rem" : "2.5rem")};
    width: 16.875rem;
    height: ${(props) => (props.isPiPOverlay ? "3.75rem" : "2.5rem")};
    background: white;
    position: absolute;
    transition: top 2s;
    z-index: 2;
    border-radius: 0.375rem;
    cursor: pointer;
    left: 50%;
    top: 0px;
    transition: opacity 1s, transform 1s;
    opacity: ${(props) => props.isOverlayHistoryLink ? 1 : 0};
    transform: ${(props) =>
      props.isOverlayHistoryLink
        ? "translate(-50%, 20%)"
        : "translate(-50%, -100%)"};
  `,
  HistoryIcon = styled.i`
    font-size: ${(props) => (!props.isMobile ? "2.6rem" : "2.2rem")};
    margin-right: 0px;
    cursor: pointer;
  `,
  ReplyIcon = styled.i`
    font-size: ${(props) => (!props.isMobile ? "2.6rem" : "2.2rem")};
    margin-right: 0px;
    cursor: pointer;
  `,
  ContainerLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightgray;
    border-radius: 50%;
    width: 2.3125rem;
    height: 2.3125rem;
    margin-right: 0.625rem;
  `,
  LinkIcon = styled.i`
    max-width: 2.3125rem;
    font-size: 2rem;
    cursor: ponter;
  `,
  CloseIcon = styled.i`
    font-size: ${(props) => `1.5rem !important`};
    margin-right: 0.3125rem;
    margin-left: auto;
    color: black;
    cursor: ponter;
    position: absolute;
    top: 0.0625rem;
    right: -0.3125rem;
  `,
  OverlayTextContainer = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.125rem;
  `,
  OverlayButton = styled.button`
    text-transform: uppercase;
    padding: ${(props) =>
      props.isMobile ? "0.625rem" : `0.625rem  0.9375rem`};
    border: none;
    font-size: 1.25rem;
    border-radius: 0.3rem;
    background-color: ${(props) => props.colors.dialButtonBackground};
    color: ${(props) => props.colors.dialButtonTextColor};
    &:nth-child(1) {
      margin-right: 0.625rem;
    }
  `,
  OverlayButtonsContainer = styled.div`
    display: flex;
    alighn-item: center;
    justify-content: center;
    &:nth-child(1) {
      margin-right: 0.625rem;
    }
  `,
  ConfirmOverlayContainer = styled.div`
    border: 2px solid gray;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100000;
    transform: translate(-50%, -50%);
    background: ${(props) => props.bgColor ?? "transperent"};
    display: flex;
    flex-direction: column;
    border-radius: 0.375rem;
    padding: 0.5rem;
    width: 70%;
    max-width: 31.25rem;
  `,
  ContainerConfirmOverlay = styled.div`
    background-color: ${(props) =>
      Util.is_url(props.bgColor) ? "unset" : props.bgColor};
    background-image: ${(props) =>
      Util.is_url(props.bgColor) ? `url(${props.bgColor})` : "unset"};
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0px;
  `,
  PulseBox = styled.div`
    position: absolute;
    top: ${(props) => (props.isMobile ? "6px" : "0.3125rem")};
    left: ${(props) => (props.isMobile ? "12px" : "0.625rem")};
    border-radius: 50%;
    height: ${(props) => (props.isMobile ? "20px" : "1.875rem")};
    width: ${(props) => (props.isMobile ? "20px" : "1.875rem")};
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
  `,
  ConfirmOverlayText = styled.div`
    text-align: center;
    margin-bottom: 0.625rem;
    line-height: 1;
    font-size: ${(props) => (!props.isMobile ? "2rem" : "1rem")};
    color: ${(props) => props.colors.dialButtonBackground}};
    @media only screen and (max-width: 550px) {
      font-size: 1.3rem;
    }
  `;
