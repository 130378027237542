import * as React from "react";
import { Box } from "grommet";
import { setInputText, setInputFocus, sendMessage } from "../_actions";
import {
    StyledTextArea,
    SendMessageButton,
    SendIcon,
    InputPlaceholder,
} from "../_styles";
import { forceConfig } from "../../../contexts/SDKContext";
import { detectDeviceType } from "../../../common/detectDevice";
import useOnClickWithTouchHandler from "../../../hooks/useOnClickWithTouchHandler";
import { DEFAULT_HEIGHT_CHAT_INPUT } from "../../../constants";

const ChatInput = ({
    dispatch,
    state,
    inputTextAreaRef,
    widthMainScreen,
    isLandscape,
    isTablet,
    videoState,
}) => {
    const device = detectDeviceType();
    const isMoreRows = inputTextAreaRef?.current?.scrollHeight > 40;

    const handleFocusInput = () => {
        if (inputTextAreaRef.current) {
            inputTextAreaRef.current.focus();
            dispatch({
                type: setInputFocus,
                inputFocussed: true,
            });
        }
    };

    const focusInput = useOnClickWithTouchHandler(handleFocusInput);

    const dispatchMessage = (state, dispatch, inputTextAreaRef) => {
        if (!state.trulience.isMediaConnected()) {
        } else if (state.inputText && state.inputText.trim().length > 0) {
            dispatch({
                type: sendMessage,
                inputText: state.inputText,
                inputRef: inputTextAreaRef,
            });
            inputTextAreaRef.current.focus();
        }
    };

    const handleKeyPress = (event, dispatch, inputTextAreaRef, state) => {
        if (event.key === "Enter") {
            if (!state.trulience.isMediaConnected()) {
            } else {
                event.preventDefault();
                if (event.target.value.trim().length > 0) {
                    dispatch({
                        type: sendMessage,
                        inputText: event.target.value,
                        inputRef: inputTextAreaRef,
                    });
                }
            }
        }
    };

    if (
        forceConfig.hideChatInput ||
        (videoState?.fullScreen && device === "IOSIpadChrome")
    ) {
        return null;
    }

    return (
        <Box
            direction="row"
            id="input1337"
            pad="none"
            border={{
                color: state.colors.borderColorBetweenInputAndScreen,
                size: "1px",
                side: state.isMobile.any && !isTablet ? "bottom" : "top",
            }}
            fill="horizontal"
            isMobile={state.isMobile && !isTablet}
            width={
                forceConfig.chatInputBoxWidth === "window" && isLandscape
                    ? `${widthMainScreen}px !important`
                    : "100%"
            }
            style={{
                height: "3.875rem",
                minHeight: "3.875rem",
                padding: "0.625rem",
                background: state.colors.inputBoxBGColor,
                marginTop: state.isMobile.any && !isTablet && "0px",
                marginLeft:
                    forceConfig.chatInputBoxWidth === "window" && "auto",
                marginRight:
                    forceConfig.chatInputBoxWidth === "window" && "auto",
                position: "relative",
                alignItems: "center",
            }}
        >
            {!state.inputFocussed && state.inputText.length === 0 && (
                <InputPlaceholder {...focusInput}>
                    Type a message here
                </InputPlaceholder>
            )}
            <StyledTextArea
                ref={inputTextAreaRef}
                plain={true}
                isMoreRows={isMoreRows}
                flex="grow"
                value={state.inputText}
                readonly={true}
                resize={false}
                onFocus={(event) => {
                    dispatch({
                        type: setInputFocus,
                        inputFocussed: true,
                    });
                }}
                onBlur={(event) => {
                    dispatch({
                        type: setInputFocus,
                        inputFocussed: false,
                    });
                }}
                onChange={(event) => {
                    dispatch({
                        type: setInputText,
                        inputText: event.target.value,
                    });
                }}
                onKeyPress={(event) =>
                    handleKeyPress(event, dispatch, inputTextAreaRef, state)
                }
                isMobile={state.isMobile && !isTablet}
                colors={state.colors}
            />
            <Box
                direction="row"
                background={state.colors.sendButtonBGColor}
                border={
                    state.colors.sendButtonBorderColor === "none"
                        ? false
                        : { color: state.colors.sendButtonBorderColor }
                }
                style={{ marginTop: "0.1875rem" }}
            >
                <SendMessageButton
                    title="Send Message"
                    icon={
                        <SendIcon
                            isMobile={state.isMobile && !isTablet}
                            colors={state.colors}
                        />
                    }
                    onClick={() => {
                        dispatchMessage(state, dispatch, inputTextAreaRef);
                    }}
                    isMobile={state.isMobile && !isTablet}
                />
            </Box>
        </Box>
    );
};

export default ChatInput;
