import React from "react";
import { chatReducer } from "../components/chat/_reducer";
import { initialState as initialStateChat } from "../components/chat/_data";
import { ConsoleLogger } from "../common/logger";
import { playerReducer } from "../components/video/_reducer";
import { initialState as initialStateVideo } from "../components/video/_data";
import { initialState as initialStateMenu } from "../components/menu/data";
import {
    setTrulience,
    handleMessageReceived,
    clearChatHistoryOnReload,
    setWebSocketStatus,
    setAuthStatus,
    setOauth,
    setUIColors,
    sendMessage,
    clearInput,
} from "../components/chat/_actions";
import {
    setTrulienceObject,
    toggleWebRtcMic,
    toggleSpeaker,
    setProgress,
    setIsLoading,
    setStatusMessage,
    stopPlaying,
    setIsPlaying,
    setAvatarPhotoURL,
    exitFullScreen,
    setInvalidAvatar,
    setScreenAspectRationProps,
    setMobileScreenAspectRationProps,
    setTabletScreenAspectRationProps,
    setLogoProps,
} from "../components/video/_actions";
import moment from "moment";
import queryString from "query-string";
import Util from "../common/Util";
import {
    handleMenuReceived,
    handleHistoryLinksReceived,
    showHistoryOverlay,
    resetMenu,
    addHistoryMenuProps,
    showToastMessage,
} from "../components/menu/actions";
import { menuReducer } from "../components/menu/reducer";

let trl = null;

let videoState = null;
let dispatchVideoEvent = null;

let menuState = null;
let dispatchMenuEvent = null;

let chatState = null;
let dispatchChatEvent = null;
let prevMicStatus = true;

/* let pingTimerId = null; */
let autoSendSSMLTimer = null;

const micPermMsg = "Please grant microphone permission";

//Add any params passed in URL to forceConfig object
export let forceConfig = {
    connect: false,
    micOffByDefault: false,
    speakerOffByDefault: false,
    disableVideo: false,
    forceVideo: false,
    disableLocalMedia: false,
    disableDragging: false,
    sttonly: false,
    videoObjectFit: "cover",
    hideFS: false,
    showSubtitlesButton: false,
    hideChatInput: false,
    hideChatHistory: false,
    hideLetsChatBtn: false,
    dialButtonText: "LET'S CHAT",
    msgOnConnect: null,
    hideMicButton: false,
    hideSpeakerButton: false,
    hideHangUpButton: false,
    isOperator: false,
    ssmlSendRepeatInterval: 0,
    useSmVersion2: false,
    ssmlInput: "",
    chatInputBoxWidth: "full",
    screenAspectRatio: null,
    mobileScreenAspectRatio: null,
    tabletScreenAspectRatio: null,
    showLogo: false,
    logoPosition: "left",
    logoSrc: "",
    speechRecogLang: "",
    sttSource: "vps_sttonly",
    registerTrlEvents: null,
    deviceOrientation: false
};

const logger = new ConsoleLogger();
export const SDKContext = React.createContext(null);

export const SDKContextProvider = (props) => {
    React.useEffect(() => {
        addMessageListeners();
        trl = doTrulienceAuth();
        return () => {};
    }, []);

    [videoState, dispatchVideoEvent] = React.useReducer(
        playerReducer,
        initialStateVideo
    );
    [chatState, dispatchChatEvent] = React.useReducer(
        chatReducer,
        initialStateChat
    );
    [menuState, dispatchMenuEvent] = React.useReducer(
        menuReducer,
        initialStateMenu
    );

    return (
        <SDKContext.Provider
            value={[
                videoState,
                dispatchVideoEvent,
                chatState,
                dispatchChatEvent,
                menuState,
                dispatchMenuEvent,
            ]}
        >
            {props.children}
        </SDKContext.Provider>
    );
};

// Message Listeners to listen for the messages from outside the iframe.
const addMessageListeners = async () => {
    window.addEventListener(
        "message",
        (event) => {
            logger.log("Received event from parent - ", event);

            // Verify event.origin as required.
            if (event.origin) {
                // Test the origin here and return if not as expected.
            }

            if (event.data !== null && event.data !== undefined) {
                let eventData = event.data;

                // Ignore because our object is not yet ready.
                if (!trl) {
                    logger.log(
                        "Ignoring event because not yet ready to accept messages."
                    );
                    return;
                }

                // Register for events.
                if (eventData.command === "trl-register-events") {
                    if (
                        typeof eventData.message === "string" ||
                        eventData.message instanceof String
                    ) {
                        let eventNames = eventData.message.split(",");
                        for (var i=0 ; i<eventNames.length ; i++) {
                            var event = eventNames[i];
                            trl.on(event, "iframe");
                        }
                    }
                }

                // Unregister for events.
                if (eventData.command === "trl-unregister-events") {
                    if (
                        typeof eventData.message === "string" ||
                        eventData.message instanceof String
                    ) {
                        let eventNames = eventData.message.split(",");
                        for (var i=0 ; i<eventNames.length ; i++) {
                            var event = eventNames[i];
                            trl.off(event, "iframe");
                        }
                    }
                }

                // Handle sending of message to server
                if (eventData.command === "trl-chat") {
                    if (
                        typeof eventData.message === "string" ||
                        eventData.message instanceof String
                    ) {
                        trl.sendMessage(eventData.message);
                    }
                }

                if (eventData.command === "trl-mic-status") {
                    logger.log("mic status");
                    if (
                        typeof eventData.message === "boolean" ||
                        eventData.message instanceof Boolean
                    ) {
                        logger.log(`mic status: ${eventData.message}`);
                        trl.fixAudioContext();
                        trl.setMicEnabled(eventData.message, true);
                    }
                }

                if (eventData.command === "trl-request-avatar-photo-url") {
                    logger.log("get avatar photo url");
                    //
                    let messageData = {
                        message: videoState.avatarPhotoURL,
                    };
                    postMessageToParent("trl-respond-avatar-photo-url", messageData);
                }

                if (eventData.command === "trl-set-speaker-status") {
                    logger.log("trl-set-speaker-status");
                    if (typeof eventData.message === "boolean" || eventData.message instanceof Boolean) {
                        logger.log(`speaker status: ${eventData.message}`);
                        trl.fixAudioContext();
                        trl.setSpeakerEnabled(eventData.message);
                    }
                }

                if (eventData.command === "end-call") {
                    logger.log("end-call from iframe parent");
                    dispatchMenuEvent({ type: resetMenu });
                    dispatchVideoEvent({ type: setIsPlaying });
                    dispatchChatEvent({ type: clearInput });
                    dispatchChatEvent({
                        type: clearChatHistoryOnReload,
                    });
                }

                if (eventData.command === "request-orientation-permission") {
                    var userInput = prompt("Please enter your name:");

// Check if the user entered something
if (userInput !== null) {
                    logger.log("Requesting orientation permission.");
                    
                    DeviceOrientationEvent.requestPermission().then((response)=>{
                        if (response === 'granted') {
                            logger.log('DeviceOrientation Permission failed');
                        }
                    }).catch((response)=>{
                        logger.log('DeviceOrientation Permission failed');
                    });
                }
            }
            }
        },
        false
    );
};

const postMessageToParent = (eventName, eventParams) => {
    try {
        let eventData = { "eventName" : eventName, "eventParams" : eventParams};
        window.parent.postMessage(eventData, document.referrer);
    } catch(e) {
        console.error("Exception in postMessageToParent - " + e);
        console.error("Stack trace - " + e.stack);
    }
};

//Auth Events Callbacks
const a1 = async (resp) => {
    logger.log("In Auth success - " + resp);

    if (resp.clientConfigURL !== "" && resp.clientConfigURL !== undefined) {
        const response = await fetch(resp.clientConfigURL);
        const data = await response.json();

        forceConfig = { ...forceConfig, ...data, ...resp.dhType };

        doSettingConfigDepUrl();
    }

    // Update dhType in Trl object, as it might be different than previous
    // when received from auth response.
    if (forceConfig.dhType) {
        trl.setDHType(forceConfig.dhType);
    }

    dispatchChatEvent({
        type: setAuthStatus,
        authStatus: "OK",
        avatarPhotoURL: resp.avatarPhotoURL,
    });
    dispatchVideoEvent({
        type: setAvatarPhotoURL,
        avatarPhotoURL: resp.avatarPhotoURL,
    });

    if (
        forceConfig.ssmlInput.length > 0 &&
        forceConfig.ssmlSendRepeatInterval > 0
    ) {
        dispatchVideoEvent({ type: setIsPlaying });
    }

    if (forceConfig.connect) {
        dispatchVideoEvent({
            type: setIsPlaying,
        });

        trl.setSpeakerEnabled(false);
    } else {
        trl.setSpeakerEnabled(true);
    }
};

const a2 = (resp) => {
    logger.log("In auth fail - " + resp);
    dispatchChatEvent({
        type: setAuthStatus,
        authStatus: "FAIL",
    });

    if (videoState.isPlaying === true) {
        dispatchVideoEvent({ type: setIsPlaying }); // set isPlaying to false again
    }

    dispatchVideoEvent({
        type: setStatusMessage,
        statusMessage: resp.errorTitle,
    });
    dispatchVideoEvent({ type: setInvalidAvatar });

    if (resp.redirectTo && resp.oauth) {
        dispatchChatEvent({
            type: setOauth,
            oauth: true,
            oauthURL: resp.redirectTo,
            oauthAppId: resp.oauthAppId,
            oauthPartner: resp.partner,
        });
        logger.log("Redirecting to Vendor Oauth Login page....");
        window.location.href = resp.redirectTo;
    }
};

const loadProgress = (msg) => {
    logger.log("WebGL Load Progress: ", msg.percent);
    dispatchVideoEvent({ type: setProgress, loadProgress: msg.percent });
};

//WebSocket Event Callbacks
const ws1 = (resp) => {
    dispatchChatEvent({
        type: setWebSocketStatus,
        webSocketStatus: "CONNECTED",
        userName: resp.userName,
        userPhoto: resp.userPhoto,
    });

    if (
        forceConfig.ssmlInput.length > 0 &&
        forceConfig.ssmlSendRepeatInterval > 0
    ) {
        autoSendSSMLTimer = setInterval(
            autoSendSSML,
            forceConfig.ssmlSendRepeatInterval * 1000
        );
    }

    // Perform specific tasks if the dhType is WebGL that is done on media connect for regular client.
    if (trl.isDHWebglBased()) {
        //dispatchVideoEvent({type: setIsLoading, isLoading: false});

        // Since RTC is not there for WebGL clients, we will do some initial setup here
        // on websocket connection being open.
        if (forceConfig.msgOnConnect) {
            trl.sendMessage(forceConfig.msgOnConnect);
        }

        // set portrait view
        if (
            videoState.isMobile &&
            videoState.isMobile.any &&
            videoState.isMobile.viewportRatio > 1
        ) {
            trl.sendMessage(
                "<trl-content orientation='portrait' />"
            );
        }

        loadProgress({percent: 0.01});
    }

    // Set the TTS Config for WebGL client
    if (trl.isDHWebglBased()) {
        // TTSConfig once set will be used for all further STT requests until changed.
        // Below is an example of how to use AWS Polly for Text To Speech.
        trl.setTTSConfig(
            "polly",
            "JoannaNTTS",
            100,
            "ssml",
            forceConfig.useSmVersion2,
            true,
            "neural",
            null,
            false
        );

        // Below is an example of how to use Azure for Text To Speech.
        // trl.setTTSConfig("azure", "en-US-AmberNeural", 1, "ssml", forceConfig.useSmVersion2, false, null, "en-US");
    }
};

const ws2 = (resp) => {
    dispatchChatEvent({
        type: setWebSocketStatus,
        webSocketStatus: "DISCONNECTED",
    });
};

const ws3 = (trlResponse) => {
    logger.log("SDKContext - ws3 - trlResponse - ", trlResponse);

    let messageType = trlResponse.messageType;

    if (messageType === window.Trulience.MessageType.ChatHistory) {
        reloadChatHistory(trlResponse.cachedChat);
        return;
    }

    if (messageType === window.Trulience.MessageType.ChatText) {
        logger.log("Received chat message - status = " + trlResponse.status);

        // Ignore the acknowledgement messages.
        if (
            trlResponse.status === "MESSAGE_DELIVERED_TO_VPS" ||
            trlResponse.status === "MESSAGE_NOT_DELIVERED_TO_VPS"
        ) {
            logger.log("Returning from if");
            return;
        }

        if (trlResponse.sttResponse === true) {
            // Received stt message.
            logger.log(
                "Received STT Message - " + trlResponse.messageArray[0].message
            );
        }

        addChatMessage(trlResponse.messageArray, trlResponse.senderType);

        // Notify parent window (if currently used in iframe) about the new message.
        if (inIframe()) {
            // Do not send out enableListening on/off messages.
            var msg = trlResponse.messageArray[0].message;
            if (!msg.includes("enableListening")) {
                let messageData = {
                    message: msg,
                    agentName: trlResponse.agentName,
                    senderType: trlResponse.senderType,
                    sttResponse: trlResponse.sttResponse,
                };
                postMessageToParent("trl-chat", messageData);
            }
        }
        return;
    }

    if (messageType === window.Trulience.MessageType.ImageDetails) {
        logger.log("SDKContext - Received Image Details");
        return;
    }

    if (messageType === window.Trulience.MessageType.ForwardedFromVPS) {
        logger.log("Received forwarded data from vps - " + trlResponse.data);
        return;
    }
};

export const inIframe = () => {
    try {
        return window.location !== window.parent.location;
    } catch (e) {
        return true;
    }
};

const autoSendSSML = () => {
    dispatchChatEvent({
        type: sendMessage,
        inputText: forceConfig.ssmlInput,
    });
};

const addChatMessage = (messageArray, senderType) => {
    /* logger.log(messageArray, senderType, '1337') */
    let newMsgArr =
        chatState.messages && chatState.messages.length > 0
            ? [...chatState.messages]
            : [];

    for (var cntr = 0; cntr < messageArray.length; cntr++) {
        let message = messageArray[cntr].message;
        let messageTokens = messageArray[cntr].messageTokens;
        if (!message) {
            logger.log("Returning because no message");
            return;
        }

        let linksHistory = Util.parseHistoryLink(messageTokens);
        let linksHistoryMenuProps = Util.linksHistoryMenu(messageTokens);
        let menuProps = Util.parseMenu(messageTokens);
        let logoProps = Util.parseLogo(messageTokens);
        /* let isPhrase = Util.parsePhrase(messageTokens); */
        let screenImage = Util.parseImage(messageTokens);
        let screenAspectRatioProps = Util.parseScreenAspectRatio(messageTokens);
        let mobileScreenAspectRatioProps =
            Util.parseMobileScreenAspectRatio(messageTokens);
        let tableteScreenAspectRatioProps =
            Util.parseTabletScreenAspectRatio(messageTokens);
        logger.log("screenImage -----> ", screenImage);

        let thisMsg = {};

        if (senderType === "USER") {
            thisMsg = {
                id: Util.generateUniqueId(),
                type: 1,
                image: chatState.userPhoto,
                text: message,
                messageTokens: messageTokens,
                timestamp: moment().format("HH:mm"),
                userName: chatState.userName,
            };
        } //AVATAR
        else {
            thisMsg = {
                id: Util.generateUniqueId(),
                type: 0,
                image: "/static/images/botIcon.png",
                text: message,
                messageTokens: messageTokens,
                pic: screenImage === chatState.userPhoto ? null : screenImage,
                timestamp: moment().format("HH:mm"),
                userName: "Bot",
            };
        }

        //TODO it is not known how many links we will process

        if (
            linksHistoryMenuProps !== null &&
            linksHistoryMenuProps !== undefined
        ) {
            dispatchMenuEvent({
                type: addHistoryMenuProps,
                historyMenuProps: linksHistoryMenuProps,
            });
        }

        if (linksHistory !== null && linksHistory !== undefined) {
            if (
                Util.checkForDuplicateLinks(
                    menuState.historyLinks,
                    linksHistory[0]
                )
            ) {
                dispatchMenuEvent({
                    type: showHistoryOverlay,
                    overlayData: linksHistory[0],
                });
                dispatchMenuEvent({
                    type: handleHistoryLinksReceived,
                    links: linksHistory,
                });
            }
        }

        newMsgArr.push(thisMsg);

        // if (!isPhrase) {
        dispatchChatEvent({ type: handleMessageReceived, messages: newMsgArr });
        // }

        if (logoProps !== null) {
            dispatchVideoEvent({ type: setLogoProps, logoProps: logoProps });
        }

        if (menuProps !== null && menuProps !== undefined) {
            const { showMenu, ...otherProps } = menuProps;
            dispatchMenuEvent({ type: handleMenuReceived, ...otherProps });

            //A delay is required for the styles to appear
            //eslint-disable-next-line no-loop-func
            let timer = setTimeout(() => {
                dispatchMenuEvent({
                    type: handleMenuReceived,
                    ...{ showMenu },
                });
            }, 500);
            return () => clearTimeout(timer);
        }

        // Aspect Ratio Setting
        if (screenAspectRatioProps !== null) {
            dispatchVideoEvent({
                type: setScreenAspectRationProps,
                screenAspectRationProps: screenAspectRatioProps,
            });
        }

        if (mobileScreenAspectRatioProps !== null) {
            dispatchVideoEvent({
                type: setMobileScreenAspectRationProps,
                mobileScreenAspectRatioProps: mobileScreenAspectRatioProps,
            });
        }

        if (tableteScreenAspectRatioProps !== null) {
            dispatchVideoEvent({
                type: setTabletScreenAspectRationProps,
                tableteScreenAspectRatioProps: tableteScreenAspectRatioProps,
            });
        }
    }

    if (chatState.isMobile.any) {
        trl.screenManager().scrollToBottom();
    } else {
        trl.screenManager().scrollToTop();
    }
};

const reloadChatHistory = (cachedChat) => {
    if (!cachedChat || cachedChat.length === 0) return;

    dispatchChatEvent({ type: clearChatHistoryOnReload });

    cachedChat.forEach((chatObj) => {
        addChatMessage(chatObj.messageArray, chatObj.senderType);
    });
};

const ws4 = (warningResponse) => {
    logger.log("WebSocket => onWarningHandler Event => Warning: ");
    logger.log(warningResponse);
};

const ws5 = (errorResponse) => {
    logger.log("WebSocket => onConnectionErrorHandler Event => Error: ");
    logger.log(errorResponse);
    clearInterval(autoSendSSMLTimer);
};

const ws6 = (closeResponse) => {
    logger.log("WebSocket => onConnectionCloseHandler Response: ");
    logger.log(closeResponse);
    clearInterval(autoSendSSMLTimer);
    dispatchChatEvent({
        type: setWebSocketStatus,
        webSocketStatus: "DISCONNECTED",
    });
    dispatchVideoEvent({ type: stopPlaying });
    dispatchVideoEvent({ type: setStatusMessage, statusMessage: "" });

    // Reload the page only if its not set to auto connect.
    if (!forceConfig.connect) {
        window.location.reload();
    }
};

//---------TAKE CARE OF 3 THINGS - ERROR MESSAGES,  TG STATUS , SPINNER IN VIDEO COMPONENTS

const mediaConnected = (msg) => {
    // Just update mic status for WebGL
    if (trl.isDHWebglBased()) {
        logger.log("In mediaConnect 1");
        if (forceConfig.micOffByDefault) {
            setTimeout(function () {
                logger.log("In mediaConnect 2 - setting mic off");
                trl.rtc().micOff();
            }, 1000);
        } else {
            // Enable mic by default for webgl avatar on connect.
            setTimeout(function () {
                logger.log("In mediaConnect 3 - setting mic on");
                trl.rtc().micOn();
            }, 100);
        }

        if (forceConfig.speakerOffByDefault) {
            setTimeout(function () {
                logger.log("In mediaConnect 4 - setting speaker off");
                trl.rtc().speakerOff();
            }, 1000);
        } else {
            // Enable speaker by default for webgl avatar on connect.
            setTimeout(function () {
                logger.log("In mediaConnect 5 - setting speaker on");
                trl.rtc().speakerOn();
            }, 100);
        }

        // Handle autoplay
        handleAutoplay();

        return;
    } else {
        // Enable/Disable speaker as required.
        if (forceConfig.speakerOffByDefault) {
            trl.rtc().speakerOff();
        } else {
            trl.rtc().speakerOn();
        }
    }

    // Handle auto play
    handleAutoplay();

    if (forceConfig.sttonly)
        dispatchVideoEvent({
            type: setStatusMessage,
            statusMessage:
                "Welcome to the world of Trulience\n\nTrulience builds interactive digital humans in Unreal Engine that can be run locally, or hosted in the cloud and embedded within web pages via a WebRTC video call.\n\nThis demo has installed an interactive digital human onto your PC and uses a web browser to capture audio and exchange chat messages. Why don't you ask our digital human for her name? You will need to give your PC's focus back to Unreal to see and hear her in action. Anything she doesn't understand she will repeat back to you.\n\nDigital humans can also be interacted with on the Trulience website. Here, they are ready and waiting for your call 24/7: ",
            statusMessageLink: "https://trulience.com",
        });

    logger.log("mediaConnect20 => : " + forceConfig.msgOnConnect);

    if (forceConfig.msgOnConnect) {
        setTimeout(() => {
            trl.sendMessage(forceConfig.msgOnConnect);
        }, 500);
    }

    // set portrait view
    if (
        videoState.isMobile &&
        videoState.isMobile.any &&
        videoState.isMobile.viewportRatio > 1
    ) {
        setTimeout(() => {
            trl.sendMessage(
                "<trl-content orientation='portrait' />"
            );
        }, 500);
    }

    logger.log("mediaConnect22 => : " + msg);
    if (forceConfig.micOffByDefault) {
        setTimeout(function () {
            trl.rtc().micOff();
        }, 1000);
    }
};

const handleAutoplay = () => {
// If connect=true there are chances that audio will not be heard 
    // due autoplay being disabled by the browser.
    // So to handle such case, we will play a silent audio and confirm.
    // If it fails, that means auto play is disabled and we perform speaker Off else do nothing.
    logger.log("autoplay before if");
    if (forceConfig.connect) {
        logger.log("autoplay inside if");
        if (!Util.isAutoplayEnabled()) {
            logger.log("Switching speaker off as connect=true and autoplay disabled");
            
            // Make sure the speaker is visible in this case
            // even if was hidden via param.
            forceConfig.hideSpeakerButton = false;

            // Mute the speaker
            trl.rtc().speakerOff();
        } else {
            logger.log("Autoplay enabled");
        }
    }
    logger.log("autoplay after if");
};

const mediaConnecting = (msg) => {
    logger.log("mediaConnecting => : ", msg);

    dispatchVideoEvent({ type: setStatusMessage, statusMessage: "" });
};

const avatarStatusUpdateHandler = (resp) => {
    let { avatarStatus } = resp;

    logger.log("Avatarstatus 1");

    if (avatarStatus === window["Trulience"].AvatarStatus.UNLOADED) {
        logger.log("============== HANDLING UNLOADED===============");
        trl.rtc().hide();
        dispatchVideoEvent({ type: setIsLoading, isLoading: !trl.isDHWebglBased() });
    } else if (avatarStatus === window["Trulience"].AvatarStatus.LOADED) {
        logger.log("============== HANDLING LOADED===============");
        dispatchVideoEvent({ type: setIsLoading, isLoading: false });
        trl.rtc().show();
    }

    // AEC half duplex when UE running locally
    // muteMicWhenAvatarSpeaking config - It set the mic muted when avatar starts talking.
    // does not automatically unmutes the mic on avatar stopped talking. Mic needs to be manually enabled by user.
    logger.log(
        "forceConfig.muteMicWhenAvatarSpeaking = " +
            forceConfig.muteMicWhenAvatarSpeaking
    );
    if (!forceConfig.sttonly && !forceConfig.muteMicWhenAvatarSpeaking) {
        return;
    }
    logger.log(
        "avatarStatus => " +
            avatarStatus +
            ", prevMicStatus = " +
            prevMicStatus +
            ", isMicEnabled = " +
            trl.isMicEnabled()
    );

    if (avatarStatus === window["Trulience"].AvatarStatus.TALKING) {
        prevMicStatus = trl.isMicEnabled();
        trl.setMicEnabled(false, false);
    } else {
        if (!forceConfig.muteMicWhenAvatarSpeaking) {
            // IDLE || LISTENING
            if (prevMicStatus && !trl.isMicEnabled()) {
                trl.setMicEnabled(true, false);
            }
            if (!prevMicStatus && trl.isMicEnabled()) {
                trl.setMicEnabled(false, false);
            }
        }
    }

    logger.log(
        "avatarStatus2 => " +
            avatarStatus +
            ", prevMicStatus = " +
            prevMicStatus +
            ", isMicEnabled = " +
            trl.isMicEnabled()
    );
};

const micAccessUpdateHandler = (data) => {
    logger.log("Mic Permission Granted => " + data.permissionGranted);
    if (!data.permissionGranted) {
        dispatchMenuEvent({ type: showToastMessage, message: micPermMsg })
    }
};

const mediaWaiting = (msg) => {
    dispatchVideoEvent({ type: setIsLoading, isLoading: false });
    logger.log("mediaWaiting => : ", msg);
    let msgToShow = "Waiting For Avatar";
    if (msg.usageLimitExceeded === true) {
        msgToShow = msg.usageLimitExceededMsg;
    } else if (msg.deactivated === true) {
        msgToShow =
            "This account has been deactivated. Please contact info@trulience.com for more details.";
    }

    dispatchVideoEvent({
        type: setStatusMessage,
        statusMessage: msgToShow,
    });
};

const mediaBusy = (msg) => {
    dispatchVideoEvent({ type: setIsLoading, isLoading: false });
    logger.log("mediaBusy => : " + msg);
    dispatchVideoEvent({
        type: setStatusMessage,
        statusMessage: "Service is Busy, Please try later.",
    });
};

const mediaDisconnecting = (msg) => {
    logger.log("mediaDisconnecting => ", msg);
    let errorMessage = null;

    // User Hangs up
    if (msg.reason === window["Trulience"].CallEndReason.HANGED_UP) {
        logger.log("------------HANGED_UP------------");
        errorMessage = "";
        // Call already Ended as User hanged up
    }

    // Server Admin can terminate call or VPS Websocket closed
    // Reasons => 0=HANGED_UP, 1=DISCONNECTED,  2=FAILED, 3=UNAUTHORISED
    if (msg.reason === window["Trulience"].CallEndReason.DISCONNECTED) {
        logger.log("------------TG DISCONNECT------------");
        errorMessage = "";

        dispatchVideoEvent({ type: toggleWebRtcMic });
        dispatchVideoEvent({ type: toggleSpeaker });
    }

    //RTC call Fails due to 503 sdp error
    if (msg.reason === window["Trulience"].CallEndReason.FAILED) {
        logger.log("------------RTC FAILED------------T");
        errorMessage = "Call Failed";
        // Call already Ended as RTC call Failed
    }

    //RTC call Fails due to 503 sdp error
    if (msg.reason === window["Trulience"].CallEndReason.UNAUTHORISED) {
        logger.log("------------CALL UNAUTHORISED------------T");
        errorMessage = "Unauthorised Call";
        // Call already Ended as RTC call Failed
    }

    // Reset UI
    dispatchVideoEvent({ type: stopPlaying });
    dispatchMenuEvent({ type: resetMenu });
    writeErrorMessageToVideoScreen(errorMessage);
    dispatchMenuEvent({ type: resetMenu });
    dispatchChatEvent({ type: clearInput });

    //exit FS if was in FS mode
    dispatchVideoEvent({ type: exitFullScreen });

    logger.log("UNLOADED AT END");
    dispatchVideoEvent({ type: setIsLoading, isLoading: false });
    document.getElementById("remoteVideo").style.visibility = "hidden";
};

const writeErrorMessageToVideoScreen = (errorMessage) => {
    dispatchVideoEvent({ type: setStatusMessage, statusMessage: errorMessage });
};

const micStatusUpdateHandler = (status) => {
    logger.log("micStatus => " + status);
    dispatchVideoEvent({
        type: toggleWebRtcMic,
        micStatus: status,
    });
}

const speakerStatusUpdateHandler = (status) => {
    logger.log("speakerStatus => " + status);

    dispatchVideoEvent({
        type: toggleSpeaker,
        speakerStatus: status,
    });
};

let videoElements = {
    remoteVideo: "remoteVideo",
};

const doSettingConfigDepUrl = () => {
    const urlParamsString = window.location.search + window.location.hash;

    let query = queryString.parse(urlParamsString, {
        parseBooleans: true,
        parseNumbers: false,
    });

    forceConfig.forceVideo = query.forceVideo ?? forceConfig.forceVideo;
    forceConfig.disableLocalMedia =
        query.disableLocalMedia ?? forceConfig.forceVideo;
    forceConfig.sttonly = query.sttonly ?? forceConfig.sttonly;
    forceConfig.connect = query.connect ?? forceConfig.connect;
    forceConfig.micOffByDefault = query.micOff ?? forceConfig.micOffByDefault;
    forceConfig.speakerOffByDefault =
        query.speakerOff ?? forceConfig.speakerOffByDefault;
    forceConfig.hideFS = query.hideFS ?? forceConfig.hideFS;
    forceConfig.hideChatInput =
        query.hideChatInput ?? forceConfig.hideChatInput;
    forceConfig.hideChatHistory =
        query.hideChatHistory ?? forceConfig.hideChatHistory;
    forceConfig.hideLetsChatBtn =
        query.hideLetsChatBtn ?? forceConfig.hideLetsChatBtn;
    forceConfig.disableDragging =
        query.disableDragging ?? forceConfig.disableDragging;

    forceConfig.hideMicButton =
        query.hideMicButton ?? forceConfig.hideMicButton;
    forceConfig.hideSpeakerButton =
        query.hideSpeakerButton ?? forceConfig.hideSpeakerButton;
    forceConfig.hideHangUpButton =
        query.hideHangUpButton ?? forceConfig.hideHangUpButton;

    forceConfig.isOperator = query.isOperator ?? forceConfig.isOperator;

    forceConfig.dialButtonText =
        query.dialButtonText ?? forceConfig.dialButtonText;

    //Aspect Ratio Setting
    forceConfig.screenAspectRatio = query.screenAspectRatio
        ? Util.converAspectRatio(query.screenAspectRatio)
        : forceConfig.screenAspectRatio === null
        ? null
        : Util.converAspectRatio(forceConfig.screenAspectRatio);

    forceConfig.mobileScreenAspectRatio = query.mobileScreenAspectRatio
        ? Util.converAspectRatio(query.mobileScreenAspectRatio)
        : forceConfig.mobileScreenAspectRatio === null
        ? null
        : Util.converAspectRatio(forceConfig.mobileScreenAspectRatio);

    forceConfig.tabletScreenAspectRatio = query.tabletScreenAspectRatio
        ? Util.converAspectRatio(query.tabletScreenAspectRatio)
        : forceConfig.tabletScreenAspectRatio === null
        ? null
        : Util.converAspectRatio(forceConfig.tabletScreenAspectRatio);

    forceConfig.showLogo = query.showLogo ?? forceConfig.showLogo;
    forceConfig.logoPosition = query.logoPosition ?? forceConfig.logoPosition;
    forceConfig.logoSrc = query.logoSrc ?? forceConfig.logoSrc;
    forceConfig.showSubtitlesButton =
        query.showSubtitlesButton ?? forceConfig.showSubtitlesButton;
    forceConfig.chatInputBoxWidth =
        query.chatInputBoxWidth ?? forceConfig.chatInputBoxWidth;
    forceConfig.msgOnConnect = query.msgOnConnect ?? forceConfig.msgOnConnect;

    forceConfig.videoObjectFit =
        query.videoObjectFit ?? forceConfig.videoObjectFit;

    forceConfig.ssmlSendRepeatInterval =
        query.ssmlSendRepeatInterval ?? forceConfig.ssmlSendRepeatInterval;
    forceConfig.ssmlInput = query.ssmlInput ?? forceConfig.ssmlInput;
    forceConfig.useSmVersion2 = query.useSmVersion2
        ? query.useSmVersion2
        : false;

    forceConfig.speechRecogLang = query.speechRecogLang
        ? query.speechRecogLang
        : "en-US";
    forceConfig.sttSource = query.sttSource ?? forceConfig.sttSource;
    forceConfig.registerTrlEvents = query.registerTrlEvents ?? forceConfig.registerTrlEvents;
    forceConfig.deviceOrientation = query.deviceOrientation ?? forceConfig.deviceOrientation;

    logger.log("forceConfig = ", forceConfig);

    if (trl != null) {
        trl.setSpeechRecogLang(forceConfig.speechRecogLang);
    }

    if (forceConfig.screenAspectRatio !== null) {
        dispatchVideoEvent({
            type: setScreenAspectRationProps,
            screenAspectRationProps: {
                enabled: true,
                heightRatio: forceConfig.screenAspectRatio.heightRatio,
                widthRatio: forceConfig.screenAspectRatio.widthRatio,
            },
        });
    }

    if (forceConfig.mobileScreenAspectRatio !== null) {
        dispatchVideoEvent({
            type: setMobileScreenAspectRationProps,
            mobileScreenAspectRatioProps: {
                enabled: true,
                heightRatio: forceConfig.mobileScreenAspectRatio.heightRatio,
                widthRatio: forceConfig.mobileScreenAspectRatio.widthRatio,
            },
        });
    }

    if (forceConfig.tabletScreenAspectRatio !== null) {
        dispatchVideoEvent({
            type: setTabletScreenAspectRationProps,
            tabletScreenAspectRatioProps: {
                enabled: true,
                heightRatio: forceConfig.tabletScreenAspectRatio.heightRatio,
                widthRatio: forceConfig.tabletScreenAspectRatio.widthRatio,
            },
        });
    }

    dispatchVideoEvent({
        type: setLogoProps,
        logoProps: {
            showLogo: `${forceConfig.showLogo}`,
            logoPosition: forceConfig.logoPosition,
            logoSrc: forceConfig.logoSrc,
        },
    });

    // BG Colors:  Set Chat Screen and Bubble Colors
    const uiColors = {
        chatScreenBGColor:
            query.chatScreenBGColor ?? forceConfig.chatScreenBGColor,

        userChatBubbleBGColor:
            query.userChatBubbleBGColor ?? forceConfig.userChatBubbleBGColor,
        avatarChatBubbleBGColor:
            query.avatarChatBubbleBGColor ??
            forceConfig.avatarChatBubbleBGColor,
        userChatBubbleBorderColor:
            query.userChatBubbleBorderColor ??
            forceConfig.userChatBubbleBorderColor,
        avatarChatBubbleBorderColor:
            query.avatarChatBubbleBorderColor ??
            forceConfig.avatarChatBubbleBorderColor,
        userChatBubbleTextColor:
            query.userChatBubbleTextColor ??
            forceConfig.userChatBubbleTextColor,
        avatarChatBubbleTextColor:
            query.avatarChatBubbleTextColor ??
            forceConfig.avatarChatBubbleTextColor,

        inputBoxBGColor: query.inputBoxBGColor ?? forceConfig.inputBoxBGColor,
        inputBoxBorderColor:
            query.inputBoxBorderColor ?? forceConfig.inputBoxBorderColor,
        inputBoxTextColor:
            query.inputBoxTextColor ?? forceConfig.inputBoxTextColor,

        sendButtonBGColor:
            query.sendButtonBGColor ?? forceConfig.sendButtonBGColor,
        sendButtonArrowColor:
            query.sendButtonArrowColor ?? forceConfig.sendButtonArrowColor,
        sendButtonBorderColor:
            query.sendButtonBorderColor ?? forceConfig.sendButtonBorderColor,

        borderColorBetweenInputAndScreen:
            query.borderColorBetweenInputAndScreen ??
            forceConfig.borderColorBetweenInputAndScreen,

        dialButtonTextColor:
            query.dialButtonTextColor ?? forceConfig.dialButtonTextColor,
        dialButtonBackground:
            query.dialButtonBackground ?? forceConfig.dialButtonBackground,
        dialPageBackground:
            query.dialPageBackground ?? forceConfig.dialPageBackground,
    };

    dispatchChatEvent({
        type: setUIColors,
        colors: uiColors,
    });
};

const handleBeforePageUnload = (event) => {
    dispatchMenuEvent({ type: resetMenu });
    dispatchChatEvent({ type: clearInput });
    dispatchChatEvent({ type: clearChatHistoryOnReload });
    window.removeEventListener("beforeunload", handleBeforePageUnload);

    // Clear listening of events.
    trl.off("auth-success", a1);
    trl.off("auth-fail", a2);

    trl.off("websocket-connect", ws1);
    trl.off("websocket-message", ws3);
    trl.off("websocket-error", ws5);
    trl.off("websocket-close", ws6);

    trl.off("media-connecting", mediaConnecting);
    trl.off("media-connected", mediaConnected);
    trl.off("media-warn", ws4);
    trl.off("media-waiting", mediaWaiting);
    trl.off("media-busy", mediaBusy);
    trl.off("media-disconnecting", mediaDisconnecting);
    trl.off("mic-update", micStatusUpdateHandler);
    trl.off("speaker-update", speakerStatusUpdateHandler);
    trl.off("avatar-status-update", avatarStatusUpdateHandler);
    trl.off("mic-access", micAccessUpdateHandler);
    trl.off("load-progress", loadProgress);
};

const doTrulienceAuth = () => {
    const urlParamsString = window.location.search + window.location.hash;

    let query = queryString.parse(urlParamsString, {
        parseBooleans: true,
        parseNumbers: false,
    });
    if (query.avatarId === undefined) {
        query.avatarId = window.location.pathname
            .replace(/\//g, "")
            .replace("avatar", "");
    }

    logger.log(query);

    let alt_userId = query.userId ? query.userId : null;
    let alt_vps = query.vps ? query.vps : null;
    let alt_webrtc = query.webrtc ? query.webrtc : null;
    let alt_accessToken = query.accessToken ? query.accessToken : null;

    logger.log("alt_userId = ", alt_userId);
    logger.log("alt_vps = ", alt_vps);
    logger.log("alt_accessToken = ", alt_accessToken);

    /* forceConfig.connect = query.connect ? query.connect : false; */
    forceConfig.avatarId = query.avatarId;
    doSettingConfigDepUrl();

    logger.log("forceConfig = ", forceConfig);

    // If not provided, default is CUSTOM dh type.
    var reqdDHType = forceConfig.dhType
        ? forceConfig.dhType
        : window.Trulience.DigitalHumanType.CUSTOM;
    var enableAvatar =
        reqdDHType === window.Trulience.DigitalHumanType.WEBGL ? false : true;
    var avatarId = forceConfig.avatarId ? forceConfig.avatarId : "10"; // Default to 10 if not provided.

    // Register for sdk events if requested so while in iframe.
    if (forceConfig.registerTrlEvents) {
        var dataToSend = { "command" : "trl-register-events", "message" : forceConfig.registerTrlEvents};
        window.postMessage(dataToSend);
    }

    logger.log(
        "CREATING NEW TRL OBJECT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
    );
    trl = window["Trulience"]
        .Builder()
        .setAvatarId(avatarId) // Setting as String as Long values are truncated in JavaScript
        .setUserId(alt_userId)
        .setLanguagePreference("en-US")
        .setUserName("Guest")
        .assignVPS(alt_vps)
        .enableAvatar(enableAvatar) // false for chat only, true for chat and video avatar
        .registerVideoElements(videoElements)
        .forceVideo(forceConfig.forceVideo)
        .disableVideo(forceConfig.disableVideo)
        .setIsLocalMediaDisabled(forceConfig.disableLocalMedia)
        .setWebRTCServer(alt_webrtc)
        .setAccessToken(alt_accessToken)
        .setIsOperator(forceConfig.isOperator)
        .setDHType(reqdDHType)
        .setSttSource(forceConfig.sttSource)
        .build();

    dispatchChatEvent({
        type: setTrulience,
        trulience: trl,
    });

    dispatchVideoEvent({
        type: setTrulienceObject,
        trulience: trl,
    });

    dispatchVideoEvent({
        type: setLogoProps,
        logoProps: {
            showLogo: `${forceConfig.showLogo}`,
            logoPosition: forceConfig.logoPosition,
            logoSrc: forceConfig.logoSrc,
        },
    });

    if (forceConfig.sttonly) {
        trl.setSTTAddress(forceConfig.sttonly);
    }

    if (forceConfig.speechRecogLang) {
        trl.setSpeechRecogLang(forceConfig.speechRecogLang);
    }

    trl.on("auth-success", a1);
    trl.on("auth-fail", a2);

    trl.on("websocket-connect", ws1);
    trl.on("websocket-message", ws3);
    trl.on("websocket-error", ws5);
    trl.on("websocket-close", ws6);

    trl.on("media-connecting", mediaConnecting);
    trl.on("media-connected", mediaConnected);
    trl.on("media-warn", ws4);
    trl.on("media-waiting", mediaWaiting);
    trl.on("media-busy", mediaBusy);
    trl.on("media-disconnecting", mediaDisconnecting);
    trl.on("mic-update", micStatusUpdateHandler);
    trl.on("speaker-update", speakerStatusUpdateHandler);
    trl.on("avatar-status-update", avatarStatusUpdateHandler);
    trl.on("mic-access", micAccessUpdateHandler);
    trl.on("load-progress", loadProgress);

    //trl.connectGateway();
    trl.authenticate();
    window.trl = trl;

    // Handle reload
    window.addEventListener("beforeunload", handleBeforePageUnload);

    return trl;
};
