import styled from "styled-components";

export const SideNav = styled.div`
    height: ${(props) =>
      props.isLandscape && !props.isFullScreen
        ? `calc(100% - 1.875rem)`
        : "unset"};
    width: ${(props) =>
      props.isLandscape ? (props.isOpen ? `25%` : "0px") : "100%"};
    min-width: ${(props) =>
      props.isLandscape && (props.isOpen ? "12.5rem" : "0px")};
    position: absolute;
    max-width: ${(props) => props.maxWidthMenu && props.maxWidthMenu}
    z-index: 1001;
    height: ${(props) =>
      !props.isLandscape &&
      (props.isOpen
        ? `calc(52vh - 3.875rem - ${
            props.isChatScreenMobile ? "0rem" : "1.875rem"
          })`
        : "0px")};
    max-height: ${(props) =>
      !props.isLandscape &&
      `calc(52vh - 3.875rem - ${
        props.isChatScreenMobile ? "0rem" : "1.875rem"
      })`};
    top: ${(props) => (props.isMobile ? "0px" : "0")};
    left: 0;
    background-color: ${(props) => {
      return props.menuBg ?? "#383838";
    }};
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: ${(props) =>
      props.isLandscape ? "1.875rem" : props.isOpen ? "1.875rem" : "0px"};
  `,
  CloseIcon = styled.i`
    position: absolute;
    top: ${(props) => (props.isLandscape ? "0px" : "0.3125rem")};
    left: ${(props) => (props.isLandscape ? "0px" : "unset")};
    right: ${(props) => (props.isLandscape ? "unset" : "0.5rem")};
    font-size: 1.875rem;
    color: white;
    cursor: pointer;
  `,
  Link = styled.a`
    text-decoration: none;
    font-size: ${(props) => (props.isMobile ? "1.125rem" : "1.5rem")};
    display: block;
    transition: 0.3s;
    overflow-wrap: anywhere;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.1875rem 0.5rem;
  `,
  Option = styled.div`
    display: flex;
    color: white;
    align-items: center;
    line-height: 1;
    padding: 0px 0.5rem 0px 0.75rem;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  `,
  Icon = styled.i`
    font-size: 1.5625rem;
    margin-right: 0.5rem;
    color: white;
    width: 1.5625rem;
  `;
