import { Anchor, Box, TextArea, Button } from "grommet";
import { Send } from "styled-icons/boxicons-regular";
import styled from "styled-components";
import { ResizableBox } from "react-resizable";
import { detectDeviceType } from "../../common/detectDevice";

const device = detectDeviceType();

export const StyledChatScreenWeb = styled(Box)`
        padding-top: 0;
        position: relative;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        justify-content: initial;
        flex-direction: column-reverse;
        background-color: ${(props) => props.colors.chatScreenBGColor};

        &::-webkit-scrollbar {
            width: 0.0625rem;
        }

        @media screen and (orientation: portrait) {
            height: ${(props) =>
                props.isIpadTable
                    ? "calc(52vh - 5.625rem)"
                    : props.hideChatInput
                    ? "0"
                    : `calc(${
                          device === "AndroidTab"
                              ? "44vh"
                              : device === "IOSIpadChrome"
                              ? `${props.hightClient}px - ${props.heightMainScreen}px`
                              : "52vh"
                      } - 3.875rem)`};
        }
        @media screen and (orientation: landscape) {
            height: ${(props) =>
                props.isTabletWithOpenKeyboard
                    ? `calc(${
                          device === "AndroidTab" ? "44vh" : "52vh"
                      } - 3.875rem)`
                    : "0rem"};
        }
    `,
    StyledResizableBox = styled(ResizableBox)`
        position: relative;

        .custom-handle {
            position: absolute;
            background-color: #b1bdcd;
            opacity: 0.95;
            z-index: 1200;
            transition: all 0.1s ease;

            &:hover {
                background-color: #dd3c66;
                opacity: 1;
                z-index: 1200;
            }
        }

        .custom-handle-sw {
            bottom: -4px;
            left: -4px;
            cursor: sw-resize;
        }
        .custom-handle-se {
            bottom: -4px;
            right: -4px;
            cursor: se-resize;
        }
        .custom-handle-nw {
            top: -4px;
            left: -4px;
            cursor: nw-resize;
        }
        .custom-handle-ne {
            top: -4px;
            right: -4px;
            cursor: ne-resize;
        }
        .custom-handle-w,
        .custom-handle-e {
            top: 0;
            width: 8px;
            height: 100vh;
            cursor: ew-resize;
        }
        .custom-handle-w {
            left: -4px;
        }
        .custom-handle-e {
            right: -4px;
        }
        .custom-handle-n,
        .custom-handle-s {
            left: 50%;
            cursor: ns-resize;
        }
        .custom-handle-n {
            top: -4px;
        }
        .custom-handle-s {
            height: 8px;
            width: 100vw;
            bottom: 0;
            width: 100vw;
            left: 0;
            right: 0;
        }
    `,
    StyledChatScreenMobile = styled(Box)`
        padding-bottom: 8px;
        position: relative;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        background-color: ${(props) => props.colors.chatScreenBGColor};
        height: 100%;

        @media screen and (orientation: landscape) {
            height: ${(props) => !props.inputFocussed && "0rem"};
        }
    `,
    StyledTextArea = styled(TextArea)`
        width: 100%;
        transform: ${(props) =>
            props.isMoreRows
                ? "translate(0%, 0.09375rem)"
                : "translate(0%, 0.6875rem)"};
        background-color: ${(props) => props.colors.inputBoxBGColor};
        font-family: sans-serif;
        font-size: ${(props) =>
            props.isMobile.any
                ? props.isMobile.tablet
                    ? "1.25rem"
                    : "1rem"
                : "0.9375rem"};
        font-weight: 500;
        height: 2.5rem;
        line-height: 1.25;
        border: ${(props) =>
            props.colors.inputBoxBorderColor !== "none"
                ? "0.0625rem solid " + props.colors.inputBoxBorderColor
                : "none"};
        display: flex;
        border-radius: unset;
        font-size: 1.125rem;
        color: ${(props) => props.colors.inputBoxTextColor};
        ::placeholder {
            font-size: 1.125rem;
        }
        padding: 0rem;
    `,
    SendIcon = styled(Send)`
        cursor: pointer;
        width: ${(props) => (props.isMobile.tablet ? "2.5rem" : "1.875rem")};
        height: ${(props) => (props.isMobile.tablet ? "2.5rem" : "1.875rem")};

        color: ${(props) => props.colors.sendButtonArrowColor};
        @media screen and (orientation: landscape) {
            display: ${(props) => (props.isMobile.any ? "flex" : "flex")};
        }
    `,
    DialButton = styled(Button)`
        cursor: pointer;
        padding: 14px;
        font-size: ${(props) => (props.isMobile.any ? "1rem" : "0.9375rem")};
        left: 50vw-30px;
        background-color: ${(props) => props.colors.userChatBubbleBGColor};
        color: ${(props) => props.colors.userChatBubbleTextColor};
    `,
    SendMessageButton = styled(Button)`
        cursor: pointer;
        padding: 0.25rem;
        @media screen and (orientation: landscape) {
            display: ${(props) => (props.isMobile.any ? "flex" : "block")};
        }
        :hover ${SendIcon} {
            //color: #FF6200;
        }
    `,
    StyledBubbleContainerBot = styled.div`
        width: 100vw;
        margin: 0.4375rem 0.125rem;
        display: flex;
        flex-shrink: 0;
        font-family: sans-serif;
        font-size: 1.125rem;
        align-items: center;
    `,
    InputPlaceholder = styled.p`
        position: absolute;
        top: 50%;
        left: 0.625rem;
        z-index: 1;
        transform: translate(0%, -50%);
        margin: 0px;
        font-size: 1.125rem;
        color: #b1b1b1;
    `,
    StyledBubbleContainerUser = styled.div`
        width: 100vw;
        margin: 0.4375rem -0.125rem;
        display: flex;
        flex-shrink: 0;
        font-family: sans-serif;
        font-size: 1.125rem;
        align-items: center;
        flex-direction: row-reverse;
    `,
    Link = styled(Anchor)`
        color: white;
        text-decoration: ${(props) =>
            props.isUnderline ? "underline" : "none"};
        text-decoration-color: white;
        text-decoration-thickness: 0.125em;
        text-underline-offset: 1.5px;
    `,
    /* To bring triangle back, make width at end 20px */
    StyledBubbleBot = styled.div`
        background-color: #f2f2f2;
        border-radius: 0.375rem;
        box-shadow: 0 0 6px #b2b2b2;
        display: block;
        padding: 0.625rem 1.125rem;
        position: relative;
        vertical-align: top;
        color: ${(props) => props.colors.avatarChatBubbleTextColor};
        word-wrap: break-word;
        max-width: 75%;

        &::before {
            background-color: #f2f2f2;
            content: "\\00a0";
            display: block;
            height: 1rem;
            position: absolute;
            top: 11px;
            transform: rotate(29deg) skew(-35deg);
            -moz-transform: rotate(29deg) skew(-35deg);
            -ms-transform: rotate(29deg) skew(-35deg);
            -o-transform: rotate(29deg) skew(-35deg);
            -webkit-transform: rotate(29deg) skew(-35deg);
            width: 0px;
        }

        background-color: ${(props) => props.colors.avatarChatBubbleBGColor};
        border: ${(props) =>
            props.colors.avatarChatBubbleBorderColor !== "none"
                ? "1px solid " + props.colors.avatarChatBubbleBorderColor
                : "none"};
        margin-left: 0.625rem;
        margin-right: 3.75rem;
        margin-top: ${(props) => (props.isLast ? "0.4375rem" : "unset")};

        &::before {
            box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
            left: -0.5625rem;
            background-color: ${(props) =>
                props.colors.avatarChatBubbleBGColor};
        }
    `,
    /* To bring triangle back, make width at end 20px */
    StyledBubbleUser = styled.div`
        background-color: #f2f2f2;
        border-radius: 0.375rem;
        box-shadow: 0 0 6px #b2b2b2;
        display: block;
        padding: 0.625rem 1.125rem;
        position: relative;
        vertical-align: top;
        color: ${(props) => props.colors.userChatBubbleTextColor};
        word-wrap: break-word;
        max-width: 75%;

        &::before {
            background-color: #f2f2f2;
            content: "\\00a0";
            display: block;
            height: 1rem;
            position: absolute;
            top: 40%;
            transform: rotate(29deg) skew(-35deg);
            -moz-transform: rotate(29deg) skew(-35deg);
            -ms-transform: rotate(29deg) skew(-35deg);
            -o-transform: rotate(29deg) skew(-35deg);
            -webkit-transform: rotate(29deg) skew(-35deg);
            width: 0px;
        }

        background-color: ${(props) => props.colors.userChatBubbleBGColor};
        border: ${(props) =>
            props.colors.userChatBubbleBorderColor !== "none"
                ? "1px solid " + props.colors.userChatBubbleBorderColor
                : "none"};
        margin-left: 3.75rem;
        margin-right: 0.625rem;
        margin-top: ${(props) => (props.isLast ? "0.4375rem" : "unset")};

        &::before {
            box-shadow: 2px -2px 2px 0 rgba(178, 178, 178, 0.4);
            right: -0.5625rem;
            background-color: ${(props) => props.colors.userChatBubbleBGColor};
        }
    `;
