import React from "react";
import {
  DisplayRemoteVideoWeb,
  VideoControlsWeb,
  DisplayAvatarPhotoWeb,
  DisplayStatusMessage,
  DisplayDialButtonWeb,
  DisplayConfirmOverlay,
  DisplayLogo,
} from "./_components";
import { StyledPlayerControlsWeb } from "./_styles";
import { SDKContext } from "../../contexts/SDKContext";
import Fullscreen from "react-full-screen";
import { forceConfig } from "../../contexts/SDKContext";
import { setDisplayControls, hideDragResizeOverlay } from "./_actions";
import { hideConfirmOverlay, hideHistoryOverlay } from "../menu/actions";
import { IframeMode } from "../iframe";
import { usePictureInPicture } from "../../hooks/usePictureInPicture";
import { Menu } from "../menu/menu";
import { HistoryLinkOverlay } from "./components/history-link-overlay";
import Util from "../../common/Util";
import { detectDeviceType } from "../../common/detectDevice";
import RainbowSpinner from "./components/spinner";
import ProgressBar from "./components/progressbar";
import { ToastContainer } from "./components/toast-message";
import MobileResizablePlayer from "./components/mobile-resizable-player";

const Player = ({
  onResize,
  videoConstraints,
  videoSize,
  defaultHightScreen,
  isLandscape,
  widthMenu,
  screenRef,
  widthMainScreen,
  heightMainScreen,
  isIpadTable,
  heightDevice,
  windowSize,
  isTablet,
  isTabletWithOpenKeyboard,
  setVideoSize
}) => {
  const { exitPictureInPicture, videoPiP } = usePictureInPicture();
  const [
    state,
    dispatch,
    chatState,
    dispatchChatEvent,
    menuState,
    dispatchMenuEvent,
  ] = React.useContext(SDKContext);

  React.useEffect(() => {
    if (!Util.is_url(chatState.colors.dialPageBackground)) {
      document.getElementById("root").style.backgroundColor =
        chatState.colors.dialPageBackground;
    } else {
      document.getElementById(
        "root"
      ).style.backgroundImage = `url(${chatState.colors.dialPageBackground})`;
    }
  }, [chatState.colors.dialPageBackground]);

  React.useEffect(() => {
    if (menuState.isOverlayHistoryLink) {
      let timer = setTimeout(() => {
        dispatchMenuEvent({ type: hideHistoryOverlay });
      }, 8000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState.isOverlayHistoryLink]);

  React.useEffect(() => {
    if (state.isDragResizeOverlay) {

      // if dragging is disabled, then hide drag to resize button
      if(forceConfig.disableDragging) {
        dispatch({ type: hideDragResizeOverlay });
        return
      }

      let timer = setTimeout(() => {
        dispatch({ type: hideDragResizeOverlay });
      }, 5000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceConfig.disableDragging]);

  const handleLeavePiP = React.useCallback(() => {
    exitPictureInPicture();
    dispatchMenuEvent({ type: hideConfirmOverlay });
  }, [exitPictureInPicture, dispatchMenuEvent]);

  React.useEffect(() => {
    if (videoPiP) {
      videoPiP.addEventListener("leavepictureinpicture", handleLeavePiP);
      return () => {
        videoPiP.removeEventListener("leavepictureinpicture", handleLeavePiP);
      };
    }
  }, [handleLeavePiP, videoPiP]);

  const handleOnClick = (event) => {
    event.stopPropagation();
  };

  const isAspectRatio = React.useMemo(() => {
    return (
      (state.screenAspectRationProps?.enabled &&
        !isIpadTable &&
        !state.isMobile.any) ||
      (state.mobileScreenAspectRatioProps?.enabled &&
        !isIpadTable &&
        state.isMobile.any) ||
      (state.tabletScreenAspectRatioProps?.enabled &&
        isIpadTable &&
        !state.isMobile.any)
    );
  }, [
    isIpadTable,
    state.isMobile,
    state.mobileScreenAspectRatioProps,
    state.screenAspectRationProps,
    state.tabletScreenAspectRatioProps,
  ]);

  const widthResizeBox = React.useMemo(() => {
    return isAspectRatio &&
      !menuState.isIframeMode &&
      isLandscape &&
      state.isPlaying
      ? widthMainScreen
      : menuState.isIframeMode
      ? 270
      : windowSize.width;
  }, [
    menuState.isIframeMode,
    windowSize.width,
    isAspectRatio,
    isLandscape,
    widthMainScreen,
    state.isPlaying,
  ]);

  const heightResizableBox = React.useMemo(() => {
    return !state.isPlaying
      ? 0
      : state.fullScreen || isLandscape
      ? windowSize.height
      : menuState.isIframeMode
      ? 140
      : videoSize.height;
  }, [
    videoSize.height,
    menuState.isIframeMode,
    windowSize.height,
    isLandscape,
    state.fullScreen,
    state.isPlaying,
  ]);

  const isSmallHeigt = React.useMemo(
    () => windowSize.height < 490,
    [windowSize.height]
  );

  // Determine if dragging should be disabled based on configuration or unavailable width
  const isWidthUnavailable = widthResizeBox === 0;
  const disableDragging = forceConfig.disableDragging || isWidthUnavailable;

  
  return state.isMobile.any && !isTablet ? (
    <>
      <IframeMode isMobile={state.isMobile.any} />
      {isLandscape && (
        <Menu
          isLandscape={isLandscape}
          widthMenu={widthMenu}
          widthMainScreen={widthMainScreen}
          windowSize={windowSize}
        />
      )}
      
      <MobileResizablePlayer {...{
        state,
        menuState,
        isAspectRatio,
        isLandscape,
        onResize,
        disableDragging,
        widthResizeBox,
        heightResizableBox,
        defaultHightScreen,
        videoConstraints,
        widthMainScreen,
        videoSize,
        windowSize,
        chatState,
        dispatch,
        dispatchChatEvent,
        dispatchMenuEvent,
        screenRef,
        handleOnClick,
        setVideoSize
        }} /> 
    </>
  ) : (
    <>
      {renderPlayerWeb(
        state,
        dispatch,
        dispatchChatEvent,
        chatState,
        menuState,
        dispatchMenuEvent,
        isLandscape,
        widthMenu,
        screenRef,
        widthMainScreen,
        heightMainScreen,
        isIpadTable,
        heightDevice,
        isAspectRatio,
        windowSize,
        isTabletWithOpenKeyboard,
        isSmallHeigt
      )}
    </>
  );
};

const renderPlayerWeb = (
  state,
  dispatch,
  dispatchChatEvent,
  chatState,
  menuState,
  dispatchMenuEvent,
  isLandscape,
  widthMenu,
  screenRef,
  widthMainScreen,
  heightMainScreen,
  isIpadTable,
  heightDevice,
  isAspectRatio,
  windowSize,
  isTabletWithOpenKeyboard,
  isSmallHeigt
) => {
  const device = detectDeviceType();

  return (
    <div
      id="screen-container"
      style={{
        height:
          !isLandscape && isIpadTable && state.fullScreen
            ? "100vh"
            : isLandscape && isAspectRatio
            ? `calc(${heightDevice}px - 3.875rem)`
            : !isLandscape && isAspectRatio
            ? "100%"
            : isLandscape
            ? "100%"
            : forceConfig.hideChatHistory
            ? `calc(${heightDevice}px - 3.875rem})`
            : device === "IOSIpadChrome"
            ? `${heightMainScreen}px`
            : "100%",
        width: isAspectRatio ? widthMainScreen : "100%",
        maxWidth: "100%",
        margin: "0px auto",
      }}
      ref={screenRef}
    >
      <Fullscreen enabled={state.fullScreen}>
        {((state.fullScreen &&
          !(!isLandscape && state.fullScreen && isIpadTable)) ||
          isLandscape) && (
          <Menu
            isLandscape={isLandscape}
            widthMenu={widthMenu}
            widthMainScreen={widthMainScreen}
            windowSize={windowSize}
          />
        )}
        {DisplayLogo({
          state,
          menuState,
          isLandscape,
          isIpadTable,
        })}
        {DisplayStatusMessage({ dispatch, state })}

        <RainbowSpinner isVisible={state.isLoading} />
        <IframeMode />
        <div
          id="avatar-container"
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 100000,
          }}
        >
          {DisplayAvatarPhotoWeb({
            dispatch,
            state,
            chatState,
            isLandscape,
          })}
          {DisplayDialButtonWeb({
            dispatch,
            state,
            dispatchChatEvent,
            chatState,
          })}
        <ProgressBar key={1} bgcolor={'#ef6c00'} completed={state.loadProgress} />
        </div>

        <HistoryLinkOverlay
          menuState={menuState}
          dispatchMenuEvent={dispatchMenuEvent}
        />

        <ToastContainer 
          menuState={menuState}
          dispatchMenuEvent={dispatchMenuEvent}
        />

        {DisplayConfirmOverlay({
          dispatch,
          state,
          dispatchChatEvent,
          chatState,
          menuState,
          dispatchMenuEvent,
        })}
        {DisplayRemoteVideoWeb({
          dispatch,
          state,
          menuState,
          chatState,
          heightMainScreen,
          isLandscape,
          isIpadTable,
          isAspectRatio,
          windowSize,
          isTabletWithOpenKeyboard,
        })}
        <div id="webgl_container" className="parent_size"></div>
        <StyledPlayerControlsWeb
          isIpadTable={isIpadTable}
          isLandscape={isLandscape}
          fullScreen={state.fullScreen}
          hideChatInput={forceConfig.hideChatInput}
          hideChatHistory={forceConfig.hideChatHistory}
          heightDevice={heightDevice}
          heightMainScreen={heightMainScreen}
          isIframeMode={menuState.isIframeMode}
          isSmallHeigt={isSmallHeigt}
          pushButtonsDown={
            forceConfig.hideMicButton || forceConfig.hideHangUpButton
          }
          pad={isSmallHeigt ? "" : "medium"}
          fill="horizontal"
          onMouseEnter={() =>
            state.isPlaying
              ? dispatch({
                  type: setDisplayControls,
                  displayControls: true,
                })
              : ""
          }
          onMouseLeave={() =>
            state.isPlaying
              ? dispatch({
                  type: setDisplayControls,
                  displayControls: false,
                })
              : ""
          }
        >
          {VideoControlsWeb({
            dispatch,
            state,
            menuState,
            dispatchChatEvent,
            dispatchMenuEvent,
            isLandscape,
          })}
        </StyledPlayerControlsWeb>
      </Fullscreen>
    </div>
  );
};

export default Player;
