import * as React from "react";
import { Container, SkChaseDot, SkChase } from "./_styles";

const RainbowSpinner = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <Container>
      <SkChase>
        <SkChaseDot />
        <SkChaseDot />
        <SkChaseDot />
        <SkChaseDot />
        <SkChaseDot />
        <SkChaseDot />
      </SkChase>
    </Container>
  );
};

export default RainbowSpinner;
